import React, { CSSProperties, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { ButtonInner, ButtonWrapper } from './Button.css';
import { getLocalStorage } from '../../../utils/fnStorage';
import { TrackingElementPropTypes } from '../../../types/Tracking';
import { useTracking } from '../../../providers/useTracking/TrackingContext';
import { trackingClickHandler } from '../../../utils/fnTracking';

type ButtonPropType = TrackingElementPropTypes & {
    link?: string;
    className?: string;
    isPrimary?: boolean;
    label?: string;
    onClick?: (e) => void;
    isDisabled?: boolean;
    dataTestId?: string;
    openNewTab?: boolean;
    selected?: boolean;
    progress?: number;
};

const Button: React.FC<ButtonPropType> = ({
    onClick,
    link,
    className,
    label,
    isDisabled,
    children,
    dataTestId,
    isPrimary = false,
    openNewTab = false,
    selected = false,
    progress,
    ...rest
}) => {
    const history = useHistory();
    const track = useTracking();
    const ref = useRef<HTMLDivElement>();

    const clickHandler = e => {
        trackingClickHandler(e, onClick, rest, track);

        if (link) {
            e.stopPropagation();
            e.preventDefault();

            if (!openNewTab) {
                history.push(link);
            } else {
                window.open(link, '_blank');
            }
        }
    };
    const isMobile = getLocalStorage('isMobile');
    let isInGallery: boolean;
    let isVisibleInGallery: boolean;

    const style: CSSProperties = {};
    if (isDisabled) {
        style.pointerEvents = 'none';
    }

    useEffect(() => {
        if (ref?.current) {
            isInGallery = !!ref?.current?.closest('.slide');
            isVisibleInGallery = isInGallery && !!ref?.current?.closest('.active');
        }
    }, [ref]);

    return (
        <ButtonWrapper
            data-test-id={dataTestId}
            style={style}
            className={`${className}`}
            onClick={isDisabled ? null : clickHandler}
            role="button"
            ref={ref}
            tabIndex={isInGallery ? (isVisibleInGallery ? 0 : -1) : 0}
            aria-label={label}
            aria-disabled={isDisabled}
            {...rest}
        >
            <ButtonInner
                data-test-id={'button-moreinfo'}
                className={`${!isMobile ? '' : 'no-hover '}`}
                isDisabled={isDisabled}
                isPrimary={isPrimary}
                isSelected={selected}
                progress={progress}
            >
                {children}
                <span className={'label'}>{label}</span>
            </ButtonInner>
        </ButtonWrapper>
    );
};

export default Button;

import React, { FC } from 'react';
import SVGInline from 'react-svg-inline';
import { ToastProps, useToasts } from 'react-toast-notifications';

import {
    NotificationCloser,
    NotificationIcon,
    NotificationIconWrapper,
    NotificationMessage,
    NotificationMessageWrapper,
    NotificationTitle,
    NotificationWrapper,
    toastStates,
} from './Notification.css';
import icons from '../../style';
import translate from '../../utils/fnTranslate';
import { RecordingType } from '../../types/RecordingTypes';
import { getRecordingIcon } from '../../utils/fnImages';

type PropTypes = ToastProps & {
    children: {
        title: string;
        message: string;
        ico: any;
        isHidden?: boolean; // ARIA
    };
};

export const Notification: FC<PropTypes> = ({ transitionState, transitionDuration, placement, children, onDismiss }) => {
    const { title, message, ico = icons.checkMarkIcon, isHidden = false } = children;

    return (
        <NotificationWrapper
            transitionDuration={transitionDuration}
            style={{ ...toastStates(placement)[transitionState] }}
            isHidden={isHidden}
        >
            <NotificationIconWrapper>
                <NotificationIcon svg={ico} />
            </NotificationIconWrapper>
            <NotificationMessageWrapper role="alert">
                <NotificationTitle data-test-id={'notification-title'}>{title}</NotificationTitle>
                <NotificationMessage>{message}</NotificationMessage>
            </NotificationMessageWrapper>
            <NotificationCloser>
                <SVGInline svg={icons.closeIconNew} onClick={onDismiss} />
            </NotificationCloser>
        </NotificationWrapper>
    );
};

export const useTransactionSuccessNotification = () => {
    const { addToast } = useToasts();

    return (callback: () => void) => {
        addToast(
            {
                title: translate('NOTIFICATION_PURCHASE_SUCCESS_TITLE'),
                message: translate('NOTIFICATION_PURCHASE_SUCCESS_BODY'),
            },
            {
                appearance: 'success',
                autoDismiss: true,
            },
            callback
        );
    };
};

export const useSubscriptionSuccessNotification = () => {
    const { addToast } = useToasts();

    return (callback: () => void) => {
        addToast(
            {
                title: translate('NOTIFICATION_SUBSCRIBE_SUCCESS_TITLE'),
                message: translate('NOTIFICATION_SUBSCRIBE_SUCCESS_BODY'),
            },
            {
                appearance: 'success',
                autoDismiss: true,
            },
            callback
        );
    };
};

export const useRecordingNotification = () => {
    const { addToast } = useToasts();

    const getTitle = (type: RecordingType, action: 'ADD' | 'REMOVE', err: boolean): string => {
        if (!err) {
            switch (type) {
                case 'RECORDED':
                    return translate(action === 'ADD' ? null : 'NOTIFICATION_RECORDING_DELETED');
                case 'ONGOING':
                    return translate(action === 'ADD' ? 'NOTIFICATION_RECORDING_STARTED' : 'NOTIFICATION_RECORDING_STOPPED');
                case 'SCHEDULED':
                    return translate(action === 'ADD' ? 'NOTIFICATION_RECORDING_SCHEDULED' : 'NOTIFICATION_RECORDING_CANCELED');
                default:
                    return null;
            }
        } else {
            switch (type) {
                case 'RECORDED':
                    return translate(action === 'ADD' ? null : 'RECORDING_NOTIFICATION_DELETE_ERROR');
                case 'ONGOING':
                    return translate(action === 'ADD' ? 'RECORDING_NOTIFICATION_SCHEDULE_ERROR' : 'RECORDING_NOTIFICATION_CANCEL_ERROR');
                case 'SCHEDULED':
                    return translate(action === 'ADD' ? 'RECORDING_NOTIFICATION_SCHEDULE_ERROR' : 'RECORDING_NOTIFICATION_CANCEL_ERROR');
                default:
                    return null;
            }
        }
    };

    return (type: RecordingType, action: 'ADD' | 'REMOVE', callback: () => void, err = false) => {
        addToast(
            {
                title: getTitle(type, action, err),
                message: null,
                ico: getRecordingIcon(type, err),
            },
            {
                appearance: 'success',
                autoDismiss: true,
            },
            callback
        );
    };
};

export const useAriaOnlyNotification = () => {
    const { addToast } = useToasts();

    return (title: string) => {
        addToast(
            {
                title,
                message: null,
                isHidden: true,
            },
            {
                appearance: 'success',
                autoDismiss: true,
            }
        );
    };
};

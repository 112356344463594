import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { isSafari } from 'utils/fnDevices';
import {
    BufferLine,
    ProgressBar,
    ProgressLine,
    Scrubber,
    SeekBarContainer,
    TimeIndicator,
    ProgressAndScrubber,
    SeekBarValue,
    ThumbnailContainer,
    ThumbnailImage,
    HoverLine,
} from './SeekBar.css';

type SeekBarProps = {
    enabled: boolean;
    onSeekInput?: () => void;
    onSeekStart?: () => void;
    onSeekEnd?: () => void;
    onSeekBarCreated: (object: SeekBarElements) => void;
    onMouseHover?: (e: MouseEvent) => void;
    onMouseLeave?: (e: MouseEvent) => void;
};

export type SeekBarElements = {
    scrubber: HTMLInputElement;
    currentTime: HTMLDivElement;
    remainingTime: HTMLDivElement;
    bufferLine: HTMLDivElement;
    hoverLine: HTMLDivElement;
    progressLine: HTMLDivElement;
    scrubberValue: HTMLDivElement;
    thumbnailContainer: HTMLDivElement;
    thumbnailImage: HTMLImageElement;
};

// eslint-disable-next-line react/display-name
export const SeekBar = forwardRef<HTMLInputElement, SeekBarProps>(
    (
        {
            enabled,
            onSeekInput = () => {},
            onSeekBarCreated = () => {},
            onSeekStart = () => {},
            onSeekEnd = () => {},
            onMouseHover = () => {},
            onMouseLeave = () => {},
        },
        ref
    ) => {
        const scrubberRef = useRef<HTMLInputElement>(null);
        const currentTimeRef = useRef<HTMLDivElement>(null);
        const remainingTimeRef = useRef<HTMLDivElement>(null);
        const bufferLineRef = useRef<HTMLDivElement>(null);
        const hoverLineRef = useRef<HTMLDivElement>(null);
        const progressLineRef = useRef<HTMLDivElement>(null);
        const scrubberValueRef = useRef<HTMLDivElement>(null);
        const thumbnailRef = useRef<HTMLDivElement>(null);
        const thumbnailImageRef = useRef<HTMLImageElement>(null);

        const [seekValue] = useState(0);
        const [seekMin] = useState(0);
        const [seekMax] = useState(1);

        const addEventListeners = () => {
            if (scrubberRef?.current) {
                const scrubber = scrubberRef.current;

                scrubber.addEventListener('mousedown', onSeekStart);
                scrubber.addEventListener('touchstart', onSeekStart);
                scrubber.addEventListener('input', onSeekInput);
                if (!isSafari()) {
                    scrubber.addEventListener('change', onSeekInput);
                }
                scrubber.addEventListener('touchend', onSeekEnd);
                scrubber.addEventListener('mouseup', onSeekEnd);
                scrubber.addEventListener('mousemove', onMouseHover);
                scrubber.addEventListener('mouseleave', onMouseLeave);
            }
        };

        useEffect(() => {
            addEventListeners();
            const scrubber = scrubberRef?.current || null;
            const currentTime = currentTimeRef?.current || null;
            const remainingTime = remainingTimeRef?.current || null;
            const bufferLine = bufferLineRef?.current || null;
            const hoverLine = hoverLineRef?.current || null;
            const progressLine = progressLineRef?.current || null;
            const scrubberValue = scrubberValueRef?.current || null;
            const thumbnailContainer = thumbnailRef?.current || null;
            const thumbnailImage = thumbnailImageRef?.current || null;

            onSeekBarCreated({
                scrubber,
                currentTime,
                remainingTime,
                bufferLine,
                hoverLine,
                progressLine,
                scrubberValue,
                thumbnailContainer,
                thumbnailImage,
            });

            return () => {
                scrubber.removeEventListener('mousedown', onSeekStart);
                scrubber.removeEventListener('touchstart', onSeekStart);
                scrubber.removeEventListener('input', onSeekInput);
                if (!isSafari()) {
                    scrubber.removeEventListener('change', onSeekInput);
                }
                scrubber.removeEventListener('touchend', onSeekEnd);
                scrubber.removeEventListener('mouseup', onSeekEnd);
                scrubber.removeEventListener('mousemove', onMouseHover);
                scrubber.removeEventListener('mouseleave', onMouseLeave);
            };
        }, []);

        return (
            <SeekBarContainer>
                <TimeIndicator data-test-id={'player-seekbar-elapsed-time'} ref={currentTimeRef}>
                    0:00:00
                </TimeIndicator>
                <ProgressAndScrubber>
                    <ProgressBar data-test-id={'player-progress-bar'}>
                        <BufferLine ref={bufferLineRef} />
                        <HoverLine ref={hoverLineRef} />
                        <ProgressLine ref={progressLineRef} />
                    </ProgressBar>
                    <SeekBarValue ref={scrubberValueRef}>0:00:00</SeekBarValue>
                    <ThumbnailContainer ref={thumbnailRef}>
                        <ThumbnailImage ref={thumbnailImageRef} src={''} positionX={0} positionY={0} />
                    </ThumbnailContainer>
                    <Scrubber
                        type={'range'}
                        step={'any'}
                        data-test-id={'player-scrubber'}
                        min={seekMin ?? 0}
                        max={seekMax ?? 1}
                        defaultValue={seekValue}
                        disabled={!enabled}
                        ref={inputElement => {
                            scrubberRef.current = inputElement;
                            if (typeof ref === 'function') {
                                ref(inputElement);
                            } else if (ref) {
                                ref.current = inputElement;
                            }
                        }}
                    />
                </ProgressAndScrubber>
                <TimeIndicator data-test-id={'player-seekbar-remaining-time'} ref={remainingTimeRef}>
                    0:00:00
                </TimeIndicator>
            </SeekBarContainer>
        );
    }
);

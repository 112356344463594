import React, { ReactNode, useState } from 'react';
import { DropdownItemSimple, DropdownMenu } from '../../DropdownMenu/DropdownMenu';
import { useElementInteractionTracking } from '../../../providers/useTracking/TrackingContext';
import { FilterSelector } from './EpgFilter.css';

export type SelectorOption = {
    label: string;
    value: any;
    isTonight?: boolean;
};

type PropTypes = {
    filterItems: SelectorOption[];
    selectedItemIndex: number;
    title: string;
    onSelected: (selection: SelectorOption) => void;
    triggerButton: ReactNode;
    dataTrackId?: string;
};

const MAX_FLYOUT_HEIGHT = 258;

export const EpgFilter: React.FC<PropTypes> = ({ filterItems = [], selectedItemIndex, onSelected, title, triggerButton, dataTrackId }) => {
    const interactionTracking = useElementInteractionTracking();
    const [isOpen, setIsOpen] = useState(false);

    const onSelect = (item: SelectorOption) => {
        onSelected(item);
    };

    return (
        <FilterSelector>
            <DropdownMenu
                triggerButton={triggerButton}
                verticalPosition={'bottom'}
                horizontalPosition={'right'}
                maxHeight={MAX_FLYOUT_HEIGHT}
                selectedIndex={selectedItemIndex || 0}
                visibilityChangeCallback={setIsOpen}
                onClick={() => {
                    interactionTracking({
                        'data-track-id': dataTrackId,
                    });
                }}
                title={title}
            >
                {filterItems.map((filterItem, index) => {
                    return (
                        <DropdownItemSimple
                            key={filterItem.value}
                            selected={index === selectedItemIndex}
                            onClick={() => {
                                interactionTracking({
                                    'data-track-id': `${dataTrackId}_${index}`,
                                    'data-button-title': filterItem.label,
                                });
                                onSelect(filterItem);
                            }}
                            tabindex={isOpen ? 0 : -1}
                        >
                            {filterItem.label}
                        </DropdownItemSimple>
                    );
                })}
            </DropdownMenu>
        </FilterSelector>
    );
};

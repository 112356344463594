import { css } from 'styled-components';
import { hexToRgba } from '../../utils/fnColors';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';

export const DropdownWrapper = styled.div`
    position: relative;
`;

export const DropdownMenuList = styled.div`
    background-color: ${props => props.theme.brandedConfig.dropdown_background_color};
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;

    ${MQ(Breakpoints.s)} {
        background-color: transparent;
        position: static;
    }

    padding: 8px 0px 8px 0px;
`;

export const DropdownContent = styled.div<{
    showMenuContent: boolean;
    isMqMobile: boolean;
    verticalPosition: string;
    horizontalPosition: string;
}>`
    transition: opacity 0.3s ease-in-out;
    opacity: ${props => (props.showMenuContent ? '1' : '0')};
    pointer-events: ${props => (props.showMenuContent ? 'all' : 'none')};

    z-index: ${maxZIndex + 1};

    ${props => {
        return !props.isMqMobile
            ? css`
                  overflow: hidden;
                  position: absolute;
                  min-width: 240px;
                  padding: 6px 0;
                  border-radius: 20px;
                  background-color: var(--color-dark-foreground-5);

                  left: ${props.horizontalPosition === 'left' ? '0' : 'auto'};
                  right: ${props.horizontalPosition === 'left' ? 'auto' : '0'};
                  top: ${props.verticalPosition === 'bottom' ? '100%' : 'auto'};
                  bottom: ${props.verticalPosition === 'bottom' ? 'auto' : '100%'};
              `
            : css`
                  position: fixed;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  top: 0;
                  z-index: ${maxZIndex};
                  background-color: var(--color-dark-foreground-5);

                  ${DropdownMenuList} {
                      border-radius: 20px;
                  }
              `;
    }}
`;

export const DropdownLabel = styled.span`
    right: 0;
    transition: opacity 0.3s ease-in-out;
    font-size: 16px;
    line-height: 24px;
    color: inherit;

    ${MQ(Breakpoints.m)} {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const DropdownItemWithSvgWrapper = styled.a<{ hideOnDesktop: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    line-height: 24px;
    color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.7)};

    &:focus-visible {
        text-decoration: 2px underline #fff;
    }

    ${MQ(Breakpoints.m)} {
        padding: 4px 16px;
        font-size: 14px;
        line-height: 20px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            background-color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.07)};
            color: ${props => props.theme.brandedConfig.high_contrast};
        }
    }

    ${props =>
        props.hideOnDesktop
            ? css`
                  ${MQ(Breakpoints.m)} {
                      display: none;
                  }
              `
            : css``}
`;

export const SVGWrapper = styled.span`
    display: block;
    width: 32px;
    height: 32px;

    .SVGInline svg {
        width: 100%;
        height: auto;
    }
`;

export const RightSvgWrapper = styled.div`
    margin-left: auto;
`;

export const DropdownItemWSeparator = styled.div`
    height: 1px;
    background-color: ${props => props.theme.brandedConfig.white_color};
    opacity: 0.15;
    margin: 4px 16px;
`;

export const DropdownItemSimpleWrapper = styled.a<{ selected: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    line-height: 24px;
    color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.7)};
    background-color: ${props => props.selected && hexToRgba(props.theme.brandedConfig.white_color, 0.07)};
    font-size: 16px;
    position: relative;
    z-index: 1;

    &:focus-visible {
        text-decoration: 2px underline #fff;
    }

    ${MQ(Breakpoints.m)} {
        padding: 8px 16px;
        font-size: 14px;
        line-height: 20px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            background-color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.07)};
        }
    }

    .SVGInline {
        height: 20px;

        :not(.colored) {
            svg {
                path {
                    fill: ${props => props.theme.brandedConfig.high_contrast};
                }
            }
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

export const DropdownTitle = styled(DropdownItemSimpleWrapper)<{ showBottomBorder: boolean }>`
    display: flex;
    align-items: center;
    font-weight: bold;
    opacity: 1;
    margin: 14px 16px 0px 16px;
    padding: 0;
    padding-bottom: 14px;
    ${props =>
        props.showBottomBorder
            ? css`
                  border-bottom: 1px solid ${hexToRgba(props.theme.brandedConfig.white_color, 0.15)};
              `
            : ''};

    ${MQ_NOT_TOUCH} {
        &:hover {
            background-color: transparent;
        }
    }

    .clear-icon {
        position: relative;
        opacity: 1;
        top: 0;
        transform: none;
        right: 0;
        width: 16px;
        height: 16px;
        margin-left: auto;

        svg {
            position: relative;
            width: auto;
            height: 100%;
        }
    }
`;

export const MenuContent = styled.div``;

export const ExpendableBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`;

export const ExpendableBar = styled.div`
    width: 48px;
    border-top: 4px solid ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.15)};
    border-radius: 2px;
`;

export const DropdownTitleDesktop = styled.div`
    position: absolute;
    top: 16px;
    font-weight: 700;
    color: var(--color-dark-body);
`;

import { css } from 'styled-components';
import SVGInline from 'react-svg-inline';
import { VolumeBar, VolumeControlContainer, VolumeScrubber, VolumeSubAddButton } from 'components/VolumeControl/VolumeControl.css';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { hexToRgba } from '../../utils/fnColors';
import { fontExtraBold, fontBold, fontNormal, levelingZIndex, maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ, MQ_NOT_TOUCH, MQHeight, DESKTOP_MQ } from '../../style/styled-components/cssMediaQueries';
import { landscapeCardHeight, landscapeCardHoveredHeight } from '../Cards/CardLandscape/CardLandscape.css';
import { ControlGroup } from '../Player/PlayerWrapper';
import { SliderWrapper, SliderTitle } from '../Slider/Slider.css';
import { ContentMarker, MetaDataSeparator, StyledSVG } from '../../style/styled-components/reusable.css';
import { ProgressAndScrubber, ProgressBar, Scrubber, TimeIndicator } from '../SeekBar/SeekBar.css';

export const FullScreenToggleIcon = styled(SVGInline)`
    width: 24px;
    height: 24px;
    opacity: 1;

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const PlayerButton = styled.div`
    display: inline-block;
    vertical-align: middle;

    .SVGInline {
        display: inline-block;
    }
`;

export const ControlButtonLabel = styled.span`
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    opacity: 0;
    font-family: ${fontNormal};
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    margin-top: 4px;
    white-space: nowrap;
`;

export const ControlButtonWrapper = styled.div<{ disabled: boolean; invisible: boolean; isHeaderControl?: boolean }>`
    display: inline-block;
    text-align: center;
    position: relative;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    pointer-events: ${props => (props.disabled || props.invisible ? 'none' : 'all')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    visibility: ${props => (props.invisible ? 'hidden' : 'visible')};
    border-radius: 50px;
    z-index: 2;

    ${ControlButtonLabel} {
        opacity: 0;
    }

    @media (min-width: 667px) and (max-width: ${Breakpoints.m}) {
        display: flex;

        ${ControlButtonLabel} {
            font-size: 14px;
            position: relative;
            display: flex;
            align-items: center;
            transform: none;
            left: auto;
            margin-top: 0;
            opacity: 1;
        }
    }

    .settings-button {
        position: relative;
        z-index: -1;
    }

    @media ${DESKTOP_MQ} {
        &:hover,
        &:focus-visible {
            ${ControlButtonLabel} {
                opacity: 1;
            }
        }

        &:focus-visible {
            ${ControlButtonLabel} {
                margin-top: 8px;
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        border-radius: inherit;
        pointer-events: none;
    }

    &:focus-visible {
        outline: none;

        &::after {
            border: var(--focus-outline);
        }
    }

    ${props =>
        props.isHeaderControl &&
        css`
            border-radius: 4px;

            &::after {
                top: -6px;
                right: -6px;
                bottom: -6px;
                left: -6px;
            }
        `}
`;

export const FooterControls = styled.div<{
    hasExtraControls: boolean;
    activeControlGroup: ControlGroup;
    withAnimation: boolean;
    hasOpacity: boolean;
    isMobile: boolean;
    isEmbedded: boolean;
}>`
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    transition: ${props => (props.withAnimation ? 'all 0.5s ease-in-out' : 'none')};
    opacity: ${props => (props.hasOpacity ? '.1' : '1')};

    ${props =>
        props.isEmbedded
            ? css`
                  transform: translateY(-8px);

                  ${MQ(Breakpoints.m)} {
                      transform: translateY(-14px);
                  }
              `
            : css`
                  transform: translateY(
                      ${props.hasExtraControls
                          ? `${props.activeControlGroup === ControlGroup.EXTRA ? 0 : landscapeCardHoveredHeight}px`
                          : props.isMobile
                          ? '-23px'
                          : '-40px'}
                  );

                  ${MQ(Breakpoints.m)} {
                      transform: translateY(
                          ${props.hasExtraControls
                              ? `${props.activeControlGroup === ControlGroup.EXTRA ? 0 : landscapeCardHeight}px`
                              : '-40px'}
                      );
                  }
              `}

    ${ControlButtonWrapper} {
        ${PlayerButton} {
            transition: background-color 0.2s ease-in-out;
            border-radius: 50%;
            padding: 8px;

            ${MQ_NOT_TOUCH} {
                background-color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0)};
            }

            .SVGInline {
                width: 24px;
                height: 24px;
                display: block;
            }
        }

        @media ${DESKTOP_MQ} {
            &:hover,
            &:focus-visible {
                opacity: 1;

                ${PlayerButton} {
                    background-color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.3)};
                }
            }
        }
    }
    ${PlayerButton} {
        .SVGInline {
            width: 24px;
            height: 24px;
        }
    }
`;

export const LoadingIndicatorWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;
    z-index: ${maxZIndex};
    justify-content: center;
    align-items: center;

    img {
        width: 72px;
        height: 72px;
        animation: spin 1s linear infinite;
    }
`;

export const ChannelLogoFallback = styled.span`
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
`;

export const ChanelLogo = styled.div`
    height: 30px;
    width: 70px;

    img {
        width: 100%;
        height: 100%;
    }
`;

export const EmbeddedChanelInfoContainer = styled.div`
    display: flex;
    margin-top: 16px;
`;

export const ChanelInfoContainer = styled.div`
    display: flex;
    margin-bottom: 16px;

    ${MQ(Breakpoints.s)} {
        margin-bottom: 0;
    }

    ${MQ(Breakpoints.m)} {
        margin-bottom: 8px;
    }
`;

export const EmbeddedTitle = styled.p`
    font-size: 20px;
    line-height: 23px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    font-family: ${fontBold};
    padding-top: 20px;

    ${MQ(Breakpoints.s)} {
        -webkit-line-clamp: 2;
    }
`;

export const Title = styled.p`
    font-size: 20px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: inline-block;
    font-family: ${fontExtraBold};
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    width: fit-content;

    ${MQ(Breakpoints.m)} {
        padding-right: 0;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        white-space: normal;
        -webkit-line-clamp: 2;
        font-size: 24px;
        line-height: 32px;
    }
`;

export const Subtitle = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontNormal};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;

    ${MQ(Breakpoints.m)} {
        font-size: 20px;
        line-height: 24px;
        padding-top: 4px;
        display: block;
    }
`;

export const MetadataSubtitle = styled.p`
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontNormal};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${MQ(Breakpoints.m)} {
        display: none;
        font-size: 20px;
        line-height: 24px;
    }
`;

export const EmbeddedIconsWrapper = styled.div`
    height: 16px;

    ${MQ(Breakpoints.m)} {
        height: 20px;
    }

    ${StyledSVG} {
        height: inherit;
        vertical-align: baseline;
    }
`;

export const IconsWrapper = styled.div`
    height: 16px;

    ${MQ(Breakpoints.m)} {
        height: 20px;
    }

    ${StyledSVG} {
        height: inherit;
        vertical-align: baseline;
    }
`;

export const EmbeddedMetaDataWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;

    ${MQ(Breakpoints.m)} {
        font-size: 14px;
    }

    ${MetaDataSeparator} {
        display: block;
        font-size: inherit;
    }
`;

export const MetaDataWrapper = styled.div`
    display: none;
    align-items: center;
    font-size: 14px;
    line-height: 20px;

    ${MQ(Breakpoints.m)} {
        display: flex;
        font-size: 14px;
    }

    ${MetaDataSeparator} {
        display: block;
        font-size: inherit;
    }
`;

export const EmbeddedPlayerMetaData = styled.div`
    position: relative;
    font-weight: normal;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin-top: 10px;
    display: flex;
    align-items: center;

    ${ContentMarker} {
        position: static;
        display: inline-block;
        padding: 6px 8px;
        margin-right: 18px;
    }
`;

export const PlayerMetaData = styled.div`
    position: relative;
    font-weight: normal;
    color: ${props => props.theme.brandedConfig.high_contrast};
    padding-top: 0;
    display: flex;
    flex-direction: column;

    ${MQ(Breakpoints.m)} {
        padding-top: 8px;
        flex-direction: row;

        ${Subtitle} {
            display: none;
        }
    }

    ${ContentMarker} {
        position: static;
        display: inline-block;
        padding: 6px 8px;
        margin-right: 18px;
    }
`;

export const UpperContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const LabelMetaData = styled.span`
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: inline-block;
    font-size: inherit;
    line-height: 20px;
`;

export const SeekBarHolder = styled.div<{ isEmbedded: boolean }>`
    position: relative;
    display: flex;
    align-items: center;

    ${props =>
        props.isEmbedded
            ? css`
                  ${TimeIndicator} {
                      display: none;
                  }

                  margin: 0 16px;

                  ${MQ(Breakpoints.s)} {
                      margin: 0 76px;

                      ${TimeIndicator} {
                          display: block;
                      }
                  }

                  ${MQ(Breakpoints.m)} {
                      margin: 0 208px;
                  }
              `
            : css`
                  margin-left: 16px;
                  margin-right: 16px;

                  ${MQ(Breakpoints.s)} {
                      margin: 15px 44px 0;
                  }

                  ${MQ(Breakpoints.m)} {
                      margin: 16px 72px 0;
                  }
              `}
`;

export const MetaDataContainer = styled.div`
    padding: 0;
    display: none;
    margin: 32px 44px 0;

    ${MQ(Breakpoints.m)} {
        margin: 24px 72px 0;
    }

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const MetaDataContainerMobile = styled.div`
    position: absolute;
    max-width: 80vw;
    flex-direction: column;
    top: 26px;
    left: 16px;

    ${MQ(Breakpoints.s)} {
        display: flex;
        flex-direction: row;
        top: 26px;
        left: 44px;
    }

    ${MQ(Breakpoints.m)} {
        display: none;
    }
`;

export const EmbeddedMetaDataContainer = styled.div`
    position: relative;
    display: none;

    ${MQ(Breakpoints.s)} {
        display: block;
    }
`;

export const CastingLabel = styled.p`
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.globalConfig.high_contrast};

    ${MQHeight('500px')} {
        margin-bottom: 50px;
    }

    ${MQ(Breakpoints.s)} {
        font-size: 20px;
        margin-bottom: 44px;
    }
`;

export const JumpButtonWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const JumpButtonIcon = styled.div`
    width: 100%;
    height: 100%;
`;

export const JumpButtonLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    font-family: ${fontBold};
`;

export const StyledSVGInline = styled(SVGInline)`
    width: 100%;
    height: 100%;

    .SVGInline-svg {
        width: 100%;
        display: block;
    }
`;

export const CloseButton = styled.a`
    height: 18px;
    width: 18px;
    display: block;
    z-index: 15;
    cursor: pointer;
    margin: 3px;

    ${StyledSVGInline} {
        width: 19px;
        height: 19px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const MinimizeButton = styled.a`
    height: 24px;
    width: 24px;
    display: block;
    z-index: 15;
    cursor: pointer;
    opacity: 1;

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const MoreInfoArrow = styled.span`
    display: inline-block;
    top: 50%;
    width: 16px;
    position: absolute;
    right: 0;
    transform: translateY(-50%);
`;

export const FooterButtonsContainer = styled.div`
    margin-top: 8px;

    ${MQ(Breakpoints.s)} {
        display: flex;
        justify-content: center;
        margin-top: 4px;
    }

    ${MQ(Breakpoints.m)} {
        margin-top: 8px;
    }

    ${JumpButtonLabel} {
        font-size: 8px;
        line-height: 8px;
    }
`;

export const ButtonsInnerContainer = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-width: 284px;
    gap: 16px;

    ${MQ(Breakpoints.m)} {
        gap: 28px;
    }
`;

export const HeaderControls = styled.div`
    display: block;
    position: absolute;
    top: 26px;
    right: 16px;

    ${MQ(Breakpoints.s)} {
        top: 26px;
        right: 44px;
    }

    ${MQ(Breakpoints.m)} {
        top: 24px;
        right: 72px;
    }
`;

export const HeaderControlsInnerContainer = styled.div`
    display: flex;
    text-align: center;
    gap: 16px;

    & > *:last-child {
        margin-left: 12px;
    }

    ${ControlButtonWrapper} {
        min-width: auto;
        padding: 0;

        ${ControlButtonLabel} {
            display: none;
        }

        ${PlayerButton} {
            width: 24px;
            height: 24px;

            ${MQ_NOT_TOUCH} {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
`;

export const HeaderEmbeddedControls = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 14px;
    right: -6px;
    left: 14px;

    ${MQ(Breakpoints.s)} {
        left: auto;
        right: -20px;
    }

    ${ControlButtonWrapper} {
        min-width: 65px;

        &.fullscren-btn {
            margin-left: auto;
        }
    }
`;

export const VolumeControlHoveringWrapper = styled.div`
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    transform-origin: top left;
    transform: rotate(-90deg) translateY(-50%) translateX(-20px);
    position: absolute;
    left: 50%;
    bottom: 50%;
    width: 180px;
    height: 40px;
    background: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.3)};
    transition: background-color 0.2s ease-in-out;
    border-radius: 0px 32px 32px 0px;
    z-index: ${maxZIndex + 1};
`;

export const VolumeControlButton = styled.div`
    position: relative;

    &:hover {
        ${PlayerButton} {
            border-radius: 0px 0px 50% 50%;
        }

        ${VolumeControlHoveringWrapper} {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            pointer-events: all;
        }
    }
`;

export const MiddleButtonsContainer = styled.div`
    width: 390px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    ${MQ(Breakpoints.s)} {
        width: 450px;
    }

    ${ControlButtonWrapper} {
        min-width: auto;

        ${ControlButtonLabel} {
            display: none;
        }

        ${PlayerButton} {
            width: 48px;
            height: 48px;
        }
    }

    ${MQ(Breakpoints.l)} {
        display: none;
    }
`;

export const EmbeddedMiddleButtonsContainer = styled.div`
    width: 390px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    ${MQ(Breakpoints.s)} {
        width: 450px;
    }

    ${ControlButtonWrapper} {
        min-width: auto;

        ${ControlButtonLabel} {
            display: none;
        }

        ${PlayerButton} {
            width: 48px;
            height: 48px;
        }
    }
`;

export const MobileHeaderControlsContainer = styled.div`
    position: absolute;
    right: 26px;
    top: 26px;
    display: flex;

    ${MQ(Breakpoints.xs)} {
        right: 52px;
        top: 32px;
    }

    ${ControlButtonWrapper} {
        padding: 0 12px 0 0;
        min-width: auto;

        ${ControlButtonLabel} {
            display: none;
        }

        ${PlayerButton} {
            width: 24px;
            height: 24px;

            ${MQ_NOT_TOUCH} {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    ${CloseButton} {
        margin-left: 32px;

        &:hover {
            opacity: 1;
        }

        .SVGInline {
            ${MQ_NOT_TOUCH} {
                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &:first-child {
            border: none;
        }
    }
`;

export const ExtraControlsWrapper = styled.div`
    padding-bottom: 40px;
    position: relative;
    margin-top: 32px;
    padding-top: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: 0.5;
    min-height: ${landscapeCardHeight}px;

    @media (orientation: landscape) {
        padding-bottom: 0;
    }

    ${MQ(Breakpoints.s)} {
        margin-top: 54px;
        padding-bottom: 40px;
    }
`;

export const CloseExtraControls = styled.a`
    top: 24px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;

export const PlayerControls = styled.div<{ isHidden: boolean; activeControlGroup: ControlGroup }>`
  width: 100%;
  height: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  z-index: ${levelingZIndex};
  position: absolute;
  left: 0;
  top: 0;
  background: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.6)};
  opacity: ${props => (props.isHidden ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  cursor: ${props => (props.isHidden ? 'none' : 'default')};

  ${SVGInline} {
    svg {
      path {
        fill: ${props => props.theme.brandedConfig.high_contrast};
      }
    }
  }

  ${MQ(Breakpoints.s)} {
    background: linear-gradient(0deg, ${props => props.theme.brandedConfig.black_color} 0%, ${props =>
    hexToRgba(props.theme.brandedConfig.black_color, 0)} 100%),
    linear-gradient(13deg, rgba(0,0,0,0) 75%, rgba(0,0,0,0.7) 100%);
  }

  ${HeaderControls},
  ${MobileHeaderControlsContainer},
  ${MiddleButtonsContainer},
  ${MetaDataContainer},
  ${SeekBarHolder},
  ${FooterButtonsContainer} {
  ${SliderTitle}
    transition: opacity 0.5s ease-in-out;
  }


  ${props =>
      props.activeControlGroup === ControlGroup.EXTRA
          ? css`
                    ${HeaderControls},
                    ${MobileHeaderControlsContainer},
                    ${MiddleButtonsContainer},
                    ${MetaDataContainer},
                    ${SeekBarHolder},
                    ${FooterButtonsContainer},
                    ${SliderTitle} {
                      opacity: 0;
                      pointer-events: none;
                    }

                    ${ExtraControlsWrapper} {
                      opacity: 1;
                      pointer-events: ${props.isHidden ? 'none' : 'all'};
                      padding-top: 54px;
                      margin-top: 0;

                      ${SliderWrapper} {
                        pointer-events: all;

                        ${SliderTitle} {
                            opacity: 1;
                        }
                      }
                    }
                  `
          : `
                  ${ExtraControlsWrapper} {
                      ${SliderWrapper}{
                        pointer-events: none;

                        ${SliderTitle} {
                            opacity: 0;
                        }
                      }
                  }
            `}

  ${props =>
      props.isHidden
          ? css`
                ${ExtraControlsWrapper} {
                    pointer-events: none;
                }
            `
          : ``}
`;

export const MiniPlayerButton = styled.a`
    width: 47px;
    height: 47px;
    padding: 11px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${MQ(Breakpoints.m)} {
        width: 57px;
        height: 57px;
    }

    .SVGInline {
        display: block;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const MiniPlayerCloseButton = styled.a`
    height: 15px;
    width: 15px;
    display: block;
    z-index: 15;
    cursor: pointer;
    opacity: 1;
    position: absolute;
    right: 10px;
    top: 10px;

    .SVGInline {
        display: block;
    }

    ${MQ(Breakpoints.m)} {
        height: 25px;
        width: 25px;
        right: 11px;
        top: 11px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const MiniPlayerFullscreenButton = styled.a`
    height: 24px;
    width: 24px;
    display: block;
    z-index: 15;
    cursor: pointer;
    opacity: 1;
    position: absolute;
    left: 8px;
    top: 8px;

    ${MQ(Breakpoints.m)} {
        height: 34px;
        width: 34px;
        right: 11px;
        top: 11px;
    }

    .SVGInline {
        display: block;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

export const MiniPlayerUIWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    ${LoadingIndicatorWrapper} {
        width: 48px;
        height: 48px;
        left: calc(50% - 24px) !important;
        top: calc(50% - 24px) !important;
    }
`;

export const MiniSeekbarHolder = styled.div`
    position: absolute;
    bottom: 8px;
    left: 11px;
    right: 11px;
    cursor: move;

    ${MQ(Breakpoints.m)} {
        bottom: 12px;
    }

    ${TimeIndicator} {
        display: none;
    }

    ${ProgressBar} {
        height: 4px;
        top: auto;
        bottom: 0;
        border-radius: 5px;

        ${MQ(Breakpoints.m)} {
            height: 6px;
        }
    }

    ${ProgressAndScrubber} {
        width: 100%;
    }

    ${Scrubber} {
        cursor: move;
    }
`;

export const VolumeControlEmbedded = styled.div<{ isVisible: boolean }>`
    width: ${props => (props.isVisible ? '100%' : '0%')};
    height: ${props => (props.isVisible ? '100%' : '0%')};
    transition: width 0.3s ease-in-out;

    ${VolumeScrubber} {
        transition: width 0.3s ease-in-out;
        width: ${props => (props.isVisible ? '100%' : '0%')};
        height: ${props => (props.isVisible ? '100%' : '0%')};
    }
`;

export const VolumeEmbeddedWrapper = styled.div`
    padding-right: 15px;
    display: none;

    ${MQ(Breakpoints.s)} {
        display: block;
    }

    ${VolumeSubAddButton} {
        display: none;
    }

    ${VolumeControlButton} {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    ${VolumeControlContainer} {
        margin: 0;
    }

    ${VolumeBar} {
        opacity: 0;
        width: 100%;
        max-width: 0%;
    }

    &:hover {
        ${VolumeBar} {
            opacity: 1;
            max-width: 100%;
            transition: width 0.3s ease-in-out;
        }
    }
`;

export const VolumeEmbeddedContainer = styled.div`
    ${ControlButtonLabel} {
        visibility: hidden;
    }

    &:hover {
        ${ControlButtonLabel} {
            visibility: hidden;
        }
    }
`;

export const FullScreenContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 70%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { DropdownContent, DropdownItemSimpleWrapper } from '../DropdownMenu/DropdownMenu.css';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../utils/fnColors';

export const BlockTitle = styled.p`
    font-size: 20px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontBold};
    padding: 14px 16px 14px 50px;
    position: relative;

    ${MQ(Breakpoints.s)} {
        font-size: 16px;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            background-color: ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.07)};
            opacity: 1;
        }
    }

    .SVGInline {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;

        svg {
            width: 6px;
            path {
                fill: ${props => props.theme.brandedConfig.high_contrast};
            }
        }
    }
`;

export const OptionContainer = styled.div`
    ${DropdownItemSimpleWrapper} {
        padding-left: 50px;

        .SVGInline {
            left: 20px;
        }
    }
`;

export const TrackBlock = styled.div`
    width: 50%;
    display: inline-block;

    @media (orientation: portrait) {
        width: 100%;
        padding-top: 5px;
    }

    ${MQ(Breakpoints.s)} {
        padding-top: 0;
        width: 100%;
        display: flex;
        gap: 16px;
    }
`;

export const TrackCol = styled.div`
    min-width: 150px;
`;

export const TrackTitle = styled.p`
    padding: 8px 16px 12px;
    font-size: 16px;
    font-weight: bold;
`;

export const TrackNotAvailable = styled.p`
    padding: 8px 16px;
    color: rgba(235, 235, 241, 0.4);
`;

export const TrackSelectorContentWrapper = styled.div`
    text-align: left;
`;

export const TrackSelectorWrapper = styled.div`
    position: relative;
    z-index: 5;

    ${DropdownContent} {
        left: -7px;
    }
`;

/**
 * Mobile view
 *
 */

export const CloseButton = styled.a`
    height: 27px;
    width: 27px;
    display: block;
    z-index: 15;
    cursor: pointer;
    line-height: 24px;
    opacity: 1;
    position: absolute;
    right: 18.67px;
    top: 18.67px;
    font-size: 24px;

    ${MQ(Breakpoints.xs)} {
        right: 26.67px;
        top: 26.67px;
    }
`;

export const StyledSVGInline = styled(SVGInline)`
    width: 100%;
    height: 100%;

    .SVGInline {
        width: 100%;
        height: 100%;
    }

    .SVGInline-svg {
        width: 100%;
    }
`;

export const TrackSelectorMobileWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: ${maxZIndex};
    width: 100%;
    height: 100%;
    background: ${props => hexToRgba(props.theme.brandedConfig.low_contrast, 0.9)};

    ${DropdownItemSimpleWrapper} {
        background: transparent;
    }

    ${TrackSelectorContentWrapper} {
        padding-top: 64px;
        overflow: auto;
        height: 100%;
    }
`;

import { styled } from 'components/ThemeConfigConnector/ThemeConfigConnnector';
import React from 'react';
import { Breakpoints, MQ } from 'style/styled-components/cssMediaQueries';
import { LoaderWrapper, LoadingRectangle } from 'style/styled-components/reusable.css';
import { EpgFullRowCell } from '../EpgProgramRow/EpgProgramRow.css';
import { ChannelItemContainer, EpgWrapper, SearchFilterWrapper } from './EpgComponent.css';

interface Props {
    isIOS: boolean;
    rowHeight: number;
    isMobile: boolean;
}

const ITEM_NUMBER = 15;

const ROW_WIDTHS = [
    // % of 100 ex. [25, 25, 50]
    [100],
];

const EpgLoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const EpgContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;

    ${SearchFilterWrapper} {
        align-self: flex-end;
    }
`;

const ChannelListContainer = styled.div`
    display: flex;
    gap: 12px;

    ${ChannelItemContainer} {
        cursor: auto;
    }
`;

const EpgRollCell = styled(EpgFullRowCell)<{ width: number }>`
    width: ${props => props.width}%;
`;

const FilterContainer = styled.div``;

const FilterWrapper = styled.div`
    float: left;
    width: 180px;
    height: 48px;
    display: none;
    margin-bottom: 6px;
    margin-top: 6px;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const EpgLoading = ({ isIOS, rowHeight, isMobile }: Props) => {
    const renderGrid = () => {
        const elements = [];

        const maxLength = ROW_WIDTHS.length;
        let widthIndex = 0;

        for (let i = 0; i < ITEM_NUMBER; i += 1) {
            if (widthIndex === maxLength) {
                widthIndex = 0;
            }

            const rowSizeArray = ROW_WIDTHS[widthIndex];

            elements.push(
                <React.Fragment key={`element-${i}`}>
                    <ChannelListContainer>
                        <ChannelItemContainer key={i} rowHeight={rowHeight} isMobile={isMobile} />
                        {rowSizeArray.map((value, rowIndex) => (
                            <EpgRollCell key={`${i}-${rowIndex}`} width={value} height={rowHeight} />
                        ))}
                    </ChannelListContainer>
                </React.Fragment>
            );

            widthIndex += 1;
        }
        return elements;
    };

    return (
        <EpgWrapper isIOS={isIOS}>
            <LoaderWrapper>
                <EpgContainer>
                    <FilterContainer>
                        <FilterWrapper>
                            <LoadingRectangle />
                        </FilterWrapper>
                        <SearchFilterWrapper>
                            <LoadingRectangle />
                        </SearchFilterWrapper>
                    </FilterContainer>
                    <EpgLoadingWrapper>{renderGrid()}</EpgLoadingWrapper>
                </EpgContainer>
            </LoaderWrapper>
        </EpgWrapper>
    );
};

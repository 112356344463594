import React, { FC, useRef } from 'react';
import { ToggleCheckBox, ToggleLabel, ToggleSlider } from './ToggleButton.css';

type toggleProps = {
    checked: boolean;
    dataTestId?: string;
    ignoreClick?: boolean;
    toggleCallback?: (arg: boolean) => void;
};

export const ToggleButton: FC<toggleProps> = ({ checked, toggleCallback, dataTestId, ignoreClick }) => {
    const checkBoxRef = useRef<HTMLInputElement>();

    return (
        <ToggleLabel ignoreClick={ignoreClick}>
            <ToggleCheckBox
                type="checkbox"
                checked={checked}
                ref={checkBoxRef}
                onChange={() => {
                    if (toggleCallback) toggleCallback(checkBoxRef.current.checked);
                }}
            />
            <ToggleSlider data-test-id={dataTestId} />
        </ToggleLabel>
    );
};

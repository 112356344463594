import React, { FC, useState } from 'react';
import {
    CardImage,
    LandscapeImageContainer,
    MoviePortraitImage,
    MoviePortraitImageWrapper,
} from '../../../style/styled-components/reusable.css';
import fallbackLandscape from '../../../assets/images/landscape_fallback.png';
import {
    CardLandscapeWrapper,
    CardWrapper,
    ImageWrapper,
    InfoWrapper,
    SubscriptionType,
    Title,
    SubscriptionPricing,
} from './CardSubscription.css';

import { ServiceOptionCover } from '../../../types/Cover';
import fallbackPortrait from '../../../assets/images/portrait_fallback.png';
import { getCurrencySymbol } from '../../../utils/fnData';

export const CardService: FC<{ asset: ServiceOptionCover }> = ({ asset }) => {
    const { title, price, currency, image, optionTypeLabel, duration, onClick, type } = asset;

    const [portraitImageError, setPortraitImageError] = useState<boolean>(false);

    return (
        <CardWrapper onClick={onClick} tabIndex={0}>
            <CardLandscapeWrapper>
                <ImageWrapper>
                    <LandscapeImageContainer>
                        <CardImage url={image} fallbackImage={fallbackLandscape} />
                    </LandscapeImageContainer>
                    {image && type === 'MOVIE' && !portraitImageError && (
                        <MoviePortraitImageWrapper>
                            <MoviePortraitImage>
                                <CardImage
                                    url={image}
                                    fallbackImage={fallbackPortrait}
                                    {...{
                                        imageError: () => {
                                            setPortraitImageError(true);
                                        },
                                    }}
                                />
                            </MoviePortraitImage>
                        </MoviePortraitImageWrapper>
                    )}
                </ImageWrapper>
                <InfoWrapper>
                    <Title tabIndex={0}>{title}</Title>
                    <SubscriptionType tabIndex={0}>{optionTypeLabel}</SubscriptionType>
                    <SubscriptionPricing tabIndex={0}>
                        {price}
                        {getCurrencySymbol(currency)} {duration ? `/ ${duration}` : ''}
                    </SubscriptionPricing>
                </InfoWrapper>
            </CardLandscapeWrapper>
        </CardWrapper>
    );
};

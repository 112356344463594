import React, { FC, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router';
import icons from '../../style';
import spinner from '../../assets/images/spinner.png';
import { VolumeControl, VolumeControlElements } from '../VolumeControl/VolumeControl';
import { SeekBar } from '../SeekBar/SeekBar';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { PlayerRecordingAction, useRecordingAction } from '../PlayerRecordingActionWindow/PlayerRecordingActionWindow';
import {
    MinimizeButton,
    CastingLabel,
    CloseButton,
    CloseExtraControls,
    ControlButtonLabel,
    ControlButtonWrapper,
    FooterButtonsContainer,
    HeaderControls,
    ExtraControlsWrapper,
    FooterControls,
    FullScreenToggleIcon,
    JumpButtonIcon,
    JumpButtonLabel,
    JumpButtonWrapper,
    LabelMetaData,
    LoadingIndicatorWrapper,
    MetaDataContainer,
    MetaDataWrapper,
    MiddleButtonsContainer,
    PlayerButton,
    PlayerControls,
    PlayerMetaData,
    SeekBarHolder,
    StyledSVGInline,
    Title,
    VolumeControlButton,
    VolumeControlHoveringWrapper,
    HeaderControlsInnerContainer,
    HeaderEmbeddedControls,
    EmbeddedMetaDataContainer,
    EmbeddedPlayerMetaData,
    EmbeddedTitle,
    EmbeddedMetaDataWrapper,
    EmbeddedChanelInfoContainer,
    EmbeddedMiddleButtonsContainer,
    VolumeEmbeddedWrapper,
    VolumeControlEmbedded,
    VolumeEmbeddedContainer,
    ButtonsInnerContainer,
    ChanelInfoContainer,
    Subtitle,
    MetadataSubtitle,
    MetaDataContainerMobile,
    MoreInfoArrow,
    FullScreenContainer,
    UpperContainer,
} from './PlayerUI.css';
import { usePlayer } from '../../providers/player/PlayerContext';
import { buildTimeString } from '../../utils/fnDate';
import translate from '../../utils/fnTranslate';
import {
    LivePlayerAssetInfo,
    PlayerControl,
    PlayerKeyboardControl,
    PlayingMode,
    PropTypesAdditional,
    PropTypesPlayerUICommon,
} from '../../types/Player';
import { ControlGroup } from '../Player/PlayerWrapper';
import { getLocalStorage } from '../../utils/fnStorage';
import { isIphone } from '../../utils/fnDevices';
import { useElementInteractionTracking } from '../../providers/useTracking/TrackingContext';
import { useApp } from '../../providers/useApp/AppContext';
import { useCast } from '../../providers/cast/CastContext';
import { getLivePlayerURL } from '../../utils/fnUrl';
import { isOrientationLandscape } from '../../style/styled-components/cssMediaQueries';
import { DetailMoreModal } from '../DetailMoreModal/DetailMoreModal';
import { PersonRole } from '../../types/Asset';
import KeyboardShortcutOverlay from '../KeyboardShortcutOverlay/KeyboardShortcutOverlay';
import { isLivePlayerAsset, isRecordingPlayerAsset } from '../../utils/fnTypeGuards';
import { usePlayerTabNavigation } from '../../hooks/usePlayerTabNavigation/usePlayerTabNavigation';

const PROGRESS_UPDATE_INTERVAL = 350;
const SKIP_JUMP_VALUE = 10;
const JUMP_DEBOUNCE_DELAY = 500;
const JUMP_RESET_DELAY = 1000;
export const PLAYER_SETTINGS_DROPDOWN_EVENT = 'player-settings-trigger';

interface ControlButtonProps {
    label?: string;
    render?: boolean;
    className?: string;
    onClick?: () => void;
    isEnabled: boolean;
    invisible?: boolean;
    asset?: any;
    controlType?: PlayerControl;
    children?: React.ReactNode;
    tabIndex?: number;
}

interface JumpButtonProps {
    jumpValue: number;
    icon: React.ReactNode;
    isForward: boolean;
    handleJump: () => void;
    totalJumpValue: number;
}

// eslint-disable-next-line react/display-name
export const ControlButton = forwardRef<HTMLDivElement, ControlButtonProps & { isHeaderControl?: boolean }>(
    ({ label, render = true, invisible = false, className, isEnabled, onClick, children, isHeaderControl = false }, ref) => {
        const handleClick = useCallback(() => {
            if (isEnabled && onClick) {
                onClick();
            }
        }, [isEnabled, onClick]);

        if (!render) {
            return null;
        }

        return (
            <ControlButtonWrapper
                className={className}
                onClick={handleClick}
                disabled={!isEnabled}
                invisible={invisible}
                ref={ref}
                tabIndex={isEnabled ? 0 : -1}
                aria-label={label}
                role="button"
                isHeaderControl={isHeaderControl}
            >
                {children}
                {label != null && <ControlButtonLabel data-test-id={`player-control-button-${label}`}>{label}</ControlButtonLabel>}
            </ControlButtonWrapper>
        );
    }
);

const JumpButton: React.FC<JumpButtonProps> = ({ jumpValue, icon, handleJump, totalJumpValue }) => {
    const displayValue = Math.abs(totalJumpValue) === 0 ? jumpValue : Math.abs(totalJumpValue);

    return (
        <JumpButtonWrapper onClick={handleJump}>
            <JumpButtonIcon>{icon}</JumpButtonIcon>
            <JumpButtonLabel>{Math.round(displayValue)}</JumpButtonLabel>
        </JumpButtonWrapper>
    );
};

const BufferingIndicator: FC<{ buffering: boolean }> = ({ buffering }) => {
    if (!buffering) {
        return null;
    }

    return (
        <LoadingIndicatorWrapper>
            <img src={spinner} alt={'spinner'} />
        </LoadingIndicatorWrapper>
    );
};

export const PlayerUI: FC<PropTypesPlayerUICommon & PropTypesAdditional> = ({
    shouldHideLayout,
    isFullScreen,
    onControlsRef,
    onBackClicked,
    toggleFullScreen,
    forceExcludedControls = [],
    extraControls,
    loading,
    metaData,
    embeddedPlayerMetadata,
    logo,
    controls,
    disabledControls,
    title,
    subtitle,
    seekEnabled,
    actionPopup,
    actionLabels,
    mobileOverlayActionHandlers,
    isEmbedded,
    description,
    moreOverlayMetaData,
    cast,
    originalLanguages,
    captionLanguages,
    setLayoutHidden,
}) => {
    const {
        isPlaying,
        volume,
        isMuted,
        isMutedRef,
        isBuffering,
        playingMode,
        setPlayingState,
        setVolumeLevel,
        adjustVolume,
        seek,
        seekStart,
        seekEnd,
        getThumbnail,
        showThumbnail,
        jump,
        getProgressWithJump,
        getSecondaryProgressWithJump,
        getProgress,
        getDuration,
        getPrePadding,
        getPostPadding,
        getSeekMax,
        canUpdateProgress,
        activeControlGroup,
        setActiveControlGroup,
        toggleMute,
        jumpToLive,
        playerError,
        loaded,
        setStreamEndReached,
        setIsEmbedded,
        switchToMiniPlayer,
        asset,
        nextEpisode,
        startOver,
    } = usePlayer();

    const history = useHistory();
    const recordingAction = useRecordingAction(asset);

    const { castConnected, toggleConnect, deviceName } = useCast();

    const interactionTracking = useElementInteractionTracking();

    const volumeBar = useRef(null);
    const volumeValue = useRef(null);
    const { config } = useConfig();

    const scrubber = useRef(null);
    const currentTime = useRef(null);
    const remainingTime = useRef(null);
    const progressLine = useRef(null);
    const bufferLine = useRef(null);
    const hoverLine = useRef(null);
    const isLoaded = useRef(false);
    const scrubberValue = useRef(null);
    const thumbnailImage = useRef(null);
    const thumbnail = useRef(null);
    const availableThumbnail = useRef(null);
    const playing = useRef(isPlaying);
    const nextEpisodeRef = useRef(nextEpisode);

    const fullScreenContainerRef = useRef<HTMLDivElement>();

    const [isScrubberValueShown, setIsScrubberValueShown] = useState<boolean>(false);
    const [isVolumeHovered, setIsVolumeHovered] = useState<boolean>(false);

    const [isOverlayActive, setIsOverlayActive] = useState(false);
    const [isSeeking, setIsSeeking] = useState(false);

    const [overlayIcon, setOverlayIcon] = useState('');
    const [overlayTrigger, setOverlayTrigger] = useState(0);

    const progressUpdateIntervalId = useRef(null);
    const extraControlsLeaveTimeout = useRef(null);

    const [forwardJumpValue, setForwardJumpValue] = useState(SKIP_JUMP_VALUE);
    const [backwardJumpValue, setBackwardJumpValue] = useState(SKIP_JUMP_VALUE);
    const currentForwardJumpDistanceRef = useRef(SKIP_JUMP_VALUE);
    const currentBackwardJumpDistanceRef = useRef(SKIP_JUMP_VALUE);
    const lastJumpTimeRef = useRef(0);
    const timeoutRefs = useRef<number[]>([]);
    const [totalJumpValue] = useState(0);

    const isMobileDevice = getLocalStorage('isMobile');
    const { isMobile, isMobileLandscape, isTablet, isDesktop } = useApp();

    const isPlayerReady = !loading && loaded && !playerError;

    const triggerOverlay = useCallback((icon: string) => {
        setOverlayIcon(icon);
        setOverlayTrigger(Date.now());
    }, []);

    const openOverlay = () => {
        interactionTracking({
            'data-track-id': 'player_show_more',
            'data-asset-name': title,
        });
        setIsOverlayActive(true);
    };

    const closeOverlay = () => {
        setIsOverlayActive(false);
    };

    const onPlayPauseClicked = async () => {
        interactionTracking({
            'data-track-id': 'player_play_pause',
        });

        await setPlayingState(!isPlaying);
    };

    const onJumpToLiveClicked = async () => {
        interactionTracking({
            'data-track-id': 'player_jump_to_live',
        });

        await jumpToLive();
    };

    const onStartOverClicked = async () => {
        interactionTracking({
            'data-track-id': 'player_start_over',
        });

        await startOver();
    };

    const onVolumeClicked = () => {
        interactionTracking({
            'data-track-id': 'player_volume',
        });

        toggleMute();
    };

    const onVolumeButtonClicked = () => {
        onVolumeClicked();
    };

    const onFullScreenToggleClicked = () => {
        interactionTracking({
            'data-track-id': 'player_toggle_full_screen',
        });

        toggleFullScreen();
    };

    const onPlayerCloseClicked = (exitPlayer?: boolean) => {
        interactionTracking({
            'data-track-id': 'player_close',
        });
        if (exitPlayer) {
            setStreamEndReached(true);
        }

        onBackClicked();
    };

    const onVolumeInput = e => {
        setVolumeLevel(parseFloat(e.target.value));
    };

    const onVolumeBarCreated = (volumeControlElements: VolumeControlElements) => {
        volumeBar.current = volumeControlElements.volumeBar;
        volumeValue.current = volumeControlElements.volumeValue;
    };

    const onCastClicked = () => {
        interactionTracking({
            'data-track-id': 'player_cast',
        });

        toggleConnect().catch(() => {});
    };

    const toggleRefDisplay = (ref: any, display: boolean, callback?: () => void) => {
        if (ref?.current?.style) {
            if (!display) {
                ref.current.style.display = 'none';
            } else {
                ref.current.style.display = 'block';
            }
            if (callback) {
                callback();
            }
        }
        return null;
    };

    const { setControlRef, controlRefs } = usePlayerTabNavigation({ disabledControls, setLayoutHidden, onVolumeButtonClicked });

    const setScrubValueAndThumbnailPosition = (playHeadPosition: number, scrubberMiddlePosition: number) => {
        const scrubberWidth = scrubber.current.offsetWidth;
        const scrubberProgress = playHeadPosition * scrubberWidth;

        if (availableThumbnail.current) {
            if (thumbnail.current?.style) {
                const thumbnailWidth = thumbnail.current.offsetWidth;
                const thumbnailPosition = scrubberProgress - thumbnailWidth / 2 + 20 - scrubberMiddlePosition;

                const maxPosition = scrubberWidth - thumbnailWidth;
                const minPosition = 0;

                thumbnail.current.style.left =
                    thumbnailPosition > minPosition
                        ? thumbnailPosition < maxPosition
                            ? `${thumbnailPosition}px`
                            : `${maxPosition}px`
                        : minPosition;

                if (scrubberValue.current?.style) {
                    const minScrubPosition = thumbnailWidth / 2 - 20;
                    const maxScrubPosition = scrubberWidth - thumbnailWidth / 2 - 20;
                    const scrubPosition = scrubberProgress - scrubberMiddlePosition;
                    scrubberValue.current.style.left =
                        scrubPosition > minScrubPosition
                            ? scrubPosition < maxScrubPosition
                                ? `${scrubPosition}px`
                                : `${maxScrubPosition}px`
                            : `${minScrubPosition}px`;
                }
            }
        } else {
            scrubberValue.current.style.left = `${scrubberProgress - scrubberMiddlePosition}px`;
        }
    };

    const setThumbnail = (thumb: any) => {
        if (thumbnail.current?.style) {
            thumbnail.current.style.width = `${thumb.width}px`;
            thumbnail.current.style.height = `${thumb.height}px`;
            thumbnail.current.style.top = `calc(-${thumb.height}px - 40px)`;
        }
        if (thumbnailImage.current?.style) {
            const [url] = thumb.uris;
            thumbnailImage.current.src = url;
            thumbnailImage.current.style.transform = `translate(-${thumb?.positionX}px, -${thumb?.positionY}px)`;
        }
    };

    const getDataForThumbnail = async (seekValue: number) => {
        if (!availableThumbnail.current) {
            return;
        }
        try {
            const thumb = await getThumbnail(seekValue);
            setThumbnail(thumb);
        } catch {
            toggleRefDisplay(thumbnail, false);
        }
    };

    const setScrubberValue = () => {
        const streamDuration = getDuration();
        const seekValue = scrubber.current.value >= streamDuration ? streamDuration - 1 : scrubber.current.value;
        const playHeadPosition = seekValue / streamDuration || 0;
        const scrubberMiddlePosition = playHeadPosition * 20 + 10;

        if (scrubberValue.current?.style) {
            const scrubberStandardValue = scrubber.current.value - getPrePadding();
            const scrubberPaddingValue = -(streamDuration - scrubber.current.value) + getPostPadding();

            if (streamDuration === 0) {
                scrubberValue.current.innerHTML = '00:00:00';
            } else {
                scrubberValue.current.innerHTML =
                    scrubberPaddingValue > 1
                        ? `+${buildTimeString(scrubberPaddingValue, true)}`
                        : buildTimeString(scrubberStandardValue, true);
            }

            getDataForThumbnail(parseFloat(seekValue));
            setScrubValueAndThumbnailPosition(playHeadPosition, scrubberMiddlePosition);
        }
        return null;
    };

    const updateTimeAndSeekRange = () => {
        if (!isLoaded.current) {
            return;
        }

        const duration = getDuration();

        if (duration === 0) {
            return;
        }

        if (canUpdateProgress()) {
            const elapsedTime = getProgressWithJump();

            if (playingMode === PlayingMode.TIME_SHIFT && elapsedTime === 0) {
                return;
            }

            let playedTimeInSeconds = elapsedTime - getPrePadding();
            let remainingTimeInSeconds = -(duration - elapsedTime) + getPostPadding();

            // When in post padding the played time shout not be counting
            playedTimeInSeconds -= remainingTimeInSeconds > 0 ? remainingTimeInSeconds : 0;
            // When in pre padding the remaining time should not count down
            remainingTimeInSeconds -= playedTimeInSeconds < 0 ? playedTimeInSeconds : 0;

            playedTimeInSeconds = playedTimeInSeconds < -getPrePadding() ? 0 : playedTimeInSeconds;
            remainingTimeInSeconds = remainingTimeInSeconds > getPostPadding() ? 0 : remainingTimeInSeconds;

            if (currentTime.current) {
                currentTime.current.innerHTML =
                    Number.isNaN(elapsedTime) || duration === 0 ? '0:00:00' : buildTimeString(playedTimeInSeconds, true);
            }

            if (remainingTime.current) {
                if (duration === 0) {
                    remainingTime.current.innerHTML = '00:00:00';
                } else {
                    remainingTime.current.innerHTML = `${remainingTimeInSeconds > 1 ? '+' : ''}${
                        Number.isNaN(remainingTimeInSeconds) ? '0:00:00' : buildTimeString(remainingTimeInSeconds, true)
                    }`;
                }
            }

            if (scrubber.current) {
                scrubber.current.min = 0;
                scrubber.current.max = duration ?? 1;
                scrubber.current.value = elapsedTime;
            }

            const playHeadPosition = elapsedTime / duration || 0;
            if (progressLine.current?.style) {
                progressLine.current.style.width = `${playHeadPosition * 100}%`;
            }
        }

        const secondaryProgress = getSecondaryProgressWithJump() / duration || 0;
        if (bufferLine.current?.style) {
            bufferLine.current.style.width = `${secondaryProgress * 100}%`;
        }
    };

    const onSeekInput = useCallback(async () => {
        const seekMax = getSeekMax();
        const streamDuration = getDuration();

        if (scrubber.current.value > seekMax && seekMax > 0) {
            scrubber.current.value = seekMax;
        }

        const seekValue = scrubber.current.value >= streamDuration ? streamDuration - 1 : scrubber.current.value;
        const playHeadPosition = seekValue / streamDuration || 0;

        if (progressLine.current?.style) {
            progressLine.current.style.width = `${playHeadPosition * 100}%`;
        }

        setScrubberValue();
        await seek(parseFloat(seekValue));
    }, [seek]);

    const onSeekStart = () => {
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        if (availableThumbnail.current) {
            toggleRefDisplay(thumbnail, true);
        }
        setScrubberValue();
        toggleRefDisplay(scrubberValue, true, () => {
            setIsScrubberValueShown(true);
        });
        setIsSeeking(true);
        seekStart();
    };

    const onSeekEnd = () => {
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        if (availableThumbnail.current) {
            toggleRefDisplay(thumbnail, false);
        }

        progressUpdateIntervalId.current = setInterval(updateTimeAndSeekRange, PROGRESS_UPDATE_INTERVAL);
        toggleRefDisplay(scrubberValue, false, () => {
            setIsScrubberValueShown(false);
        });
        setIsSeeking(false);
        seekEnd();
    };

    const onSeekBarCreated = ref => {
        scrubber.current = ref.scrubber;
        currentTime.current = ref.currentTime;
        remainingTime.current = ref.remainingTime;
        progressLine.current = ref.progressLine;
        hoverLine.current = ref.hoverLine;
        bufferLine.current = ref.bufferLine;
        scrubberValue.current = ref.scrubberValue;
        thumbnail.current = ref.thumbnailContainer;
        thumbnailImage.current = ref.thumbnailImage;
    };

    const getMetaData = (meta: any) => {
        if (meta?.length) {
            return meta.map((obj, index) => {
                return (
                    <React.Fragment key={`meta-${index}`}>
                        <LabelMetaData data-test-id={'player-content-subtitle'} key={`meta-data-${index}`}>
                            {obj}
                        </LabelMetaData>
                    </React.Fragment>
                );
            });
        }

        return null;
    };

    const getMaxJumpValue = (): number => {
        const theshHoldSeconds = (config.app_config.player_settings.binge_watching_threshold / 100) * getDuration();

        if (getSeekMax() === 0) return Infinity;

        if (nextEpisodeRef.current != null) {
            return Math.round(getSeekMax() - getProgress() - theshHoldSeconds);
        }

        return Math.round(getSeekMax() - getProgress());
    };

    const getVolumeIcon = useCallback(() => {
        if (volume === 0 || isMuted) {
            return icons.volumeMutedIcon;
        }

        if (volume <= 0.5 && volume > 0) {
            return icons.volumeMinIcon;
        }

        return icons.volumeIcon;
    }, [isMuted, volume]);

    useEffect(() => {
        nextEpisodeRef.current = nextEpisode;
    }, [nextEpisode]);

    const clearTimeouts = () => timeoutRefs.current.forEach(clearTimeout);

    const handleJump = useCallback(
        (direction: 'forward' | 'backward', isKeyboard: boolean = false) => {
            const now = Date.now();
            const timeSinceLastJump = now - lastJumpTimeRef.current;
            const isForward = direction === 'forward';
            const currentProgress = getProgress();
            const maxJump = getMaxJumpValue();

            if (scrubber.current) {
                scrubber.current.blur();
            }

            clearTimeouts();

            let newJumpValue;

            const currentJumpDistanceRef = isForward ? currentForwardJumpDistanceRef : currentBackwardJumpDistanceRef;
            const setJumpValue = isForward ? setForwardJumpValue : setBackwardJumpValue;

            if (timeSinceLastJump < JUMP_DEBOUNCE_DELAY) {
                newJumpValue = currentJumpDistanceRef.current + (isForward ? SKIP_JUMP_VALUE : -SKIP_JUMP_VALUE);
            } else {
                newJumpValue = isForward ? SKIP_JUMP_VALUE : -SKIP_JUMP_VALUE;
            }

            if (isForward) {
                newJumpValue = Math.min(newJumpValue, maxJump);
            } else {
                newJumpValue = Math.max(newJumpValue, -currentProgress);
            }

            currentJumpDistanceRef.current = newJumpValue;
            setJumpValue(Math.round(Math.abs(newJumpValue)));
            lastJumpTimeRef.current = now;

            if (isKeyboard) {
                triggerOverlay(isForward ? 'jumpFw' : 'jumpBw');
            }

            interactionTracking({
                'data-track-id': isForward ? 'player_jump_fwd' : 'player_jump_rwd',
            });

            timeoutRefs.current.push(
                window.setTimeout(() => {
                    jump(newJumpValue);
                }, JUMP_DEBOUNCE_DELAY),
                window.setTimeout(() => {
                    setJumpValue(SKIP_JUMP_VALUE);
                    currentJumpDistanceRef.current = SKIP_JUMP_VALUE;
                }, JUMP_RESET_DELAY)
            );
        },
        [jump, triggerOverlay, getMaxJumpValue, getProgress, interactionTracking]
    );

    useEffect(() => () => clearTimeouts(), []);

    const getRecordingLabel = actionLabels => {
        if (actionLabels && PlayerControl.RECORDING in actionLabels) {
            return translate(actionLabels[PlayerControl.RECORDING]);
        }
        return '';
    };

    const centerControls = () => {
        if (isScrubberValueShown) {
            return null;
        }
        return (
            <>
                <ControlButton
                    key={PlayerControl.JUMP_BACK}
                    render={controls.includes(PlayerControl.JUMP_BACK)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                    label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                    ref={setControlRef(PlayerControl.JUMP_BACK)}
                    onClick={() => handleJump('backward')}
                >
                    <PlayerButton data-test-id={'player-skip-back-button'}>
                        <JumpButton
                            jumpValue={backwardJumpValue}
                            icon={<StyledSVGInline svg={icons.iconJumpBackwardStrong} />}
                            isForward={false}
                            handleJump={() => handleJump('backward')}
                            totalJumpValue={totalJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.PLAY_PAUSE}
                    label={translate(isPlaying ? 'SCREEN_PLAYER_PAUSE_BUTTON' : 'SCREEN_PLAYER_PLAY_BUTTON')}
                    invisible={!controls.includes(PlayerControl.PLAY_PAUSE)}
                    isEnabled={!disabledControls.includes(PlayerControl.PLAY_PAUSE)}
                    onClick={onPlayPauseClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.JUMP_FORWARD}
                    render={controls.includes(PlayerControl.JUMP_FORWARD)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                    label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                    ref={setControlRef(PlayerControl.JUMP_FORWARD)}
                    onClick={() => handleJump('forward')}
                >
                    <PlayerButton data-test-id={'player-skip-forward-button'}>
                        <JumpButton
                            jumpValue={forwardJumpValue}
                            icon={<StyledSVGInline svg={icons.iconJumpForwardStrong} />}
                            isForward={true}
                            handleJump={() => handleJump('forward')}
                            totalJumpValue={totalJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const embeddedCenterControls = () => {
        if (isScrubberValueShown) {
            return null;
        }
        return (
            <>
                <ControlButton
                    key={PlayerControl.JUMP_BACK}
                    render={controls.includes(PlayerControl.JUMP_BACK)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                    label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                    ref={setControlRef(PlayerControl.JUMP_BACK)}
                    onClick={() => handleJump('backward')}
                >
                    <PlayerButton data-test-id={'player-skip-back-button'}>
                        <JumpButton
                            jumpValue={backwardJumpValue}
                            icon={<StyledSVGInline svg={icons.iconJumpBackwardStrong} />}
                            isForward={false}
                            handleJump={() => handleJump('backward')}
                            totalJumpValue={totalJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
                ,
                <ControlButton
                    key={PlayerControl.PLAY_PAUSE}
                    label={translate(isPlaying ? 'SCREEN_PLAYER_PAUSE_BUTTON' : 'SCREEN_PLAYER_PLAY_BUTTON')}
                    invisible={!controls.includes(PlayerControl.PLAY_PAUSE)}
                    isEnabled={!disabledControls.includes(PlayerControl.PLAY_PAUSE)}
                    onClick={onPlayPauseClicked}
                >
                    <PlayerButton>
                        <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
                    </PlayerButton>
                </ControlButton>
                <ControlButton
                    key={PlayerControl.JUMP_FORWARD}
                    render={controls.includes(PlayerControl.JUMP_FORWARD)}
                    isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                    label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                >
                    <PlayerButton data-test-id={'player-skip-forward-button'}>
                        <JumpButton
                            jumpValue={SKIP_JUMP_VALUE}
                            icon={<StyledSVGInline svg={icons.iconJumpForwardStrong} />}
                            isForward={true}
                            handleJump={() => handleJump('forward')}
                            totalJumpValue={totalJumpValue}
                        />
                    </PlayerButton>
                </ControlButton>
            </>
        );
    };

    const DesktopControls = () => {
        const defaultButtons = [
            <ControlButton
                key={PlayerControl.JUMP_BACK}
                render={controls.includes(PlayerControl.JUMP_BACK)}
                isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                controlType={PlayerControl.JUMP_BACK}
                ref={setControlRef(PlayerControl.JUMP_BACK)}
                onClick={() => handleJump('backward')}
            >
                <PlayerButton data-test-id={'player-skip-back-button'}>
                    <JumpButton
                        jumpValue={backwardJumpValue}
                        icon={<StyledSVGInline svg={icons.iconJumpBackwardStrong} />}
                        isForward={false}
                        handleJump={() => handleJump('backward')}
                        totalJumpValue={totalJumpValue}
                    />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.JUMP_FORWARD}
                render={controls.includes(PlayerControl.JUMP_FORWARD)}
                isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                controlType={PlayerControl.JUMP_FORWARD}
                ref={setControlRef(PlayerControl.JUMP_FORWARD)}
                onClick={() => handleJump('forward')}
            >
                <PlayerButton data-test-id={'player-skip-forward-button'}>
                    <JumpButton
                        jumpValue={forwardJumpValue}
                        icon={<StyledSVGInline svg={icons.iconJumpForwardStrong} />}
                        isForward={true}
                        handleJump={() => handleJump('forward')}
                        totalJumpValue={totalJumpValue}
                    />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.START_OVER}
                label={translate('SCREEN_PLAYER_STARTOVER_BUTTON')}
                render={controls.includes(PlayerControl.START_OVER)}
                isEnabled={!disabledControls.includes(PlayerControl.START_OVER)}
                ref={setControlRef(PlayerControl.START_OVER)}
                onClick={onStartOverClicked}
            >
                <PlayerButton>
                    <StyledSVGInline data-test-id={'player-startover-button'} svg={icons.icoStartOver} />
                </PlayerButton>
            </ControlButton>,
            <VolumeControlButton key="volume-control-button">
                <ControlButton
                    key={PlayerControl.VOLUME}
                    render={controls.includes(PlayerControl.VOLUME)}
                    label={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                    isEnabled={true}
                    ref={setControlRef(PlayerControl.VOLUME)}
                >
                    <PlayerButton
                        onClick={onVolumeClicked}
                        data-test-id={'player-volume-button'}
                        className={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                    >
                        <StyledSVGInline svg={getVolumeIcon()} />
                    </PlayerButton>
                    <VolumeControlHoveringWrapper>
                        <VolumeControl onVolumeInput={onVolumeInput} onVolumeBarCreated={onVolumeBarCreated} />
                    </VolumeControlHoveringWrapper>
                </ControlButton>
            </VolumeControlButton>,
            <ControlButton
                key={PlayerControl.MORE_MEDIA}
                render={controls.includes(PlayerControl.MORE_MEDIA)}
                isEnabled={!disabledControls.includes(PlayerControl.MORE_MEDIA)}
                ref={setControlRef(PlayerControl.MORE_MEDIA)}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.moreMediaIcon} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.NEXT_ASSET}
                render={controls.includes(PlayerControl.NEXT_ASSET)}
                label={translate('HINTS_BUTTON_NEXT')}
                isEnabled={!disabledControls.includes(PlayerControl.NEXT_ASSET)}
                ref={setControlRef(PlayerControl.NEXT_ASSET)}
            >
                <PlayerButton key={'next'}>
                    <StyledSVGInline svg={icons.nextAssetIcon} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.SUBTITLE}
                render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
                isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                ref={setControlRef(PlayerControl.SUBTITLE)}
                onClick={() => document.dispatchEvent(new Event(PLAYER_SETTINGS_DROPDOWN_EVENT))}
            >
                <PlayerButton data-test-id={'player-settings-button'} className="settings-button">
                    {actionPopup?.[PlayerControl.SUBTITLE]}
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.FULL_SCREEN}
                render={controls.includes(PlayerControl.FULL_SCREEN)}
                isEnabled={!disabledControls.includes(PlayerControl.FULL_SCREEN)}
                label={translate('SCREEN_PLAYER_FULLSCREEN_BUTTON')}
                onClick={onFullScreenToggleClicked}
                ref={setControlRef(PlayerControl.FULL_SCREEN)}
            >
                <PlayerButton data-test-id={'player-fullscreen-button'} key={'fullscreen'}>
                    <StyledSVGInline
                        data-test-id={isFullScreen ? 'player-fullscreen-icon-true' : 'player-fullscreen-icon-false'}
                        svg={isFullScreen ? icons.shrinkScreenIcon : icons.fullScreenIcon}
                    />
                </PlayerButton>
            </ControlButton>,
        ];

        const livePlayerButtons = [
            <ControlButton
                key={PlayerControl.JUMP_BACK}
                render={controls.includes(PlayerControl.JUMP_BACK)}
                isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                ref={setControlRef(PlayerControl.JUMP_BACK)}
                onClick={() => handleJump('backward')}
            >
                <PlayerButton data-test-id={'player-skip-back-button'}>
                    <JumpButton
                        jumpValue={backwardJumpValue}
                        icon={<StyledSVGInline svg={icons.iconJumpBackwardStrong} />}
                        isForward={false}
                        handleJump={() => handleJump('backward')}
                        totalJumpValue={totalJumpValue}
                    />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.JUMP_FORWARD}
                render={controls.includes(PlayerControl.JUMP_FORWARD)}
                isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                ref={setControlRef(PlayerControl.JUMP_FORWARD)}
                onClick={() => handleJump('forward')}
            >
                <PlayerButton data-test-id={'player-skip-forward-button'}>
                    <JumpButton
                        jumpValue={forwardJumpValue}
                        icon={<StyledSVGInline svg={icons.iconJumpForwardStrong} />}
                        isForward={true}
                        handleJump={() => handleJump('forward')}
                        totalJumpValue={totalJumpValue}
                    />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.GO_TO_LIVE}
                render={controls.includes(PlayerControl.GO_TO_LIVE)}
                isEnabled={!disabledControls.includes(PlayerControl.GO_TO_LIVE)}
                label={translate('SCREEN_PLAYER_JUMP_TO_LIVE_BUTTON')}
                onClick={onJumpToLiveClicked}
                ref={setControlRef(PlayerControl.GO_TO_LIVE)}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.icoGoToLiveProgram} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.START_OVER}
                label={translate('SCREEN_PLAYER_STARTOVER_BUTTON')}
                render={controls.includes(PlayerControl.START_OVER)}
                isEnabled={!disabledControls.includes(PlayerControl.START_OVER)}
                ref={setControlRef(PlayerControl.START_OVER)}
                onClick={onStartOverClicked}
            >
                <PlayerButton>
                    <StyledSVGInline data-test-id={'player-startover-button'} svg={icons.icoStartOver} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.RECORDING}
                render={!forceExcludedControls.includes(PlayerControl.RECORDING) && controls.includes(PlayerControl.RECORDING)}
                label={getRecordingLabel(actionLabels)}
                isEnabled={!disabledControls.includes(PlayerControl.RECORDING)}
                ref={setControlRef(PlayerControl.RECORDING)}
                onClick={() => recordingAction.onRecordingOptionClicked()}
            >
                <PlayerRecordingAction asset={asset} />
            </ControlButton>,
            <VolumeControlButton key="volume-control-button">
                <ControlButton
                    key={PlayerControl.VOLUME}
                    render={controls.includes(PlayerControl.VOLUME)}
                    label={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                    isEnabled={true}
                    ref={setControlRef(PlayerControl.VOLUME)}
                >
                    <PlayerButton
                        onClick={onVolumeClicked}
                        data-test-id={'player-volume-button'}
                        className={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                    >
                        <StyledSVGInline svg={getVolumeIcon()} />
                    </PlayerButton>
                    <VolumeControlHoveringWrapper>
                        <VolumeControl onVolumeInput={onVolumeInput} onVolumeBarCreated={onVolumeBarCreated} />
                    </VolumeControlHoveringWrapper>
                </ControlButton>
            </VolumeControlButton>,
            <ControlButton
                key={PlayerControl.MORE_MEDIA}
                render={controls.includes(PlayerControl.MORE_MEDIA)}
                isEnabled={!disabledControls.includes(PlayerControl.MORE_MEDIA)}
                ref={setControlRef(PlayerControl.MORE_MEDIA)}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.moreMediaIcon} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.NEXT_ASSET}
                render={controls.includes(PlayerControl.NEXT_ASSET)}
                label={translate('HINTS_BUTTON_NEXT')}
                isEnabled={!disabledControls.includes(PlayerControl.NEXT_ASSET)}
                ref={setControlRef(PlayerControl.NEXT_ASSET)}
            >
                <PlayerButton key={'next'}>
                    <StyledSVGInline svg={icons.nextAssetIcon} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.SUBTITLE}
                render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
                isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                ref={setControlRef(PlayerControl.SUBTITLE)}
                onClick={() => document.dispatchEvent(new Event(PLAYER_SETTINGS_DROPDOWN_EVENT))}
            >
                <PlayerButton data-test-id={'player-settings-button'} className="settings-button">
                    {actionPopup?.[PlayerControl.SUBTITLE]}
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.FULL_SCREEN}
                render={controls.includes(PlayerControl.FULL_SCREEN)}
                isEnabled={!disabledControls.includes(PlayerControl.FULL_SCREEN)}
                label={translate('SCREEN_PLAYER_FULLSCREEN_BUTTON')}
                onClick={onFullScreenToggleClicked}
                ref={setControlRef(PlayerControl.FULL_SCREEN)}
            >
                <PlayerButton data-test-id={'player-fullscreen-button'} key={'fullscreen'}>
                    <StyledSVGInline
                        data-test-id={isFullScreen ? 'player-fullscreen-icon-true' : 'player-fullscreen-icon-false'}
                        svg={isFullScreen ? icons.shrinkScreenIcon : icons.fullScreenIcon}
                    />
                </PlayerButton>
            </ControlButton>,
        ];

        const recordingPlayerButtons = [
            <ControlButton
                key={PlayerControl.JUMP_BACK}
                render={controls.includes(PlayerControl.JUMP_BACK)}
                isEnabled={!disabledControls.includes(PlayerControl.JUMP_BACK)}
                label={translate('SCREEN_PLAYER_SKIP_BWD_BUTTON')}
                ref={setControlRef(PlayerControl.JUMP_BACK)}
                onClick={() => handleJump('backward')}
            >
                <PlayerButton data-test-id={'player-skip-back-button'}>
                    <JumpButton
                        jumpValue={backwardJumpValue}
                        icon={<StyledSVGInline svg={icons.iconJumpBackwardStrong} />}
                        isForward={false}
                        handleJump={() => handleJump('backward')}
                        totalJumpValue={totalJumpValue}
                    />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.JUMP_FORWARD}
                render={controls.includes(PlayerControl.JUMP_FORWARD)}
                isEnabled={!disabledControls.includes(PlayerControl.JUMP_FORWARD)}
                label={translate('SCREEN_PLAYER_SKIP_FWD_BUTTON')}
                ref={setControlRef(PlayerControl.JUMP_FORWARD)}
                onClick={() => handleJump('forward')}
            >
                <PlayerButton data-test-id={'player-skip-forward-button'}>
                    <JumpButton
                        jumpValue={forwardJumpValue}
                        icon={<StyledSVGInline svg={icons.iconJumpForwardStrong} />}
                        isForward={true}
                        handleJump={() => handleJump('forward')}
                        totalJumpValue={totalJumpValue}
                    />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.START_OVER}
                label={translate('SCREEN_PLAYER_STARTOVER_BUTTON')}
                render={controls.includes(PlayerControl.START_OVER)}
                isEnabled={!disabledControls.includes(PlayerControl.START_OVER)}
                ref={setControlRef(PlayerControl.START_OVER)}
                onClick={onStartOverClicked}
            >
                <PlayerButton>
                    <StyledSVGInline data-test-id={'player-startover-button'} svg={icons.icoStartOver} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.RECORDING}
                render={!forceExcludedControls.includes(PlayerControl.RECORDING) && controls.includes(PlayerControl.RECORDING)}
                label={getRecordingLabel(actionLabels)}
                isEnabled={!disabledControls.includes(PlayerControl.RECORDING)}
                ref={setControlRef(PlayerControl.RECORDING)}
                onClick={() => recordingAction.onRecordingOptionClicked()}
            >
                <PlayerRecordingAction asset={asset} />
            </ControlButton>,
            <VolumeControlButton key="volume-control-button">
                <ControlButton
                    key={PlayerControl.VOLUME}
                    render={controls.includes(PlayerControl.VOLUME)}
                    label={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                    ref={setControlRef(PlayerControl.VOLUME)}
                    isEnabled={true}
                >
                    <PlayerButton
                        onClick={onVolumeClicked}
                        data-test-id={'player-volume-button'}
                        className={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                    >
                        <StyledSVGInline svg={getVolumeIcon()} />
                    </PlayerButton>
                    <VolumeControlHoveringWrapper>
                        <VolumeControl onVolumeInput={onVolumeInput} onVolumeBarCreated={onVolumeBarCreated} />
                    </VolumeControlHoveringWrapper>
                </ControlButton>
            </VolumeControlButton>,
            <ControlButton
                key={PlayerControl.MORE_MEDIA}
                render={controls.includes(PlayerControl.MORE_MEDIA)}
                isEnabled={!disabledControls.includes(PlayerControl.MORE_MEDIA)}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.moreMediaIcon} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.NEXT_ASSET}
                render={controls.includes(PlayerControl.NEXT_ASSET)}
                label={translate('HINTS_BUTTON_NEXT')}
                isEnabled={!disabledControls.includes(PlayerControl.NEXT_ASSET)}
            >
                <PlayerButton key={'next'}>
                    <StyledSVGInline svg={icons.nextAssetIcon} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.SUBTITLE}
                render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
                isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                ref={setControlRef(PlayerControl.SUBTITLE)}
                onClick={() => document.dispatchEvent(new Event(PLAYER_SETTINGS_DROPDOWN_EVENT))}
            >
                <PlayerButton data-test-id={'player-settings-button'} className="settings-button">
                    {actionPopup?.[PlayerControl.SUBTITLE]}
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.FULL_SCREEN}
                render={controls.includes(PlayerControl.FULL_SCREEN)}
                isEnabled={!disabledControls.includes(PlayerControl.FULL_SCREEN)}
                label={translate('SCREEN_PLAYER_FULLSCREEN_BUTTON')}
                ref={setControlRef(PlayerControl.FULL_SCREEN)}
                onClick={onFullScreenToggleClicked}
            >
                <PlayerButton data-test-id={'player-fullscreen-button'} key={'fullscreen'}>
                    <StyledSVGInline
                        data-test-id={isFullScreen ? 'player-fullscreen-icon-true' : 'player-fullscreen-icon-false'}
                        svg={isFullScreen ? icons.shrinkScreenIcon : icons.fullScreenIcon}
                    />
                </PlayerButton>
            </ControlButton>,
        ];

        const orderedButtons =
            (isLivePlayerAsset(asset) ? livePlayerButtons : isRecordingPlayerAsset(asset) ? recordingPlayerButtons : defaultButtons) || [];

        return (
            <FooterButtonsContainer>
                <ButtonsInnerContainer>{orderedButtons}</ButtonsInnerContainer>
            </FooterButtonsContainer>
        );
    };

    const MobileTabletControls = () => {
        const defaultButtons = [
            <ControlButton
                key={PlayerControl.SUBTITLE}
                render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                onClick={() => document.dispatchEvent(new Event(PLAYER_SETTINGS_DROPDOWN_EVENT))}
                label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.icoSettings} />
                </PlayerButton>
            </ControlButton>,
        ];

        const livePlayerButtons = [
            <ControlButton
                key={PlayerControl.GO_TO_LIVE}
                render={controls.includes(PlayerControl.GO_TO_LIVE)}
                isEnabled={!disabledControls.includes(PlayerControl.GO_TO_LIVE)}
                label={translate('SCREEN_PLAYER_JUMP_TO_LIVE_BUTTON')}
                onClick={onJumpToLiveClicked}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.icoGoToLiveProgram} />
                </PlayerButton>
            </ControlButton>,
            <ControlButton
                key={PlayerControl.RECORDING}
                render={!forceExcludedControls.includes(PlayerControl.RECORDING) && controls.includes(PlayerControl.RECORDING)}
                label={getRecordingLabel(actionLabels)}
                isEnabled={!disabledControls.includes(PlayerControl.RECORDING)}
                ref={setControlRef(PlayerControl.RECORDING)}
                onClick={() => recordingAction.onRecordingOptionClicked()}
            >
                <PlayerRecordingAction asset={asset} />
            </ControlButton>,
            <ControlButton
                key={PlayerControl.SUBTITLE}
                render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                onClick={() => setActiveControlGroup(ControlGroup.AUDIO_SUBTITLE)}
                label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.icoSettings} />
                </PlayerButton>
            </ControlButton>,
        ];

        const recordingPlayerButtons = [
            <ControlButton
                key={PlayerControl.RECORDING}
                render={!forceExcludedControls.includes(PlayerControl.RECORDING) && controls.includes(PlayerControl.RECORDING)}
                isEnabled={!disabledControls.includes(PlayerControl.RECORDING)}
                label={getRecordingLabel(actionLabels)}
                controlType={PlayerControl.RECORDING}
                asset={asset}
                onClick={() => recordingAction.onRecordingOptionClicked()}
            >
                <PlayerRecordingAction asset={asset} />
            </ControlButton>,
            <ControlButton
                key={PlayerControl.SUBTITLE}
                render={!forceExcludedControls.includes(PlayerControl.SUBTITLE) && controls.includes(PlayerControl.SUBTITLE)}
                isEnabled={!disabledControls.includes(PlayerControl.SUBTITLE)}
                onClick={() => setActiveControlGroup(ControlGroup.AUDIO_SUBTITLE)}
                label={translate('SCREEN_PLAYER_SETTINGS_BUTTON')}
            >
                <PlayerButton>
                    <StyledSVGInline svg={icons.icoSettings} />
                </PlayerButton>
            </ControlButton>,
        ];

        const orderedButtons =
            (isLivePlayerAsset(asset) ? livePlayerButtons : isRecordingPlayerAsset(asset) ? recordingPlayerButtons : defaultButtons) || [];

        return (
            <FooterButtonsContainer>
                <ButtonsInnerContainer>{orderedButtons}</ButtonsInnerContainer>
            </FooterButtonsContainer>
        );
    };

    const ResponsiveFooterButtons = ControlsComponent => (
        <FooterButtonsContainer>
            <ButtonsInnerContainer>{ControlsComponent()}</ButtonsInnerContainer>
        </FooterButtonsContainer>
    );

    const onExtraControlsHovered = () => {
        clearTimeout(extraControlsLeaveTimeout.current);
        extraControlsLeaveTimeout.current = setTimeout(() => {
            if (activeControlGroup === ControlGroup.BASE && !isMobileDevice && extraControls != null) {
                setActiveControlGroup(ControlGroup.EXTRA);
            }
        }, 250);
    };

    const closeExtraControls = () => {
        setActiveControlGroup(ControlGroup.BASE);
    };

    const onExtraControlsLeave = () => {
        clearTimeout(extraControlsLeaveTimeout.current);
        extraControlsLeaveTimeout.current = setTimeout(() => {
            closeExtraControls();
        }, 500);
    };

    const onSwipeUp = () => {
        if (activeControlGroup === ControlGroup.BASE && extraControls != null) {
            setActiveControlGroup(ControlGroup.EXTRA);
        }
    };

    const onSwipeDown = () => {
        closeExtraControls();
    };

    const calculateThumbnailPosition = (cursorX, scrubberWidth, thumbnailCenterOffset, thumbnailWidth) => {
        let position = cursorX - thumbnailCenterOffset;
        position = Math.max(position, 0);
        position = Math.min(position, scrubberWidth - thumbnailWidth);

        return position;
    };

    // TODO: hide hover thumb while buffering
    //       the if below no worky :|
    const getThumbnailOnHover = e => {
        // if (loading || isBuffering) return;

        const scrubberRect = e.target.getBoundingClientRect();
        const cursorX = e.clientX - scrubberRect.left;
        const thumbnailWidth = thumbnail.current.offsetWidth;
        const thumbnailCenterOffset = thumbnailWidth / 2;

        const streamDuration = getDuration();
        const scrubberWidth = scrubberRect.width;
        const hoverValue = (cursorX / scrubberWidth) * streamDuration;
        const thumbnailPosition = calculateThumbnailPosition(cursorX, scrubberWidth, thumbnailCenterOffset, thumbnailWidth);

        getDataForThumbnail(hoverValue);

        if (availableThumbnail.current) {
            toggleRefDisplay(thumbnail, true);
        }

        hoverLine.current.style.width = `${cursorX}px`;
        thumbnail.current.style.left = `${thumbnailPosition}px`;
    };

    const clearThumbnailOnHover = () => {
        hoverLine.current.style.width = '0px';
        toggleRefDisplay(thumbnail, false);
    };

    useEffect(() => {
        if (volumeBar.current && volumeValue.current) {
            const displayValue = isMuted ? 0 : volume;

            volumeBar.current.value = displayValue;
            volumeValue.current.style.width = `${displayValue * 100}%`;
        }
    }, [volume, isMuted, volumeBar.current, volumeValue.current]);

    useEffect(() => {
        if (showThumbnail) {
            availableThumbnail.current = true;
            getDataForThumbnail(0);
        }
    }, [showThumbnail]);

    useEffect(() => {
        isLoaded.current = loaded;
        if (progressUpdateIntervalId.current) {
            clearInterval(progressUpdateIntervalId.current);
        }
        progressUpdateIntervalId.current = setInterval(updateTimeAndSeekRange, PROGRESS_UPDATE_INTERVAL);
    }, [title, loaded, playingMode]);

    useEffect(() => {
        return () => {
            clearInterval(progressUpdateIntervalId.current);
            clearTimeout(extraControlsLeaveTimeout.current);
        };
    }, []);

    const handlePlayerKeyboardControl = (event: KeyboardEvent) => {
        switch (event.code) {
            case PlayerKeyboardControl.SPACE:
                if (!isEmbedded) {
                    interactionTracking({
                        'data-track-id': 'player_play_pause',
                    });
                    setPlayingState(!playing.current);
                    triggerOverlay(!playing.current ? 'pause' : 'play');
                }
                break;
            case PlayerKeyboardControl.LEFTARROW:
                handleJump('backward', true);
                break;
            case PlayerKeyboardControl.RIGHTARROW:
                handleJump('forward', true);
                break;
            case PlayerKeyboardControl.F:
                if (!isEmbedded) {
                    toggleFullScreen();
                }
                break;
            case PlayerKeyboardControl.M:
                if (!isEmbedded) {
                    toggleMute();
                    triggerOverlay(isMutedRef.current ? 'mute' : 'unmute');
                }
                break;
            case PlayerKeyboardControl.UPARROW:
                if (!isEmbedded) {
                    const iconUp = adjustVolume(true);
                    triggerOverlay(iconUp);
                }
                break;
            case PlayerKeyboardControl.DOWNARROW:
                if (!isEmbedded) {
                    const iconDown = adjustVolume(false);
                    triggerOverlay(iconDown);
                }
                break;
            case PlayerKeyboardControl.ESCAPE:
                onPlayerCloseClicked();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const handleFocusChange = () => {
            const { activeElement } = document;
            const isFocusedWithinPlayer = Array.from(controlRefs.current.values()).some(el => el === activeElement);

            setLayoutHidden(!isFocusedWithinPlayer);
        };

        handleFocusChange();

        document.addEventListener('focus', handleFocusChange, true);
        document.addEventListener('blur', handleFocusChange, true);

        return () => {
            document.removeEventListener('focus', handleFocusChange, true);
            document.removeEventListener('blur', handleFocusChange, true);
        };
    }, [controlRefs, setLayoutHidden]);

    useEffect(() => {
        const orientationChangeHandler = () => {
            setTimeout(() => {
                if (isOrientationLandscape() && isEmbedded && isMobileDevice && asset) {
                    history.push(getLivePlayerURL((asset as LivePlayerAssetInfo).channelId));
                }
            }, 200);
        };

        window.addEventListener('keyup', handlePlayerKeyboardControl);
        window.addEventListener('orientationchange', orientationChangeHandler);
        if (!isEmbedded) {
            fullScreenContainerRef.current?.addEventListener('dblclick', toggleFullScreen);
        }

        return () => {
            window.removeEventListener('keyup', handlePlayerKeyboardControl);
            fullScreenContainerRef.current?.removeEventListener('dblclick', toggleFullScreen);
            window.removeEventListener('orientationchange', orientationChangeHandler);
        };
    }, [isEmbedded, isMobileDevice, asset]);

    useEffect(() => {
        playing.current = isPlaying;
    }, [isPlaying]);

    useEffect(() => {
        isMutedRef.current = isMuted;
    }, [isMuted]);

    return (
        <>
            {isOverlayActive && (
                <DetailMoreModal
                    modalTitle={title}
                    modalText={description}
                    showMoreMetadataTitle={getMetaData(moreOverlayMetaData?.firstLine)}
                    showMoreMetadataContent={getMetaData(moreOverlayMetaData?.secondLine)}
                    metadata={getMetaData(metaData)}
                    cast={cast?.filter(person => person.role === PersonRole.ACTOR)}
                    directors={cast?.filter(person => person.role === PersonRole.DIRECTOR)}
                    original={originalLanguages}
                    caption={captionLanguages}
                    closeOverlay={() => closeOverlay()}
                />
            )}
            <PlayerControls isHidden={shouldHideLayout} activeControlGroup={activeControlGroup} ref={ref => onControlsRef(ref)}>
                <>
                    <FullScreenContainer ref={fullScreenContainerRef} />
                    {!isEmbedded && (
                        <HeaderControls key={'desktop-header-controls'}>
                            <HeaderControlsInnerContainer>
                                {!playerError && isDesktop() && (
                                    <ControlButton
                                        key={PlayerControl.INFO}
                                        render={controls.includes(PlayerControl.INFO)}
                                        label={translate('SCREEN_PLAYER_INFO_BUTTON')}
                                        isEnabled={!disabledControls.includes(PlayerControl.INFO)}
                                        onClick={openOverlay}
                                        ref={setControlRef(PlayerControl.INFO)}
                                        isHeaderControl={true}
                                    >
                                        <PlayerButton>
                                            <StyledSVGInline svg={icons.infoIcon} />
                                        </PlayerButton>
                                    </ControlButton>
                                )}
                                <ControlButton
                                    key={PlayerControl.CAST}
                                    render={!forceExcludedControls.includes(PlayerControl.CAST) && controls.includes(PlayerControl.CAST)}
                                    label={translate('SCREEN_PLAYER_CAST_BUTTON')}
                                    isEnabled={!disabledControls.includes(PlayerControl.CAST)}
                                    onClick={onCastClicked}
                                    ref={setControlRef(PlayerControl.CAST)}
                                    isHeaderControl={true}
                                >
                                    <PlayerButton>
                                        <StyledSVGInline
                                            data-test-id={'player-cast-button'}
                                            svg={castConnected ? icons.castConnectedIcon : icons.castIcon}
                                        />
                                    </PlayerButton>
                                </ControlButton>
                                {!playerError && (
                                    <ControlButton
                                        key={PlayerControl.MINIMIZE}
                                        label={translate('SCREEN_PLAYER_MINIMIZE_BUTTON')}
                                        isEnabled={!disabledControls.includes(PlayerControl.MINIMIZE)}
                                        onClick={onPlayerCloseClicked}
                                        ref={setControlRef(PlayerControl.MINIMIZE)}
                                        isHeaderControl={true}
                                    >
                                        <MinimizeButton data-test-id={'player-back-button'}>
                                            <StyledSVGInline svg={icons.icoMinimize} />
                                        </MinimizeButton>
                                    </ControlButton>
                                )}
                                <ControlButton
                                    key={PlayerControl.CLOSE}
                                    label={translate('CLOSE_BUTTON')}
                                    isEnabled={!disabledControls.includes(PlayerControl.CLOSE)}
                                    onClick={() => {
                                        onPlayerCloseClicked(true);
                                    }}
                                    ref={setControlRef(PlayerControl.CLOSE)}
                                    isHeaderControl={true}
                                >
                                    <CloseButton>
                                        <StyledSVGInline svg={icons.closeIcon} />
                                    </CloseButton>
                                </ControlButton>
                            </HeaderControlsInnerContainer>
                        </HeaderControls>
                    )}

                    {isEmbedded && (
                        <HeaderEmbeddedControls>
                            <VolumeEmbeddedContainer>
                                <ControlButton
                                    key={PlayerControl.VOLUME}
                                    render={controls.includes(PlayerControl.VOLUME)}
                                    label={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                                    isEnabled={true}
                                >
                                    <VolumeEmbeddedWrapper
                                        onMouseEnter={() => setIsVolumeHovered(true)}
                                        onMouseLeave={() => setIsVolumeHovered(false)}
                                    >
                                        <VolumeControlButton>
                                            <PlayerButton
                                                data-test-id={'player-volume-button'}
                                                className={translate('SCREEN_PLAYER_VOLUME_BUTTON')}
                                                onClick={onVolumeClicked}
                                            >
                                                <StyledSVGInline svg={getVolumeIcon()} />
                                            </PlayerButton>
                                            <VolumeControlEmbedded isVisible={isVolumeHovered}>
                                                <VolumeControl onVolumeInput={onVolumeInput} onVolumeBarCreated={onVolumeBarCreated} />
                                            </VolumeControlEmbedded>
                                        </VolumeControlButton>
                                    </VolumeEmbeddedWrapper>
                                </ControlButton>
                            </VolumeEmbeddedContainer>
                            {!playerError && !isMobile() && (
                                <MinimizeButton
                                    data-test-id={'player-back-button'}
                                    onClick={() => {
                                        setIsEmbedded(false);
                                        switchToMiniPlayer({
                                            params: {
                                                channelId: (asset as LivePlayerAssetInfo).channelId,
                                            },
                                            type: (asset as LivePlayerAssetInfo).type,
                                        });
                                    }}
                                >
                                    <StyledSVGInline svg={icons.icoMinimize} />
                                </MinimizeButton>
                            )}
                            {!isIphone() && !playerError && (
                                <ControlButton
                                    key={PlayerControl.FULL_SCREEN}
                                    render={controls.includes(PlayerControl.FULL_SCREEN)}
                                    isEnabled={!disabledControls.includes(PlayerControl.FULL_SCREEN)}
                                    className={'fullscren-btn'}
                                >
                                    <PlayerButton
                                        onClick={() => {
                                            const liveURL = getLivePlayerURL((asset as LivePlayerAssetInfo).channelId);
                                            const separator = liveURL.includes('?') ? '&' : '?';
                                            history.push(`${liveURL}${separator}live=true`);
                                        }}
                                    >
                                        <FullScreenToggleIcon svg={isFullScreen ? icons.shrinkScreenIcon : icons.fullScreenIcon} />
                                    </PlayerButton>
                                </ControlButton>
                            )}
                        </HeaderEmbeddedControls>
                    )}

                    {!isEmbedded && !isDesktop() && !playerError && (
                        <MiddleButtonsContainer key={'mobile-middle-controls'}>{centerControls()}</MiddleButtonsContainer>
                    )}

                    {isEmbedded && !playerError && (
                        <EmbeddedMiddleButtonsContainer key={'embedded-middle-controls'}>
                            {embeddedCenterControls()}
                        </EmbeddedMiddleButtonsContainer>
                    )}

                    {!isScrubberValueShown && isEmbedded && !playerError && (
                        <EmbeddedMetaDataContainer>
                            <EmbeddedChanelInfoContainer>{logo}</EmbeddedChanelInfoContainer>
                            {title && <EmbeddedTitle data-test-id={'player-content-title'}>{title}</EmbeddedTitle>}
                            <EmbeddedPlayerMetaData>
                                <EmbeddedMetaDataWrapper>{getMetaData(embeddedPlayerMetadata)}</EmbeddedMetaDataWrapper>
                            </EmbeddedPlayerMetaData>
                        </EmbeddedMetaDataContainer>
                    )}

                    {!isScrubberValueShown && !isEmbedded && (
                        <MetaDataContainerMobile>
                            {logo && (
                                <UpperContainer>
                                    <ChanelInfoContainer>{logo}</ChanelInfoContainer>
                                </UpperContainer>
                            )}
                            {subtitle && <Subtitle data-test-id={'player-content-subtitle'}>{subtitle}</Subtitle>}
                            <PlayerMetaData>
                                {title && (
                                    <Title data-test-id={'player-content-title'} onClick={openOverlay}>
                                        {title}
                                        <MoreInfoArrow>
                                            <StyledSVGInline svg={icons.rightArrowIconSmall} />
                                        </MoreInfoArrow>
                                    </Title>
                                )}
                                <MetaDataWrapper>{getMetaData(metaData)}</MetaDataWrapper>
                                {subtitle && <MetadataSubtitle data-test-id={'player-content-subtitle'}>{subtitle}</MetadataSubtitle>}
                            </PlayerMetaData>
                        </MetaDataContainerMobile>
                    )}

                    <Swipeable onSwipedUp={onSwipeUp} onSwipedDown={onSwipeDown}>
                        {!isScrubberValueShown && !isEmbedded && (
                            <MetaDataContainer>
                                {logo && <ChanelInfoContainer>{logo}</ChanelInfoContainer>}
                                {title && <Title data-test-id={'player-content-title'}>{title}</Title>}
                                {subtitle && <Subtitle data-test-id={'player-content-subtitle'}>{subtitle}</Subtitle>}
                            </MetaDataContainer>
                        )}
                        <FooterControls
                            isEmbedded={isEmbedded}
                            data-test-id={'footer-controls'}
                            hasExtraControls={extraControls != null}
                            activeControlGroup={activeControlGroup}
                            withAnimation={true}
                            hasOpacity={loading || !loaded || (!isSeeking && isBuffering && sessionStorage.getItem('e_smv') !== 'true')} // jank
                            isMobile={isMobile()}
                        >
                            {castConnected && deviceName && (
                                <CastingLabel>{translate('FEATURE_CAST_SENDER_HINT', { receiver_name: deviceName })}</CastingLabel>
                            )}
                            <SeekBarHolder isEmbedded={isEmbedded}>
                                {isDesktop() && !isEmbedded && !playerError && (
                                    <ControlButton
                                        key={PlayerControl.PLAY_PAUSE}
                                        render={controls.includes(PlayerControl.PLAY_PAUSE)}
                                        isEnabled={!disabledControls.includes(PlayerControl.PLAY_PAUSE)}
                                        onClick={onPlayPauseClicked}
                                        ref={setControlRef(PlayerControl.PLAY_PAUSE)}
                                    >
                                        <PlayerButton data-test-id={'player-play-pause-button'}>
                                            <StyledSVGInline svg={isPlaying ? icons.pauseIconSimple : icons.playIconSimple} />
                                        </PlayerButton>
                                    </ControlButton>
                                )}
                                <SeekBar
                                    enabled={seekEnabled && !playerError}
                                    onSeekInput={onSeekInput}
                                    onSeekStart={onSeekStart}
                                    onSeekEnd={onSeekEnd}
                                    onSeekBarCreated={onSeekBarCreated}
                                    onMouseHover={getThumbnailOnHover}
                                    onMouseLeave={clearThumbnailOnHover}
                                    ref={setControlRef(PlayerControl.SEEKBAR)}
                                />
                            </SeekBarHolder>

                            {!isTablet() &&
                                !isMobile() &&
                                !isMobileLandscape() &&
                                !isEmbedded &&
                                !playerError &&
                                ResponsiveFooterButtons(DesktopControls)}

                            {(isTablet() || isMobileLandscape() || isMobile()) &&
                                !isEmbedded &&
                                !playerError &&
                                ResponsiveFooterButtons(MobileTabletControls)}

                            {extraControls != null && (
                                <ExtraControlsWrapper onMouseEnter={onExtraControlsHovered} onMouseLeave={onExtraControlsLeave}>
                                    {activeControlGroup === ControlGroup.EXTRA && (
                                        <CloseExtraControls onClick={closeExtraControls}>
                                            <StyledSVGInline svg={icons.downArrowIcon} />
                                        </CloseExtraControls>
                                    )}
                                    {extraControls}
                                </ExtraControlsWrapper>
                            )}
                        </FooterControls>
                    </Swipeable>
                </>
            </PlayerControls>
            <KeyboardShortcutOverlay
                iconName={overlayIcon}
                overlayTrigger={overlayTrigger}
                isEnabled={isPlayerReady}
                forwardJumpValue={forwardJumpValue}
                backwardJumpValue={backwardJumpValue}
            />

            {(isMobileLandscape() || isMobile() || isTablet()) && <>{mobileOverlayActionHandlers}</>}
            <BufferingIndicator buffering={(loading || !loaded || isBuffering) && !playerError} />
        </>
    );
};

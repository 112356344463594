import React from 'react';
import { useLocation } from 'react-router';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { ExternalLink, FooterWrapper, InternalLink, LinksBlock } from './Footer.css';
import { useScreenState } from '../../hooks/useScreenState/useScreenState';
import translate from '../../utils/fnTranslate';
import { useMenu } from '../../hooks/useMenu';
import { usePageScroller } from '../PageScroller/usePageScroller';
import { useSearch } from '../../providers/useSearch/SearchContext';

const FooterMenu: React.FC = () => {
    const { config } = useConfig();
    const footerMenuData = config?.content_config?.footer_menu ?? [];
    const location = useLocation();
    const { emptyStates } = useScreenState();
    const { scrollTo } = usePageScroller();
    const { exitSearch } = useSearch();
    const pageConfig = config?.getPageConfigByLocation({
        route: {
            path: location.pathname,
            isExact: true,
            url: '',
            params: {},
        },
        params: {},
    });
    const pageId = pageConfig?.id ?? '';
    const { activeMenuIndex } = useMenu(
        pageId,
        footerMenuData.filter(item => item.id && item.id.length)
    );

    const handleMenuItemClick = (path: string) => {
        if (path) {
            emptyStates(path);
        }
        scrollTo(0);
        exitSearch(false);
    };

    return (
        <>
            {footerMenuData.map((item, index) => {
                const link = item.href || config.getPagePath(item.id) || '';
                const { href, target, title } = item;
                let isActive = activeMenuIndex === index;

                // menu has no active item -> fallback to home
                if (activeMenuIndex === -1 && item?.id === 'HOME') {
                    isActive = true;
                }

                const text = translate(title);

                return href ? (
                    <ExternalLink
                        active={isActive ? 'active' : null}
                        key={index}
                        href={link}
                        target={target || ''}
                        onClick={() => handleMenuItemClick(null)}
                        data-event-name={'click'}
                        data-track-id={`menu_footer_${text.toLowerCase()}`}
                        data-test-id={`footer-menu-${text.toLowerCase()}`}
                    >
                        {text}
                    </ExternalLink>
                ) : (
                    <InternalLink
                        active={isActive ? 'active' : null}
                        key={index}
                        to={link}
                        target={target || ''}
                        onClick={() => handleMenuItemClick(link)}
                        data-event-name={'click'}
                        data-track-id={`menu_footer_${text.toLowerCase()}`}
                        data-test-id={`footer-menu-${text.toLowerCase()}`}
                    >
                        {text}
                    </InternalLink>
                );
            })}
        </>
    );
};

export const Footer: React.FC = () => {
    return (
        <FooterWrapper data-test-id="footer">
            <LinksBlock aria-label="Main">
                <FooterMenu />
            </LinksBlock>
        </FooterWrapper>
    );
};

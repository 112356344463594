import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../../utils/fnColors';
import { MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { ButtonPlayOutline } from '../../Buttons/ButtonPlayOutline/ButtonPlayOutline';

export const portraitMixedCardWidth = 176;
export const portraitMixedCardHeight = 264;
export const portraitMixedCardHoveredWidth = 186;
export const portraitMixedCardHoveredHeight = 280;

export const portraitMovieCardWidth = 176;
export const portraitMovieCardHeight = 264;
export const portraitMovieCardHoveredWidth = 186;
export const portraitMovieCardHoveredHeight = 280;

export const animationType = 'ease-in-out';
export const animationDuration = '150ms';
export const animationDelay = '150ms';

export const ImageWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    opacity: 0.9;
    border: 2px solid transparent;

    img {
        width: 100%;
    }
`;

export const StyledButtonPlay = styled(ButtonPlayOutline)`
    opacity: 0;
    transition: opacity 300ms ${animationType} 0ms;
    pointer-events: none;
`;

export const FallbackTitle = styled.div`
    height: 48px;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    top: calc(50% - 24px);
    left: 0;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;

    span {
        text-align: center;
        font-size: 16px;
        width: 100%;
        display: block;
        font-family: ${fontBold};
    }
`;

export const CardPortraitWrapper = styled.div<{ mixed: boolean }>`
    width: ${props => (props.mixed ? portraitMixedCardWidth : portraitMovieCardWidth)}px;
    height: ${props => (props.mixed ? portraitMixedCardHeight : portraitMovieCardHeight)}px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    box-shadow: 0 4px 8px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.05)};
    transition: opacity ${animationDuration} ${animationType} ${animationDelay};

    &:focus-visible {
        outline: var(--focus-outline);
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            ${StyledButtonPlay} {
                transition: opacity 300ms ${animationType} 200ms;
                opacity: 1;
                pointer-events: all;
            }

            ${ImageWrapper} {
                outline: var(--focus-outline-solid);
                opacity: 1;
            }
        }
    }
`;

export const pageTypes = {
    modular: 'modular_page',
    search: 'search_page',
    static: 'static_page',
    default: 'default_page',
    recording: 'recording_page',
};

export type PageType = 'search_page' | 'modular_page' | 'static_page' | 'default_page';
export type ModuleItemType = 'module' | 'page' | 'menu_tab' | 'deep_link' | 'view_all_link' | 'collection';

export type SettingsOptions =
    | 'SETTINGS_PIN'
    | 'SETTINGS_LANGUAGE'
    | 'SETTINGS_ABOUT_APP'
    | 'SETTINGS_RECORDINGS'
    | 'SETTINGS_AUTOPLAY'
    | 'SETTINGS_DEVICE_MANAGEMENT'
    | 'SETTINGS_ACCOUNT';

export type ApiConfig = {
    timeout_limit: number;
    update_interval: number;
    backend_error_update_interval: number;
    slowlog_limit: number;
    page_size: number;
    config_id: string;
    routes: {
        Vod: {
            get_movie_details: string;
            get_series_details: string;
            rent_vod: string;
            purchased_titles: string;
            get_series_category: string;
            get_similar: string;
        };
        Tv: {
            get_channels: string;
            get_programs: string;
            get_category: string;
            get_tv_channels_by_category: string;
            get_now_next: string;
            get_cast_crew: string;
            get_details: string;
            get_movie_related: string;
            get_series_related: string;
            get_live_asset: string;
            get_related: string;
            recommendations: string;
        };
        Auth: {
            auth: string;
            sso: string;
            user_info: string;
            change_credentials: string;
        };
        DRM: {
            register_device: string;
            heartbeat: string;
            get_drm_token: string;
            license_server_widevine: string;
            license_server_playready: string;
            license_server_fairplay: string;
            fairplay_cert_url: string;
            get_all_devices: string;
            delete_device: string;
        };
        Playback: {
            create_session: string;
            keep_alive: string;
            end_session: string;
        };
        Pin: {
            manage: string;
            reset: string;
        };
        Entitlement: {
            purchased_products: string;
            entitled_products: string;
            get_subscription_options_by_product_code: string;
            get_subscription_options_by_channel_code: string;
            order_product: string;
        };
        World: {
            get_root_id: string;
            get_categories: string;
            keywords_and_names: string;
            category_content: string; // TODO: to be removed
            dynamic_movies: string; // TODO: to be removed
            dynamic_series: string;
            content_by_category: string;
        };
        Recommendations: {
            movie: string;
            series: string;
        };
        Bookmark: {
            save_bookmark: string; // TODO: remove
            save: string;
        };
        Search: {
            search_movies: string;
            search_series: string;
        };
        Recording: {
            manage: string;
            get_by_event: string;
            get_scheduled: string;
            get_movies: string;
            get_episodes: string;
            get_quota: string;
            get_all_recordings: string;
        };
    };
    maintenance_mode: {
        start: number;
        end: number;
    };
};

export type AppConfig = {
    general: {
        title: string;
        language: string;
    };
    player_settings: {
        buffers?: {
            [key: string]: {
                bufferingGoal?: number;
                rebufferingGoal?: number;
                bufferBehind?: number;
            };
        };
        hide_controls_after: number;
        skip_back_interval: number;
        skip_forward_interval: number;
        bookmark_threshold: number;
        binge_watching_threshold: number;
        binge_watching_start_timeout: number;
        binge_watching_inactivity_timeout: number;
        timeshift_disable_duration_threshold: number;
        manifest_suffix?: {
            [key: string]: {
                chrome?: string;
                edge?: string;
                firefox?: string;
                safari?: string;
            };
        };
        startover_min_position_ms: number;
        playback_languages_subtitles: ConfigLanguages[];
        playback_languages_audio: ConfigLanguages[];
        bookmark_save_interval: number;
    };
    content_markers: {
        _DEFAULT: ContentMarker;
        CONTENT_MARKER_NOW: ContentMarker;
        CONTENT_MARKER_LIVE: ContentMarker;
        CONTENT_MARKER_NOT_SUBSCRIBED: ContentMarker;
        CONTENT_MARKER_NEW: ContentMarker;
        CONTENT_MARKER_FREE: ContentMarker;
        CONTENT_MARKER_NEW_EPISODES: ContentMarker;
    };
    epg_settings: {
        past_visibility: number;
        future_visibility: number;
        prime_time: number;
        prime_time_dst: string;
        row_height: number;
        day_start_hour: number;
        pixels_per_minute: number;
    };
    languages: {
        et: Language;
        en: Language;
        ru: Language;
    };
    product_settings: {
        replay_window_time_machine: number;
        replay_window_time_machine_plus: number;
        product_code_time_machine_plus: string;
        linear_prefix: string;
        npvr_prefix: string;
        subscription_update_delay: number;
    };
    pin_settings: {
        allowed_wrong_attempts: number;
        wrong_attempts_lock_duration: number;
    };
    search_settings: {
        key_threshold: number;
        trigger_timeout: number;
    };
    auth: {
        realm: string;
        client_id: string;
        google_cast_client_id: string;
    };
    miscellanious: {
        max_items_collection: number;
        gallery_auto_scroll: number;
        detail_page_small_image_threshold: number;
        sign_up_link: string;
        sign_up_link_huub: string;
        youbora_account_code: string;
    };
    locale_settings: {
        currency: string;
        currency_sign: string;
        date_format: string;
        decimal_seperator: string;
        number_seperator: string;
        time_format: string;
    };
    chromecast: {
        receiver_id: string;
        namespace: string;
        media_namespace: string;
    };
    smart_app_banner: {
        android_app_id: string;
        ios_app_id: string;
    };
    meta_tags: {
        [key: string]: {
            meta_title: string;
            meta_description: string;
        };
    };
};

export type FeatureControl = {
    search: boolean;
    guest_mode: boolean;
    guest_mode_show_login: boolean;
    mini_epg: boolean;
    multiple_audio: boolean;
    subtitles: boolean;
    google_cast: boolean;
    account_profile_language: boolean;
    epg_search: boolean;
    analytics: boolean;
    timeshift: boolean;
    dev_mode: boolean;
    pvr: boolean;
    buy_tvod: boolean;
    npvr_upsell: boolean;
    mini_player: boolean;
    thumbnail_scroll: boolean;
    self_service: boolean;
};

export type ContentMarker = {
    color: string;
    background: string;
    transparency?: number;
    priority?: number;
};

export type Language = {
    url: string;
    image: string;
    default: boolean;
    data?: Object;
};

export type LayoutConfig = {
    advanced: boolean;
    title: string;
    language: string;
    logo?: string;
    primary: string;
    background: string;
    secondary: string;
    black_color: string;
    white_color: string;
    success_color: string;
    error_color: string;
    scrollbar_color: string;
    background_image?: string;
    empty_page_message?: string;
    access_forbidden?: string;
    low_contrast: string;
    high_contrast: string;
    login_required?: boolean;
    notification_background_color: string;
    epg_live_program_color?: string;
    epg_hover_color?: string;
    dropdown_background_color?: string;
};

export type EditorialItemAction = {
    type: ModuleItemType;
    page_id?: string;
    collection_id?: string;
    menu_tab_id?: string;
    module?: ModuleConfigs;
    url?: string;
};

export type EditorialStyle = {
    width?: number;
    height?: number;
    padding?: string;
    icon?: string;
    font_size?: number;
    text_color?: string;
    background_image?: string;
    gradient?: {
        from?: string;
        to?: string;
    };
};

export type EditorialItem = {
    title: string;
    style?: EditorialStyle;
    action: EditorialItemAction;
    show_when_subscribed?: string;
};

export type ModuleConfigs = {
    title: string;
    icon: string;
    scope: string;
    module: string;
    type: string;
    variant: string;
    datasource: Datasource;
    style: {};
    config: {
        auto_slide: string;
        module?: ModuleConfigs;
    };
    collection: Collection;
    id: string;
    min_elements: number;
    children: [];
    assets?: [];
    items?: EditorialItem[];
    route: string;
    foreignId?: string;
    disabled?: boolean;
};

export type Params = {
    filter: string[];
    sort: string[];
    term: string[];
    size: string[];
};

export type Datasource = {
    Service: string;
    request: {
        method: string;
        variables?: { [key: string]: any };
    };
    params?: Params;
    cacheKey?: string;
    shouldReload?: boolean;
};

export type Collection = {
    id: string;
    type: string;
    variant: string;
    datasource: Datasource;
};

export type Configs = {
    title: string;
    id: string;
    type: PageType;
    modules: ModuleConfigs[];
    layout_config: LayoutConfig;
    path: string;
    subpages: string[];
    parent: string;
    isCollectionPage: boolean;
};

export type SettingsMenu = {
    title: string;
    href?: string;
    id: string;
    type?: string;
    target?: string;
    action: any;
    action_name?: string;
    internal?: string;
};

export type MainMenu = {
    title: string;
    id: string;
    icon: string;
};

export type FooterMenu = {
    title: string;
    id: string;
    href?: string;
    target?: string;
};

export type ContentConfig = {
    configs: Configs[];
    settings_menu: SettingsMenu[];
    main_menu: MainMenu[];
    footer_menu: FooterMenu[];
};

export type PageConfigs = {
    id: string;
    modules: ModuleConfigs[];
    parent: string;
    path: string;
    subpages: string[];
    title: string;
    type: string;
};

export type Config = {
    configEnvironment: string;
    isMock: boolean;
    default_language: string;
    playerName: string;
    playerOptions: string;
    measurementsEnabled: boolean;
    api_config: ApiConfig;
    app_config: AppConfig;
    feature_control: FeatureControl;
    layout_config: LayoutConfig;
    content_config: ContentConfig;
    _page_ids: {};
    _page_configs: PageConfigs;
    _editorial_module_configs: {};
};

export type ConfigResponse = {};

export type ConfigError = {};

export type ConfigLanguages = {
    iso_code: string;
    title: string;
};

import { SettingsMenu } from 'types/Config';
import {
    SETTINGS_PIN,
    SETTINGS_RECORDINGS,
    SETTINGS_AUTOPLAY,
    SETTINGS_ABOUT_APP,
    SETTINGS_LANGUAGE,
    SETTINGS_DEVICE_MANAGEMENT,
    SETTINGS_ACCESSIBILITY,
} from 'components/Application';

export const settingsMenuStucture: SettingsMenu[] = [
    {
        id: 'SETTINGS_PIN',
        title: 'SETTINGS_PIN',
        action: {
            type: 'custom',
        },
        internal: 'SETTINGS_PIN',
        action_name: 'SETTINGS_SET_PIN_LINK',
    },
    {
        title: 'SETTINGS_LANGUAGE',
        id: 'SETTINGS_LANGUAGE',
        action: {
            type: 'deeplinking',
            url: '/settings/language',
        },
        action_name: 'SETTINGS_MANAGE_LANGUAGE_PREFERENCES',
    },
    {
        title: 'SETTINGS_ACCESSIBILITY',
        id: 'SETTINGS_ACCESSIBILITY',
        action: {
            type: 'deeplinking',
            url: '/settings/accessibility',
        },
        action_name: 'SETTINGS_ACCESSIBILITY',
    },
    {
        id: 'SETTINGS_RECORDINGS',
        title: 'SETTINGS_RECORDINGS',
        action: {
            type: 'deeplinking',
            url: '/settings/recordings',
        },
        action_name: 'SETTINGS_RECORDINGS_LINK',
    },
    {
        id: 'SETTINGS_AUTOPLAY',
        title: 'SETTINGS_AUTOPLAY',
        action: {
            type: 'deeplinking',
            url: '/settings/autoplay',
        },
        action_name: 'SETTINGS_AUTOPLAY_LINK',
    },
    {
        id: 'SETTINGS_DEVICE_MANAGEMENT',
        title: 'SETTINGS_DEVICE_MANAGEMENT',
        action: {
            type: 'deeplinking',
            url: '/settings/device-manager',
        },
        action_name: 'SETTINGS_DEVICE_MANAGEMENT_LINK',
    },
    {
        title: 'SETTINGS_ABOUT_APP',
        id: 'SETTINGS_ABOUT_APP',
        action: {
            type: 'deeplinking',
            url: '/settings/about',
        },
        action_name: 'SETTINGS_APP_INFO_LINK',
    },
    {
        id: 'SETTINGS_ACCOUNT',
        title: 'SETTINGS_ACCOUNT',
        action: {
            type: 'deeplinking',
            url: '/settings/account',
        },
        action_name: 'SETTINGS_ACCOUNT_INFO',
    },
];

export const getOptionIconName = (type: string) => {
    switch (type) {
        case SETTINGS_PIN:
            return 'unlockIcon';
        case SETTINGS_RECORDINGS:
            return 'icoRecord';
        case SETTINGS_AUTOPLAY:
            return 'playOutlineIcon';
        case SETTINGS_ABOUT_APP:
            return 'infoIcon';
        case SETTINGS_LANGUAGE:
            return 'icoLanguage';
        case SETTINGS_DEVICE_MANAGEMENT:
            return 'icoDevices';
        case SETTINGS_ACCESSIBILITY:
            return 'accessibility';
        default:
            return 'icoSettingsPlaceholder';
    }
};

export const redirectTo = (url: string) => {
    window.open(url, '_blank');
};

import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import SVGInline from 'react-svg-inline';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import icons from '../../style';
import translate from '../../utils/fnTranslate';
import { CardService } from '../Cards/CardSubscription/CardSubscription';
import { ServiceOptionCover } from '../../types/Cover';
import { ModalNavigation, ModalCloseButton, ModalWrapper } from '../../style/styled-components/reusable.css';
import { CardWrapper, OptionsContent, OptionSubtext, OptionTitle } from './UpsellOptionSelector.css';

type PropTypes = {
    options: ServiceOptionCover[];
    onClose: () => void;
};

const ServiceSelector: FC<PropTypes> = ({ options, onClose }) => {
    useKeyboardShortcuts({
        escape: onClose,
    });

    useEffect(() => {
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
        const root = document.getElementById('root');
        root.setAttribute('inert', '');

        return () => {
            root.removeAttribute('inert');
        };
    }, []);

    return createPortal(
        <ModalWrapper opacity={1} aria-modal>
            <ModalNavigation>
                <ModalCloseButton onClick={onClose} tabIndex={0}>
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>
            <OptionsContent role="alert">
                <OptionTitle tabIndex={0}>{`${translate('SCREEN_UPSELL_TITLE')}`}</OptionTitle>
                <OptionSubtext tabIndex={0}>{`${translate('SCREEN_UPSELL_SUBTEXT')} `}</OptionSubtext>
                <CardWrapper>
                    {options.map((option, index) => (
                        <CardService key={`${index}-${option.id}`} asset={option} />
                    ))}
                </CardWrapper>
            </OptionsContent>
        </ModalWrapper>,
        document.getElementById('modal-root')
    );
};

export const UpsellOptionSelector = withNoScroll(ServiceSelector);

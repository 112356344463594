import React, { CSSProperties, FC, MouseEvent, useEffect, useRef, useState, FocusEvent } from 'react';
import { useLocation, useHistory } from 'react-router';
import { usePlayer } from 'providers/player/PlayerContext';
import { useEpgStore } from 'providers/useEpgStore/EpgContext';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import { Channel } from 'types/Asset';
import Api from 'api/Api';
import { useDispatch } from 'react-redux';
import { channelsAction } from 'providers/reducers/channel-reducer';
import { useAriaOnlyNotification } from 'components/Notification/Notification';
import { EpgProgram } from '../../../types/EpgTypes';
import {
    AirTimeText,
    HoverActionIconsContainer,
    HoveredTitleContainer,
    HoverStateContainer,
    HoverStateInnerWrapper,
    PlayIcon,
    ProgramItem,
    ProgramTitle,
    TitleText,
} from './EpgProgramItem.css';
import { getHourMinute, getTextWidth } from '../../../utils/fnEpg';
import { LinkTrackable } from '../../Trackable/LinkTrackable';
import { getBroadcastDetailsURL, getCatchupPlayerURL, getLivePlayerURL } from '../../../utils/fnUrl';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { getMiniPlayerProps, isCatchUpCapable } from '../../../utils/fnData';
import { useConfig } from '../../../providers/useConfig/ConfigContext';
import { isFuture, isNow, isPast } from '../../../utils/fnDate';
import { MILLISECONDS, MINUTES } from '../../../utils/TimeUnit';
import { useBroadcastTimeCluster } from '../../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { useElementInteractionTracking } from '../../../providers/useTracking/TrackingContext';
import { useChannels } from '../../../providers/useChannels/useChannels';
import { useApp } from '../../../providers/useApp/AppContext';
import { PlayingAssetType } from '../../../types/Player';
import { usePlayOptions } from '../../../providers/usePlayOptions/PlayOptionsContext';
import translate from '../../../utils/fnTranslate';

const HOVER_BUTTON_CONTAINER_WIDTH = 75;
const HOVER_EXTRA_RIGHT_OFFSET = 45;

const HOVER_FONT_SIZE = 16;
const HOVER_FONT_SIZE_AIRING = 14;
const HOVER_FONT_FAMILY = 'Open Sans Bold';
const HOVER_FONT_WEIGHT = 'bold';
const HOVER_FONT_WEIGHT_AIRING = 'normal';

const HOVER_LEFT_FROM_RIGHT_PARTIAL = -5;
const HOVER_DEFAULT_LEFT = -3;
const HOVER_WIDTH_EXTRA_OFFSET = -4;

const DEFAULT_HOVER_STYLE_PROPS: CSSProperties = {
    top: '0px',
    left: '0px',
    opacity: 1,
};

const HoveredProgramItem: FC<{
    program: EpgProgram;
    playIcon: any;
    playIconHovered: any;
    rowHeight: number;
    airTimeText: string;
    selected: boolean;
    styles;
}> = ({ program, playIcon, playIconHovered, airTimeText, styles, selected }) => {
    const [playIconState, setPlayIconState] = useState(playIcon);

    const { getReplayWindowTimeFrame, isGuest } = useAuth();
    const { isMini, updateMiniPlayerProps } = usePlayer();
    const { getChannelById, channels } = useChannels();
    const interactionTracking = useElementInteractionTracking();
    const dispatch = useDispatch();

    const history = useHistory();
    const { handleEpgPlayOptions } = usePlayOptions();
    const favoriteNotification = useAriaOnlyNotification();

    const channel = getChannelById(program.channelId);

    const replayCapable = (): boolean => {
        return (
            !isFuture(program.startTime) &&
            !program.isBreak &&
            isCatchUpCapable(program.startTime, channel?.replay ?? false, getReplayWindowTimeFrame())
        );
    };

    const onPlayClicked = event => {
        event?.preventDefault();
        event?.stopPropagation();

        interactionTracking({
            'data-track-id': 'epg_program_play_icon',
            'data-asset-id': program.id,
            'data-asset-name': program.title,
        });

        if (isNow(program.startTime, program.endTime)) {
            if (isMini) {
                updateMiniPlayerProps(getMiniPlayerProps(program, PlayingAssetType.LIVE));
            } else {
                history.push(getLivePlayerURL(program.channelId));
            }
        }
        if (isPast(program.endTime) && replayCapable()) {
            handleEpgPlayOptions(program, getCatchupPlayerURL(program.id));
        }
    };

    const onFavPressed = async () => {
        const action = channel.favorite ? 'REMOVE' : 'ADD';
        const response = await Api.channelFavorite(action, channel.id);

        if (response.response) {
            const updatedChannels = channels as Channel[];
            const index = updatedChannels.findIndex(o => o.productCode === channel.productCode);
            updatedChannels[index].favorite = !updatedChannels[index].favorite;
            dispatch(channelsAction('STORE_CHANNELS', updatedChannels));

            const toastString =
                action === 'ADD'
                    ? translate('ARIA_TOAST_FAV_CHANNEL_ADD', { channelName: channel.name })
                    : translate('ARIA_TOAST_FAV_CHANNEL_REMOVE', { channelName: channel.name });
            favoriteNotification(toastString);
        }
    };

    useKeyboardShortcuts({
        p: onPlayClicked as any,
        f: onFavPressed,
    });

    return (
        <HoverStateContainer>
            <HoverStateInnerWrapper style={styles} hovered={true} selected={selected}>
                <HoveredTitleContainer>
                    <TitleText>{program.title}</TitleText>
                    <AirTimeText>{airTimeText}</AirTimeText>
                </HoveredTitleContainer>
                <HoverActionIconsContainer>
                    {(isNow(program.startTime, program.endTime) || replayCapable()) && !isGuest && channel?.subscription && (
                        <PlayIcon
                            onClick={onPlayClicked}
                            onMouseOver={() => {
                                setPlayIconState(playIconHovered);
                            }}
                            onMouseOut={() => {
                                setPlayIconState(playIcon);
                            }}
                        >
                            {playIconState}
                        </PlayIcon>
                    )}
                </HoverActionIconsContainer>
            </HoverStateInnerWrapper>
        </HoverStateContainer>
    );
};

export const EpgProgramItem: FC<{
    program: EpgProgram;
    isMobile: boolean;
    onPlayClicked?: () => void;
    rowHeight: number;
    playIcon: any;
    playIconHovered: any;
    pixelsPerMinute: number;
    grid: HTMLDivElement;
}> = ({ program, isMobile, playIcon, playIconHovered, rowHeight, grid, pixelsPerMinute }) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const [hoveredStyle, setHoveredStyle] = useState<CSSProperties | null>(null);
    const [past, setPast] = useState<boolean>(isPast(program.endTime));
    const { config } = useConfig();
    const { getChannelById } = useChannels();
    const timeCluster = useBroadcastTimeCluster(program);

    const handleMouseEnterTimeout = 250;
    const applySecondaryStateTimeout = 200;
    const removeHoveredStateTimeout = 200;

    const timeoutBeforeHandleMouseEnter = useRef(null);
    const timeoutBeforeApplyHoveredStyles = useRef(null);
    const timeoutBeforeHandleRemoveHoveredState = useRef(null);

    const { pathname } = useLocation();
    const { setSelectedProgram } = useEpgStore();
    const { appLanguage } = useApp();
    const { asset } = usePlayer();
    const channel = getChannelById(program.channelId);

    const duration = program.endTime - program.startTime;
    const cellWidth = pixelsPerMinute * (MILLISECONDS.toSeconds(duration) / 60);

    const startDate = new Date(program?.realStartTime ?? program.startTime);
    const endDate = new Date(program?.realEndTime ?? program.endTime);
    const isLive = isNow(program.startTime, program.endTime);

    let airTimeText = `${getHourMinute(startDate.getHours())}:${getHourMinute(startDate.getMinutes())}`;
    airTimeText += ` - ${getHourMinute(endDate.getHours())}:${getHourMinute(endDate.getMinutes())}`;

    DEFAULT_HOVER_STYLE_PROPS.height = `${config.app_config.epg_settings.row_height + HOVER_WIDTH_EXTRA_OFFSET}px`;

    const findContainer = element => {
        if (element === null) {
            return element;
        }
        if (element.classList.contains('program-item')) {
            return element;
        }

        let currentElement = element;

        while (!currentElement.classList.contains('program-item')) {
            currentElement = currentElement.parentNode;
        }

        return currentElement;
    };

    const calculateHoveredContainerWidth = () => {
        const buttonsContainerWidth =
            program.isBreak || isFuture(program.startTime) ? HOVER_EXTRA_RIGHT_OFFSET : HOVER_BUTTON_CONTAINER_WIDTH;

        // calculate width of the title and air time
        const titleFullWidth = getTextWidth(program.title, HOVER_FONT_FAMILY, HOVER_FONT_SIZE, 0, HOVER_FONT_WEIGHT_AIRING);
        const airingTimeFullWidth = getTextWidth(airTimeText, HOVER_FONT_FAMILY, HOVER_FONT_SIZE_AIRING, 0, HOVER_FONT_WEIGHT);

        return Math.max(titleFullWidth, airingTimeFullWidth) + buttonsContainerWidth;
    };

    const calculateHoveredContainerProps = e => {
        // set base returnable elements
        const hoveredContainerWidth = calculateHoveredContainerWidth();
        const returnObj = {
            left: `${HOVER_DEFAULT_LEFT}px`,
            width: `${hoveredContainerWidth}px`,
        };

        // return base, if target is undefined, calculations below need target
        if (e === undefined || e === null || e.target === undefined || e.target === null) {
            return returnObj;
        }

        const element = findContainer(e.target);
        const programStart = element.offsetLeft;
        const programHoveredEnd = element.offsetLeft + Math.max(cellWidth, hoveredContainerWidth);
        const programEnd = programStart + cellWidth;
        const leftInvisibleArea = grid.scrollLeft - programStart;
        const leftVisibleArea = programEnd - grid.scrollLeft;
        const rightVisibleArea = grid.scrollLeft + grid.clientWidth - programStart;
        const rightInvisibleArea = programHoveredEnd - (grid.scrollLeft + grid.clientWidth);

        // handle right side so when the element would be out to the right
        if (rightInvisibleArea > 0) {
            if (rightVisibleArea >= hoveredContainerWidth) {
                returnObj.width = `${rightVisibleArea}px`;
            } else {
                returnObj.width = `${hoveredContainerWidth}px`;
                returnObj.left = `${-(hoveredContainerWidth - rightVisibleArea)}px`;
            }
        } else if (
            // handle left side so when part of the element is invisible
            leftInvisibleArea > 0
        ) {
            returnObj.left = `${leftInvisibleArea + HOVER_LEFT_FROM_RIGHT_PARTIAL}px`;

            if (leftVisibleArea >= hoveredContainerWidth) {
                if (programStart < grid.scrollLeft && programEnd > grid.scrollLeft + grid.clientWidth) {
                    returnObj.width = `${cellWidth - leftInvisibleArea}px`;
                } else {
                    returnObj.width = `${leftVisibleArea - HOVER_LEFT_FROM_RIGHT_PARTIAL}px`;
                }
            }
        } else if (cellWidth > hoveredContainerWidth) {
            returnObj.width = `${cellWidth + HOVER_WIDTH_EXTRA_OFFSET}px`;
        }

        return returnObj;
    };

    const mouseEnter = (e: MouseEvent<HTMLDivElement>) => {
        e.persist();

        if (program.isBreak || isMobile) {
            return;
        }

        if (timeoutBeforeHandleRemoveHoveredState.current != null) {
            clearTimeout(timeoutBeforeHandleRemoveHoveredState.current);
        }

        const scrollLeftBefore = grid.scrollLeft;
        const hoveredProps = calculateHoveredContainerProps(e);

        setHovered(true);

        const styles: CSSProperties = {
            ...DEFAULT_HOVER_STYLE_PROPS,
            ...hoveredProps,
        };

        timeoutBeforeHandleMouseEnter.current = setTimeout(() => {
            timeoutBeforeApplyHoveredStyles.current = setTimeout(() => {
                const scrollLeftAfter = grid.scrollLeft;
                if (scrollLeftAfter !== scrollLeftBefore) {
                    mouseEnter(e);
                } else {
                    setHoveredStyle(styles);
                }
            }, applySecondaryStateTimeout);
        }, handleMouseEnterTimeout);

        e.preventDefault();
        e.stopPropagation();
    };

    const focusEnter = (e: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
        const hoveredProps = calculateHoveredContainerProps(e);
        const styles: CSSProperties = {
            ...DEFAULT_HOVER_STYLE_PROPS,
            ...hoveredProps,
        };

        setHovered(true);
        setHoveredStyle(styles);
    };

    const mouseLeave = (e: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
        if (!hovered || isMobile) {
            return false;
        }

        e.preventDefault();
        e.stopPropagation();

        if (timeoutBeforeHandleMouseEnter.current != null) {
            clearTimeout(timeoutBeforeHandleMouseEnter.current);
        }

        if (timeoutBeforeApplyHoveredStyles.current != null) {
            clearTimeout(timeoutBeforeApplyHoveredStyles.current);
        }

        setHoveredStyle(null);
        timeoutBeforeHandleRemoveHoveredState.current = setTimeout(() => setHovered(false), removeHoveredStateTimeout);

        return true;
    };

    const renderHoveredState = () => {
        if (!hovered) {
            return null;
        }

        let hoveredContainerCSS: CSSProperties = { width: '100%' };

        if (hovered) {
            hoveredContainerCSS.width = `${cellWidth + HOVER_LEFT_FROM_RIGHT_PARTIAL}px`;
        }

        // looking for hovered state styles
        if (hoveredStyle) {
            hoveredContainerCSS = {
                ...hoveredContainerCSS,
                ...hoveredStyle,
            };
        }

        return (
            <HoveredProgramItem
                program={program}
                playIcon={playIcon}
                playIconHovered={playIconHovered}
                rowHeight={rowHeight}
                airTimeText={airTimeText}
                styles={hoveredContainerCSS}
                selected={program?.id === asset?.id}
            />
        );
    };

    useEffect(() => {
        if (timeCluster) {
            setPast(timeCluster === 'PAST');
        }
    }, [timeCluster]);

    useEffect(() => {
        return () => {
            if (timeoutBeforeHandleMouseEnter.current) {
                clearTimeout(timeoutBeforeHandleMouseEnter.current);
            }

            if (timeoutBeforeApplyHoveredStyles.current) {
                clearTimeout(timeoutBeforeApplyHoveredStyles.current);
            }

            if (timeoutBeforeHandleRemoveHoveredState.current) {
                clearTimeout(timeoutBeforeHandleRemoveHoveredState.current);
            }
        };
    }, []);

    const programItem = (
        <ProgramItem
            data-test-id={'tvguide-program-item'}
            width={cellWidth}
            height={rowHeight}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            isNow={isLive}
            isSelected={program?.id === asset?.id}
            className={'program-item'}
            onFocus={focusEnter}
            onBlur={mouseLeave}
            aria-label={isLive ? `${channel?.name}: ${program?.title} (${translate('ARIA_HINT_EPG_PLAY')})` : ''}
            tabIndex={isLive ? 0 : -1}
        >
            <ProgramTitle rowHeight={rowHeight} past={past}>
                {duration > MINUTES.toMillis(6) && program.title}
            </ProgramTitle>
            {renderHoveredState()}
        </ProgramItem>
    );

    if (program.isBreak) {
        return programItem;
    }

    return (
        <LinkTrackable
            to={getBroadcastDetailsURL(
                program,
                {
                    pathname,
                    isDetailPage: true,
                },
                appLanguage
            )}
            data-event-name={'click'}
            data-track-id={'epg_program_item'}
            data-asset-id={program.id}
            data-asset-name={program.title}
            ignoreFocus
            onClick={() => {
                setSelectedProgram(program);
            }}
        >
            {programItem}
        </LinkTrackable>
    );
};

import React, { FC, useCallback } from 'react';
import { BroadcastPlayerAsset, PlayerControl, RecordingPlayerAssetInfo } from '../../types/Player';
import {
    CloseButton,
    RecordingActionWindowMobileWrapper,
    RecordingPopupContentWrapper,
    StyledSVGInline,
} from './PlayerRecordingActionWindow.css';
import { PlayerButton } from '../PlayerUI/PlayerUI.css';
import { useBroadcastTimeCluster } from '../../hooks/useBroadcastTimeCluster/useBroadcastTimeCluster';
import { useRecording } from '../../providers/useRecording/RecordingContext';
import { ControlGroup } from '../Player/PlayerWrapper';
import { usePlayer } from '../../providers/player/PlayerContext';
import { DropdownItemSimple } from '../DropdownMenu/DropdownMenu';
import translate from '../../utils/fnTranslate';
import icons from '../../style';
import { isRecordingPlayerAsset } from '../../utils/fnTypeGuards';

export const useRecordingAction = (asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo | any) => {
    const timeCluster = useBroadcastTimeCluster(asset);
    const { deleteRecordingByEventId, deleteRecording, createRecording, subscribe } = useRecording();

    const onRecordingOptionClicked = useCallback(() => {
        if (!asset) return;

        if (isRecordingPlayerAsset(asset)) {
            deleteRecording(asset.id, 'PAST');
        } else if (asset.isRecordingSubscribed) {
            if (asset.isRecorded) {
                deleteRecordingByEventId(asset.id, timeCluster);
            } else {
                createRecording(asset.id, timeCluster);
            }
        } else {
            subscribe(asset.recordingProductCode);
        }
    }, [asset, timeCluster]);

    const getOptionText = useCallback(() => {
        switch (timeCluster) {
            case 'PAST':
                return translate(asset.isRecorded ? 'DELETE_RECORDING_BUTTON' : null);
            case 'LIVE':
                return translate(asset.isRecorded ? 'STOP_RECORDING_BUTTON' : 'RECORD_BUTTON');
            default:
                return null;
        }
    }, [asset, timeCluster]);

    return {
        onRecordingOptionClicked,
        getOptionText,
    };
};

export const PlayerRecordingAction: FC<{ asset: any }> = ({ asset }) => {
    const { onRecordingOptionClicked, getOptionText } = useRecordingAction(asset);
    const timeCluster = useBroadcastTimeCluster(asset);
    const getIcon = () => {
        if (timeCluster === 'PAST') {
            return icons.icoDeleteRecording;
        }
        return asset?.isRecorded ? icons.icoEndRecording : icons.icoRecord;
    };

    return (
        <PlayerButton data-test-id={'player-button-record'} onClick={onRecordingOptionClicked} aria-label={getOptionText()}>
            <StyledSVGInline svg={getIcon()} />
        </PlayerButton>
    );
};

export const PlayerRecordingActionWindowMobile: FC<{ asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo }> = ({ asset }) => {
    const { setActiveControlGroup, activeControlGroup } = usePlayer();
    const { getOptionText, onRecordingOptionClicked } = useRecordingAction(asset);

    if (activeControlGroup !== ControlGroup.RECORDING) {
        return null;
    }

    const closeOverlay = () => {
        setActiveControlGroup(ControlGroup.BASE);
    };

    const onButtonClick = () => {
        closeOverlay();
        onRecordingOptionClicked();
    };

    return (
        <RecordingActionWindowMobileWrapper>
            <CloseButton key={PlayerControl.CLOSE}>
                <StyledSVGInline svg={icons.closeIcon} onClick={closeOverlay} />
            </CloseButton>
            <RecordingPopupContentWrapper>
                <DropdownItemSimple key={'recording'} selected={false} onClick={onButtonClick}>
                    {getOptionText()}
                </DropdownItemSimple>
            </RecordingPopupContentWrapper>
        </RecordingActionWindowMobileWrapper>
    );
};

import React, { FC, useEffect } from 'react';
import { redirectTo } from 'utils/fnSettings';
import { PageWrapper } from '../PageSettingsCommon.css';
import translate from '../../../../utils/fnTranslate';
import { useTrackingScreen } from '../../../../providers/useTracking/TrackingContext';
import { useApp } from '../../../../providers/useApp/AppContext';
import { HelmetWrapper } from '../../../HelmetWrapper/HelmetWrapper';
import { getHelmetData } from '../../../../utils/fnHelmet';
import { PinFlowType, usePin } from '../../../../providers/usePin/PinContext';
import { useConfig } from '../../../../providers/useConfig/ConfigContext';
import OptionFocusable from '../OptionFocusable';

const PagePinProtection: FC = () => {
    const { setPageTitle } = useApp();
    const trackScreen = useTrackingScreen(() => true);
    const { openPinDialog } = usePin();
    const { config } = useConfig();
    const { reset } = config?.api_config?.routes?.Pin ?? {};

    useEffect(() => {
        const title = translate('SETTINGS_PIN');

        setPageTitle(title);
        trackScreen(title);
    }, []);

    return (
        <HelmetWrapper {...getHelmetData(translate('SETTINGS_PIN'))}>
            <PageWrapper>
                <OptionFocusable title={translate('SETTINGS_CHANGE_PURCHASE_PIN')} onClick={() => openPinDialog(PinFlowType.CHANGE)} />
                <OptionFocusable title={translate('SETTINGS_FORGOT_YOUR_PIN')} onClick={() => redirectTo(reset)} isExternalLink />
            </PageWrapper>
        </HelmetWrapper>
    );
};

export default PagePinProtection;

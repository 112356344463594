import { Breakpoints, MQ } from 'style/styled-components/cssMediaQueries';
import React, { useEffect, useRef, useState } from 'react';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import { css } from 'styled-components';
import { isIOS } from 'react-device-detect';
import { useAuth } from 'providers/useAuth/AuthContext';
import icons from '../../style';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import {
    GlobalBackNavigation,
    GlobalNavPageTitle,
    HeaderButtonsWrapper,
    HeaderCountWrapper,
    HeaderWrapper,
    Logo,
    StyledSVGInline,
} from './Header.css';
import mainImages from '../../assets/images/main';
import { MainMenu } from './MainMenu';
import {
    body1FontSize,
    body1LineHeight,
    body2FontSize,
    body2LineHeight,
    body3FontSize,
    body3LineHeight,
    fontBold,
} from '../../style/styled-components/cssGlobalVariables';
import { useApp, usePageUtils } from '../../providers/useApp/AppContext';
import translate from '../../utils/fnTranslate';
import { useNavigation } from '../../hooks/useNavigation';
import { useDetailLocationParams } from '../../hooks/useDetailLocationParams';
import { DropdownMenuSettings } from './DropdownMenuSettings';
import { usePageScroller } from '../PageScroller/usePageScroller';
import { HeaderSearch } from './HeaderSearch/HeaderSearch';
import { useSearch } from '../../providers/useSearch/SearchContext';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { LinkTrackable } from '../Trackable/LinkTrackable';
import { useElementInteractionTracking } from '../../providers/useTracking/TrackingContext';
import { getLocalOrRemoteImagePath } from '../../utils/fnData';
import { CastButton } from '../CastButton/CastButton';
import { LanguageSettingsDropdown } from './LanguageSettingsDropdown';

const pageTitleParser = (title: string) => {
    // separates content between ()
    const regExp = /\(([^)]+)\)/;

    const newTitle = title?.replace(regExp, '');
    const count = regExp?.exec(title)?.[1];

    return { newTitle, count };
};

export const Header: React.FC = ({ ...rest }) => {
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const { pageTitle, isDesktop } = useApp();
    const { onBackPressed, history } = useNavigation();
    const { isDetailPage } = useDetailLocationParams();
    const { scrollTop } = usePageScroller();
    const { pageBelongsToMainSections, isCollectionPage, isSettings, isSearch, pageConfig } = usePageUtils();
    const { searchTerm, doSearch, enterSearch, exitSearch } = useSearch();
    const { config } = useConfig();
    const { isLoggedIn, isGuest } = useAuth();
    const interactionTracking = useElementInteractionTracking();
    const { newTitle: title, count } = pageTitleParser(pageTitle);

    const headerRef = useRef(null);

    const showBackNavigation = (): boolean => {
        return (!pageBelongsToMainSections || isCollectionPage || isSettings) && !isSearch;
    };

    const handleLogoClick = () => {
        history.push('/');
        exitSearch(false);

        interactionTracking({
            'data-track-id': 'header_logo',
        });
    };

    const onSearch = (value: string) => {
        if (value?.length === 0 || value?.length >= config.app_config.search_settings.key_threshold) {
            doSearch(value);
        }
    };

    const closeSearch = () => {
        if (isSearch) {
            exitSearch(true);
        }
    };

    const submitSearch = (value: string) => {
        doSearch(value);
    };

    const openSearchScreen = () => {
        if (!isSearch) {
            enterSearch();
        }
    };

    const searchInput = () => {
        return (
            config?.isFeatureActive('search') && (
                <HeaderSearch
                    value={searchTerm}
                    placeholder={translate('SCREEN_SEARCH')}
                    isOpen={isSearch}
                    onSearch={onSearch}
                    onSubmit={submitSearch}
                    onClose={closeSearch}
                    onOpen={openSearchScreen}
                />
            )
        );
    };

    useEffect(() => {
        setIsSticky(scrollTop > 0);
    }, [scrollTop]);

    useKeyboardShortcuts({
        backspace: showBackNavigation() ? onBackPressed : undefined,
    });

    return (
        <HeaderWrapper
            ref={headerRef}
            isSticky={isSticky || isCollectionPage || !pageBelongsToMainSections}
            hideOnMobile={isDetailPage}
            className={showBackNavigation() ? 'with-back-navigation' : ''}
            {...rest}
        >
            <Logo
                onClick={handleLogoClick}
                imageSrc={getLocalOrRemoteImagePath(pageConfig?.layout_config?.logo ?? config?.layout_config?.logo, mainImages)}
                data-test-id={'header-logo'}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <MainMenu pageId={pageConfig?.id ?? ''} id={'MainMenu'} isIOS={isIOS} />
                <HeaderButtonsWrapper hideOnMobile={isDetailPage}>
                    {isSettings ? (isDesktop() ? searchInput() : null) : searchInput()}
                    {!isSettings && (!isLoggedIn || isGuest ? null : <CastButton />)}
                    {!isSettings && isDesktop() ? <LanguageSettingsDropdown /> : null}
                    {isSettings ? isDesktop() ? <DropdownMenuSettings /> : null : <DropdownMenuSettings />}
                </HeaderButtonsWrapper>
            </div>
            {showBackNavigation() && (
                <GlobalBackNavigation onClick={() => onBackPressed()}>
                    <StyledSVGInline data-test-id={'header-account-backbutton'} svg={icons.leftArrowIcon} />
                    <GlobalNavPageTitle data-test-id={'header-account-sectionbutton'}>{translate(title)}</GlobalNavPageTitle>
                    <HeaderCountWrapper>{count}</HeaderCountWrapper>
                </GlobalBackNavigation>
            )}
        </HeaderWrapper>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MenuLink = styled(({ active, ...rest }) => <LinkTrackable {...rest} />)<{ active: boolean }>`
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: inline-block;
    font-size: ${body3FontSize};
    line-height: ${body3LineHeight};
    opacity: 0.66;
    font-family: ${fontBold};

    ${MQ(Breakpoints.s)} {
        font-size: ${body2FontSize};
        line-height: ${body2LineHeight};
    }

    ${MQ(Breakpoints.m)} {
        font-size: ${body1FontSize};
        line-height: ${body1LineHeight};
    }

    &:hover {
        opacity: 1 !important;
    }

    &:focus-visible {
        border-bottom: 2px solid #eee;
        transition: border 0s;
    }

    ${props => {
        return props.active
            ? css`
                  opacity: 1;
              `
            : ``;
    }}
`;

import React, { FC, useEffect, useRef, useState } from 'react';
import SVGInline from 'react-svg-inline';
import { getLanguage } from 'utils/fnData';
import { Person } from 'types/Asset';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import {
    DetailMoreAudio,
    DetailMoreAudioAndSubtitle,
    DetailMoreCast,
    DetailMoreSubtitle,
    GradientBottom,
    MetadataWrapper,
    ModalContent,
    ModalContentWrapper,
    ModalDetails,
    ModalSubtitle,
    ModalTitle,
    ModalTitleWrapper,
} from './DetailMoreModal.css';
import icons from '../../style';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import translate from '../../utils/fnTranslate';
import { ModalCloseButton, ModalNavigation, ModalWrapper } from '../../style/styled-components/reusable.css';

type PropTypes = {
    modalTitle?: string;
    modalSubTitle?: string;
    metadata?: string;
    showMoreMetadataTitle?: any[];
    showMoreMetadataContent?: any[];
    cast?: Person[];
    directors?: Person[];
    modalText?: string;
    closeOverlay: () => void;
    caption?: string[] | null;
    original?: string[] | null;
};

const DetailMoreModalComponent: FC<PropTypes> = ({
    modalTitle,
    modalSubTitle,
    metadata,
    showMoreMetadataTitle,
    showMoreMetadataContent,
    cast = [],
    directors = [],
    modalText,
    closeOverlay,
    caption,
    original,
}) => {
    const scrollbarRef = useRef<HTMLDivElement>(null);
    const contentScrollbarRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState<boolean>(true);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useKeyboardShortcuts({
        escape: closeOverlay,
        backspace: closeOverlay,
    });

    const hasAdditionalContentDetails = () => {
        return (
            cast?.length > 0 ||
            directors?.length > 0 ||
            (original !== null && original?.length > 0) ||
            (caption !== null && caption?.length > 0)
        );
    };

    useEffect(() => {
        if (scrollbarRef?.current && contentScrollbarRef?.current) {
            setIsVisible(true);
            setIsScrollable(contentScrollbarRef?.current?.clientHeight > scrollbarRef?.current?.clientHeight);
        } else {
            setIsVisible(false);
            setIsScrollable(!isScrollable);
        }
    }, [scrollbarRef]);

    return (
        <ModalWrapper ref={scrollbarRef} opacity={0.9} aria-modal>
            <ModalNavigation>
                <ModalCloseButton
                    data-test-id={'tv-detailscreen-closeoverlaybutton'}
                    aria-label={translate('CLOSE_BUTTON')}
                    onClick={closeOverlay}
                    tabIndex={0}
                >
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>
            <ModalDetails isVisible={isVisible} isScrollable={isScrollable} ref={contentScrollbarRef}>
                <ModalTitleWrapper>
                    <ModalTitle data-test-id={'tv-detailscreen-modaltitleoverlay'} tabIndex={0}>
                        {modalTitle}
                    </ModalTitle>
                    <ModalSubtitle data-test-id={'tv-detailscreen-modalsubtitleoverlay'}>{modalSubTitle}</ModalSubtitle>
                    {showMoreMetadataTitle && showMoreMetadataContent ? (
                        <MetadataWrapper>
                            <ModalContent>
                                {showMoreMetadataTitle} {showMoreMetadataContent}
                            </ModalContent>
                        </MetadataWrapper>
                    ) : (
                        <MetadataWrapper>
                            <ModalContent tabIndex={0}>{metadata}</ModalContent>
                        </MetadataWrapper>
                    )}
                    <ModalContent data-test-id={'tv-detailscreen-descriptionoverlay'} tabIndex={0}>
                        {modalText}
                    </ModalContent>
                </ModalTitleWrapper>
                {hasAdditionalContentDetails() && (
                    <ModalContentWrapper>
                        <ModalContent>
                            {cast?.length > 0 && (
                                <DetailMoreCast tabIndex={0}>
                                    <strong>{translate('SCREEN_MORE_INFO_ACTORS')}</strong> {cast.map(person => person.title).join(', ')}
                                </DetailMoreCast>
                            )}
                            {directors?.length > 0 && (
                                <DetailMoreCast tabIndex={0}>
                                    <strong>{translate('SCREEN_MORE_INFO_DIRECTORS')}</strong>{' '}
                                    {directors.map(person => person.title).join(', ')}
                                </DetailMoreCast>
                            )}
                        </ModalContent>
                        <DetailMoreAudioAndSubtitle isScrollable={isScrollable}>
                            {original !== null && original?.length > 0 && (
                                <DetailMoreAudio tabIndex={0}>
                                    <strong>{translate('DETAIL_AUDIO_LANGUAGE')} </strong>
                                    {original?.map(audio => getLanguage(audio, translate(`LANGUAGE_${audio.toUpperCase()}`))).join(', ')}
                                </DetailMoreAudio>
                            )}
                            {caption !== null && (
                                <DetailMoreSubtitle tabIndex={0}>
                                    <strong>{translate('DETAIL_SUBTITLES')} </strong>
                                    {caption?.length > 0
                                        ? caption
                                              .map(subtitle => getLanguage(subtitle, translate(`LANGUAGE_${subtitle.toUpperCase()}`)))
                                              .join(', ')
                                        : translate('SUBTITLES_NONE')}
                                </DetailMoreSubtitle>
                            )}
                        </DetailMoreAudioAndSubtitle>
                    </ModalContentWrapper>
                )}
            </ModalDetails>
            <GradientBottom />
        </ModalWrapper>
    );
};

export const DetailMoreModal = withNoScroll(DetailMoreModalComponent);

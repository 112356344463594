import muteIcon from './mute-icon.svg';
import pauseIcon from './pause-icon.svg';
import playIcon from './play-icon.svg';
import unmuteIcon from './unmute-icon.svg';
import volumeDownIcon from './volume-down-icon.svg';
import volumeUpIcon from './volume-up-icon.svg';
import jumpFwIcon from './jump-fw-icon.svg';
import jumpBwIcon from './jump-bw-icon.svg';

export default {
    muteIcon,
    pauseIcon,
    playIcon,
    unmuteIcon,
    volumeDownIcon,
    volumeUpIcon,
    jumpFwIcon,
    jumpBwIcon,
};

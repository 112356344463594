import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { PlayerControl, PlayerIcon, PlayingAsset, PropTypesAdditional, PropTypesPlayerUICommon } from '../../types/Player';
import { getRecordingIconName } from '../../utils/fnData';
import { TimeClusterRecordingTypeMap } from '../../types/RecordingTypes';
import {
    castEnabled,
    getBroadcastPlayerActionComponents,
    getBroadcastPlayerMetaData,
    getBroadcastPlayerMoreOverlayMetaData,
} from '../../utils/fnPlayerUI';
import { ChanelLogo, ChannelLogoFallback } from '../../components/PlayerUI/PlayerUI.css';
import { Picture } from '../../components/Picture/Picture';
import { isRecordingPlayerAsset } from '../../utils/fnTypeGuards';

export const useRecordingPlayerUI = (common: PropTypesPlayerUICommon, asset: PlayingAsset): PropTypesAdditional => {
    if (!isRecordingPlayerAsset(asset)) {
        return null;
    }

    const { channelLogo, channelName, title, subtitle, description, cast, originalLanguages } = asset;

    const getIcons = (): PlayerIcon[] => {
        const metaIcons: PlayerIcon[] = [];

        metaIcons.push(PlayerIcon.CATCHUP);
        metaIcons.push(getRecordingIconName(TimeClusterRecordingTypeMap.PAST) as PlayerIcon);

        return metaIcons;
    };

    const getControls = (): PlayerControl[] => {
        const controls: PlayerControl[] = [PlayerControl.FULL_SCREEN, PlayerControl.VOLUME];

        if (castEnabled()) {
            controls.push(PlayerControl.CAST);
        }

        controls.push(PlayerControl.INFO);

        controls.push(PlayerControl.SUBTITLE);

        controls.push(PlayerControl.START_OVER);

        if (!isMobile && !isTablet) {
            controls.push(PlayerControl.JUMP_BACK);
            controls.push(PlayerControl.JUMP_FORWARD);
        }
        controls.push(PlayerControl.PLAY_PAUSE);

        if (!common.casting) {
            controls.push(PlayerControl.RECORDING);
        }

        return controls;
    };

    const controls = getControls();
    const { actionPopup, mobileOverlayActionHandlers, actionLabels } = getBroadcastPlayerActionComponents(controls, asset);

    return {
        logo: (channelLogo || channelName) && (
            <ChanelLogo>
                <Picture src={channelLogo} hPosition={'left'} vPosition={'center'} fit={'scale-down'}>
                    {channelName && <ChannelLogoFallback>{channelName}</ChannelLogoFallback>}
                </Picture>
            </ChanelLogo>
        ),
        metaData: getBroadcastPlayerMetaData(asset),
        embeddedPlayerMetadata: null,
        contentMarker: null,
        metaIcons: getIcons(),
        disabledControls: [],
        controls,
        seekEnabled: true,
        title,
        subtitle,
        actionPopup,
        actionLabels,
        mobileOverlayActionHandlers,
        moreOverlayMetaData: getBroadcastPlayerMoreOverlayMetaData(asset),
        description,
        cast,
        originalLanguages,
    };
};

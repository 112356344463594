import { css } from 'styled-components';
import { ButtonInner } from 'components/Buttons/Button/Button.css';
import { hexToRgba } from '../../utils/fnColors';
import { fontBold, fontNormal, maxZIndex } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import Button from '../Buttons/Button/Button';

export const AlertDialogWrapper = styled.div<{ transparent: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => hexToRgba(props.theme.brandedConfig.background, props.transparent ? 0.75 : 0.95)};
    backdrop-filter: ${props => (props.transparent ? 'blur(16px)' : 'none')};
    overflow: auto;
    z-index: ${maxZIndex + 5};
`;

export const HintText = styled.div`
    opacity: 0.7;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    position: absolute;
    left: 40px;
    bottom: 40px;
`;

export const DialogButtonContainer = styled.div`
    margin-top: 40px;
    position: absolute;
    bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    ${MQ(Breakpoints.xs)} {
        position: static;
        bottom: unset;
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }
`;

export const AlertDialogInnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    ${HintText} {
        display: none;
    }

    ${DialogButtonContainer} {
        display: block;
    }

    ${MQ(Breakpoints.xs)} {
        ${HintText} {
            display: block;
        }

        ${DialogButtonContainer} {
            display: none;
        }
    }
`;

export const ErrorMessage = styled.div`
    font-family: ${fontNormal};
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    position: absolute;
    left: 40px;
    bottom: 40px;
    display: none;

    ${MQ(Breakpoints.xs)} {
        display: block;
    }
`;

export const DialogTitle = styled.p`
    font-family: ${fontBold};
    font-size: 32px;
    line-height: 40px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
`;

export const SubtitleText = styled.p`
    font-size: 16px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-family: ${fontBold};
    line-height: 24px;
    margin-top: 8px;
`;

export const DialogBodyText = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    margin-top: 16px;
    opacity: 0.7;

    .bold {
        display: inline;
        font-family: ${fontBold};
    }
`;

export const DialogButton = styled(Button)`
    margin-bottom: 12px;
    font-family: ${fontBold};
    width: 100%;

    ${MQ(Breakpoints.xs)} {
        display: inline-block;
        margin-right: 12px;
        width: auto;
    }

    ${props =>
        props.progress &&
        css`
            ${ButtonInner} {
                background: linear-gradient(to right, rgba(235, 235, 241, 0.15) ${props.progress}%, transparent ${props.progress}%);
                border: 2px solid rgba(238, 238, 238, 0.3);
                padding: 12px 16px;
            }
        `}
`;

export const AlertContent = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 16px;
    text-align: center;

    ${HintText} {
        position: static;
        margin-top: 8px;
        display: block;
    }

    ${DialogButtonContainer} {
        display: none;
    }

    ${MQ(Breakpoints.xs)} {
        width: 480px;

        ${HintText} {
            display: none;
        }

        ${DialogButtonContainer} {
            display: block;
        }
    }
`;

import React, { ReactNode } from 'react';
import { CardContext, StripeTypes } from '../../types/Common';
import {
    isBroadcast,
    isChannel,
    isMovie,
    isNowAndNext,
    isPlaceholder,
    isRecording,
    isSeries,
    isTvSeriesEpisode,
    isVODSeriesEpisode,
} from '../../utils/fnTypeGuards';
import { BroadcastCardPresenter } from '../Presenters/StripeCollection/BroadcastCardPresenter';
import { cardTrackingData } from '../../utils/fnTracking';
import { RecordingCardPresenter } from '../Presenters/StripeCollection/RecordingCardPresenter';
import { NowAndNextCardPresenter } from '../Presenters/StripeCollection/NowAndNextCardPresenter';
import { MovieCardPresenter } from '../Presenters/StripeCollection/MovieCardPresenter';
import { SeriesCardPresenter } from '../Presenters/StripeCollection/SeriesCardPresenter';
import { VODEpisodeCardPresenter } from '../Presenters/StripeCollection/VODEpisodeCardPresenter';
import { TVEpisodeCardPresenter } from '../Presenters/StripeCollection/TVEpisodeCardPresenter';
import { ChannelBundlePresenter } from '../Presenters/StripeCollection/ChannelBundlePresenter';
import { BasePresenterSelector } from './BasePresenterSelector';
import { PlaceholderCardPresenter } from '../Presenters/StripeCollection/PlaceholderCardPresenter';

export class StripePresenterSelector extends BasePresenterSelector {
    private stripeType: StripeTypes;

    private title: string;

    private onCardClick: (event: React.MouseEvent<any>, data: any) => void;

    private screenName: string;

    private position: number;

    static create = (): StripePresenterSelector => {
        return new StripePresenterSelector();
    };

    setType = (stripeType: StripeTypes): StripePresenterSelector => {
        this.stripeType = stripeType;
        return this;
    };

    setTitle = (title: string): StripePresenterSelector => {
        this.title = title;
        return this;
    };

    setScreenName = (screenName: string): StripePresenterSelector => {
        this.screenName = screenName;
        return this;
    };

    setPosition = (position: number): StripePresenterSelector => {
        this.position = position;
        return this;
    };

    setCardClickListener = (clickListener: (event: React.MouseEvent<any>, data: any) => void): StripePresenterSelector => {
        this.onCardClick = clickListener;
        return this;
    };

    get = (): ReactNode[] => {
        if (!this.assets) return null;
        const cards = [];

        for (let i = 0; i < this.assets.length; i += 1) {
            const asset = this.assets[i];

            const presenterKey = `${asset.id}-${i}`;

            if (isPlaceholder(asset)) {
                cards.push(<PlaceholderCardPresenter key={presenterKey} asset={asset} cardContext={CardContext.STRIPE} />);
            }

            if (isBroadcast(asset)) {
                cards.push(
                    <BroadcastCardPresenter
                        key={presenterKey}
                        asset={asset}
                        type={this.stripeType}
                        onClick={this.onCardClick}
                        cardContext={CardContext.STRIPE}
                        trackingData={cardTrackingData(
                            'program_card',
                            this.screenName,
                            i,
                            this.title,
                            asset.id,
                            asset.title,
                            this.position
                        )}
                    />
                );
            }

            if (isRecording(asset)) {
                cards.push(
                    <RecordingCardPresenter
                        key={presenterKey}
                        asset={asset}
                        type={this.stripeType}
                        cardContext={CardContext.STRIPE}
                        trackingData={cardTrackingData(
                            'recording_card',
                            this.screenName,
                            i,
                            this.title,
                            asset.id,
                            asset.title,
                            this.position
                        )}
                    />
                );
            }

            if (isNowAndNext(asset)) {
                cards.push(
                    <NowAndNextCardPresenter
                        key={presenterKey}
                        asset={asset}
                        cardContext={CardContext.STRIPE}
                        onClick={this.onCardClick}
                        trackingData={cardTrackingData(
                            'now_next_card',
                            this.screenName,
                            i,
                            this.title,
                            asset.id,
                            asset.title,
                            this.position
                        )}
                    />
                );
            }

            if (isMovie(asset)) {
                cards.push(
                    <MovieCardPresenter
                        key={presenterKey}
                        asset={asset}
                        type={this.stripeType}
                        cardContext={CardContext.STRIPE}
                        trackingData={cardTrackingData('movie_card', this.screenName, i, this.title, asset.id, asset.title, this.position)}
                    />
                );
            }

            if (isSeries(asset)) {
                cards.push(
                    <SeriesCardPresenter
                        key={presenterKey}
                        asset={asset}
                        type={this.stripeType}
                        cardContext={CardContext.STRIPE}
                        trackingData={cardTrackingData('series_card', this.screenName, i, this.title, asset.id, asset.title, this.position)}
                    />
                );
            }

            if (isVODSeriesEpisode(asset)) {
                cards.push(
                    <VODEpisodeCardPresenter
                        key={presenterKey}
                        asset={asset}
                        cardContext={CardContext.STRIPE}
                        onClick={this.onCardClick}
                        trackingData={cardTrackingData(
                            'vod_episode_card',
                            this.screenName,
                            i,
                            this.title,
                            asset.id,
                            asset.title,
                            this.position
                        )}
                    />
                );
            }

            if (isTvSeriesEpisode(asset)) {
                cards.push(
                    <TVEpisodeCardPresenter
                        key={presenterKey}
                        asset={asset}
                        cardContext={CardContext.STRIPE}
                        onClick={this.onCardClick}
                        trackingData={cardTrackingData(
                            'tv_episode_card',
                            this.screenName,
                            i,
                            this.title,
                            asset.id,
                            asset.title,
                            this.position
                        )}
                    />
                );
            }

            /*
            if (isPerson(asset)) {
                cards.push(
                    <PersonCardPresenter
                        key={presenterKey}
                        asset={asset}
                        trackingData={cardTrackingData('person_card', this.screenName, i, this.title, asset.id, asset.title, this.position)}
                    />
                );
            }
            */

            if (isChannel(asset)) {
                cards.push(
                    <ChannelBundlePresenter
                        channelRow1={asset}
                        key={presenterKey}
                        trackingData={cardTrackingData('channel_card', this.screenName, i, this.title, asset.id, asset.name, this.position)}
                    />
                );
            }
        }

        return cards;
    };
}

import { css } from 'styled-components';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';

export const ButtonInner = styled.div<{ isPrimary: boolean; isSelected?: boolean; isDisabled?: boolean; progress?: number }>`
    padding: 12px 16px;
    line-height: 1;
    height: 100%;
    position: relative;
    display: block;
    white-space: nowrap;
    font-size: 16px;
    border-radius: 4px;
    color: ${props =>
        props.isDisabled ? 'var(--color-dark-body-70)' : props.isPrimary ? 'var(--color-light-body)' : 'var(--color-dark-body)'};
    transition: all 0.2s ease-in-out;
    background: ${props =>
        props.isDisabled
            ? 'transparent'
            : props.isPrimary
            ? 'var(--color-light-background)'
            : props.isSelected
            ? 'var(--color-dark-body-20)'
            : 'transparent'};
    border: ${props => (props.isPrimary || props.isSelected ? `2px solid transparent` : '2px solid var(--color-dark-body-70)')};
    opacity: ${props => (props.isDisabled ? 0.5 : 1)};

    &:not(.no-hover):hover {
        color: ${props =>
            props.isDisabled
                ? 'var(--color-dark-body-70)'
                : props.isPrimary
                ? 'var(--color-light-body)'
                : props.isSelected
                ? 'var(--color-dark-body)'
                : 'var(--color-dark-body)'};
        background: ${props =>
            props.isDisabled
                ? 'transparent'
                : props.isPrimary
                ? 'var(--color-light-foreground)'
                : props.isSelected
                ? 'var(--color-dark-body-20)'
                : 'var(--color-dark-body-10)'};
        border: ${props =>
            props.isPrimary || props.isSelected || props.isDisabled ? `2px solid transparent` : `2px solid var(--color-dark-body)`};
    }

    ${props =>
        props.progress &&
        css`
            background: linear-gradient(
                to left,
                var(--color-dark-body-70) ${props.progress}%,
                var(--color-light-background) ${props.progress}%
            );
            transition: all 0s;
            padding: 14px 18px;
            border: 0;

            &:not(.no-hover):hover {
                background: var(--color-light-background);
                border: 0;
            }
        `}
`;

export const ButtonWrapper = styled.div`
    outline: none;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    min-width: 120px;
    z-index: 1;
    padding: 0;
    font-family: ${fontBold};

    &:focus-visible {
        ${ButtonInner} {
            outline: var(--focus-outline);
            transition: all 0.2s ease-in-out, outline 0.01s linear;
        }
    }
`;

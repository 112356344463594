import React, { FC, useEffect } from 'react';
import SVGInline from 'react-svg-inline';
import { createPortal } from 'react-dom';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import {
    ButtonContainer,
    ImageWrapper,
    ImageWrapperInner,
    MetaContainer,
    MetaDescription,
    MetaService,
    MetaTitle,
    ModalNavigation,
    OptionsContent,
    StyledButton,
} from './SubscriptionConfirmation.css';
import icons from '../../style';
import translate from '../../utils/fnTranslate';
import { PictureWithFallback } from '../Picture/PictureWithFallback';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import fallbackImageLandscape from '../../assets/images/landscape_fallback.png';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import { getOfferTypeByTag } from '../../utils/fnEntitlement';
import { ModalCloseButton, ModalWrapper } from '../../style/styled-components/reusable.css';
import { SubscribeOption } from '../../types/Entitlement';
import { getCurrencySymbol } from '../../utils/fnData';

type PropTypes = {
    onClose: () => void;
    onConfirm: () => void;
    selectedOffer: SubscribeOption;
    loading: boolean;
};

const SubscriptionConfirm: FC<PropTypes> = ({ onClose, onConfirm, selectedOffer, loading }) => {
    const buttonText = translate('SUBSCRIBE_FOR_PRICE_BUTTON', { price: `${selectedOffer.price}${getCurrencySymbol(null)}` });

    useKeyboardShortcuts({
        escape: onClose,
        enter: onConfirm,
    });

    useEffect(() => {
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
        const root = document.getElementById('root');
        root.setAttribute('inert', '');

        return () => {
            root.removeAttribute('inert');
        };
    }, []);

    return createPortal(
        <ModalWrapper opacity={1} aria-modal>
            <ModalNavigation>
                <ModalCloseButton onClick={onClose} tabIndex={0}>
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>

            <OptionsContent>
                <ImageWrapper>
                    <ImageWrapperInner>
                        <PictureWithFallback
                            src={selectedOffer.poster}
                            fit={'cover'}
                            vPosition={'top'}
                            fallbackImage={fallbackImageLandscape}
                        />
                    </ImageWrapperInner>
                </ImageWrapper>
                <MetaContainer>
                    <MetaTitle tabIndex={0}>{`${translate('SCREEN_SUBSCRIBE_CONFIRMATION_HEADER', {
                        service_name: selectedOffer.name,
                    })}`}</MetaTitle>
                    <MetaService tabIndex={0}>{getOfferTypeByTag(selectedOffer?.tags?.[0])}</MetaService>
                    <MetaDescription tabIndex={0}>{selectedOffer.description}</MetaDescription>
                    <ButtonContainer>
                        <StyledButton
                            key={''}
                            label={buttonText}
                            onClick={onConfirm}
                            isPrimary={true}
                            data-event-name={'click'}
                            data-track-id={'button_subscribe'}
                            data-button-title={buttonText}
                            data-page-name={'SubscriptionConfirmation'}
                        />
                    </ButtonContainer>
                </MetaContainer>
            </OptionsContent>

            <LoadingIndicator overlay={true} isLoading={loading} />
        </ModalWrapper>,
        document.getElementById('modal-root')
    );
};

export const SubscriptionConfirmation = withNoScroll(SubscriptionConfirm);

import React, { FC, useState } from 'react';
import { hasProgressBar } from 'utils/fnData';
import {
    CardCollectionTileContainer,
    CardCollectionWrapperInner,
    CollectionCardWrapper,
    FallbackTitle,
    ImageWrapper,
    PlayIcon,
    PlayIconSVG,
    PortraitImageContainer,
} from './CardCollectionPortrait.css';
import { CardImage, ContentMarker } from '../../../style/styled-components/reusable.css';
import { LinkTrackable } from '../../Trackable/LinkTrackable';
import fallbackPortrait from '../../../assets/images/portrait_fallback.png';
import icons from '../../../style';
import { StandardCover } from '../../../types/Cover';

type PropTypes = {
    asset: StandardCover;
};

export const CardCollectionPortrait: FC<PropTypes> = ({ asset, ...rest }) => {
    const [showFallback, setShowFallback] = useState(false);
    const { path, image, title, progressBar, renderPlayIcon, onPlayIconClicked, contentMarker, rawData } = asset;

    const altText = `${rawData?.genres?.[0] ? rawData?.genres?.[0] : ''} ${title}`;

    return (
        <CollectionCardWrapper>
            <CardCollectionWrapperInner>
                <CardCollectionTileContainer>
                    <LinkTrackable
                        to={path}
                        onClick={e => {
                            if (asset.onClick) {
                                e.preventDefault();
                                e.stopPropagation();

                                asset.onClick(e, asset.rawData);
                            }
                        }}
                        visibleFocus="outline"
                        {...rest}
                    >
                        <ImageWrapper>
                            {renderPlayIcon && (
                                <PlayIcon onClick={onPlayIconClicked}>
                                    <PlayIconSVG svg={icons.playIcon} />
                                </PlayIcon>
                            )}
                            <PortraitImageContainer>
                                <CardImage
                                    url={image}
                                    fallbackImage={fallbackPortrait}
                                    alt={altText}
                                    {...{
                                        imageError: () => {
                                            setShowFallback(true);
                                        },
                                    }}
                                />
                            </PortraitImageContainer>

                            {showFallback && (
                                <FallbackTitle>
                                    <span>{title}</span>
                                </FallbackTitle>
                            )}

                            {progressBar}
                            {contentMarker != null && (
                                <ContentMarker withProgressBar={hasProgressBar(progressBar)} marker={contentMarker}>
                                    {contentMarker.value}
                                </ContentMarker>
                            )}
                        </ImageWrapper>
                    </LinkTrackable>
                </CardCollectionTileContainer>
            </CardCollectionWrapperInner>
        </CollectionCardWrapper>
    );
};

import React, { useEffect, useRef } from 'react';
import { EpgFullRowCell, RowItemContainer } from './EpgProgramRow.css';
import { EpgChannel, EpgProgram } from '../../../types/EpgTypes';
import { EpgProgramItem } from '../EpgProgramItem/EpgProgramItem';

type PropTypes = {
    rowHeight: number;
    dataIndex: number;
    isMobile: boolean;
    channels: EpgChannel[];
    programs: {};
    selectedDate: Date;
    loadingText: string;
    tvPlaceholderIcon: any;
    playIcon: any;
    playIconHovered: any;
    pixelsPerMinute: number;
    pastLength: number;
    forceEmpty?: boolean;
    grid: HTMLDivElement;
};

export const FullRowItem: React.FC<{ text: string; rowHeight: number; paddingLeft: number; grid: HTMLDivElement }> = ({
    text,
    rowHeight,
    paddingLeft,
    grid,
}) => {
    const paddingExtra = 15;
    const cellRef = useRef<HTMLSpanElement>(null);
    const currentPadding = useRef(0);

    const gridScrollListener = () => {
        const paddingToSet = grid.scrollLeft + paddingExtra;

        if (paddingToSet === currentPadding.current) {
            return;
        }

        currentPadding.current = paddingToSet;
        if (cellRef.current && grid) {
            cellRef.current.style.paddingLeft = `${paddingToSet}px`;
        }
    };

    useEffect(() => {
        if (grid) {
            grid.addEventListener('scroll', gridScrollListener);
        }
        return () => {
            if (grid) {
                grid.removeEventListener('scroll', gridScrollListener);
            }
        };
    }, [grid]);

    useEffect(() => {
        if (cellRef.current && grid) {
            cellRef.current.style.paddingLeft = `${paddingLeft + paddingExtra}px`;
        }
    }, [cellRef.current]);

    return (
        <EpgFullRowCell ref={cellRef} height={rowHeight}>
            {text}
        </EpgFullRowCell>
    );
};

export const MemoizedFullRowItem = React.memo(FullRowItem);

export const EpgProgramRow: React.FC<PropTypes> = ({
    dataIndex,
    channels,
    programs,
    selectedDate,
    loadingText,
    rowHeight,
    pixelsPerMinute,
    pastLength,
    playIcon,
    playIconHovered,
    isMobile,
    forceEmpty = false,
    grid,
}) => {
    const channel = forceEmpty ? null : channels[dataIndex];
    const cells = [];
    const timeKey = selectedDate.getTime();

    const isLoadingRow = forceEmpty || !programs[channel.id] || !programs[channel.id][timeKey];

    if (channel || isLoadingRow) {
        if (isLoadingRow || !programs[channel.id][timeKey].length) {
            cells.push(
                <MemoizedFullRowItem
                    text={loadingText}
                    rowHeight={rowHeight}
                    paddingLeft={grid?.scrollLeft ?? pastLength}
                    grid={grid}
                    key={`full-row-${dataIndex}`}
                />
            );
        } else {
            const rowData = programs[channel.id][timeKey];

            if (rowData.length === 1 && rowData[0].id == null) {
                cells.push(
                    <MemoizedFullRowItem
                        text={rowData[0].title}
                        rowHeight={rowHeight}
                        paddingLeft={grid?.scrollLeft ?? pastLength}
                        grid={grid}
                        key={`full-row-${dataIndex}`}
                    />
                );
            } else {
                for (let i = 0; i < rowData.length; i += 1) {
                    cells.push(
                        <EpgProgramItem
                            key={`program-item-${i}-${(rowData[i] as EpgProgram).id}`}
                            isMobile={isMobile}
                            grid={grid}
                            playIcon={playIcon}
                            playIconHovered={playIconHovered}
                            program={rowData[i]}
                            rowHeight={rowHeight}
                            pixelsPerMinute={pixelsPerMinute}
                        />
                    );
                }
            }
        }
    }

    return (
        <RowItemContainer height={rowHeight} aria-rowindex={dataIndex + 1} role="row">
            {cells}
        </RowItemContainer>
    );
};

export const MemoizedRow = React.memo(EpgProgramRow);

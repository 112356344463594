import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { Scrollbar } from 'react-scrollbars-custom';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import SVGInline from 'react-svg-inline';
import { Swipeable } from 'react-swipeable';
import { IconClear } from 'components/Header/HeaderSearch/HeaderSearch.css';
import {
    DropdownContent,
    DropdownTitle,
    DropdownItemSimpleWrapper,
    DropdownItemWithSvgWrapper,
    DropdownLabel,
    DropdownMenuList,
    DropdownWrapper,
    SVGWrapper,
    MenuContent,
    ExpendableBarWrapper,
    ExpendableBar,
    RightSvgWrapper,
} from './DropdownMenu.css';
import icons from '../../style';
import { useResizeWatcher } from '../../hooks/useResizeWatcher/useResizeWatcher';

export type DropdownMenuProps = {
    keepOpen?: boolean;
    onMouseEnterCb?: () => void;
    onMouseLeaveCb?: () => void;
    showMenuContent?: boolean;
    onClick?: () => void;
    triggerButton: ReactNode;
    verticalPosition?: 'top' | 'bottom';
    horizontalPosition?: 'left' | 'right';
    maxHeight?: number;
    openOnMouseEnter?: boolean;
    selectedIndex?: number;
    stripMobilePortal?: boolean;
    visibilityChangeCallback?: (isVisible: boolean) => void;
    title?: string;
    withSelectedIcon?: boolean;
    ignoreInsideClick?: boolean;
};

const MOUSE_LEAVE_TIMEOUT_AMOUNT = 500;

export const PLAYER_SETTINGS_DROPDOWN_EVENT = 'player-settings-trigger';

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
    keepOpen = false,
    onMouseEnterCb = () => {},
    onMouseLeaveCb = () => {},
    showMenuContent = false,
    openOnMouseEnter = false,
    selectedIndex = null,
    onClick = () => {},
    children,
    triggerButton,
    verticalPosition,
    horizontalPosition,
    maxHeight,
    stripMobilePortal = false,
    visibilityChangeCallback,
    title,
    ignoreInsideClick = false,
    ...rest
}) => {
    const [expandedDropdown, setExpandedDropdown] = useState<boolean>(false);
    const [canExpand, setCanExpand] = useState<boolean>(false);
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState<boolean>(showMenuContent);
    const [autoVerticalPosition, setDropdownVerticalPosition] = useState<string>(null);
    const [autoHorizontalPosition, setDropdownHorizontalPosition] = useState<string>(null);
    const [withScrollbarDesktop, setWithScrollbarDesktop] = useState<boolean>(false);
    const [scrollHeight, setScrollHeight] = useState<string>(null);

    const hideAnimationTimeout = useRef(null);
    const menuButtonRef = useRef<HTMLElement>(null);
    const menuContentRef = useRef<HTMLElement>(null);
    const scrollBarRef = useRef(null);

    const { isMobile, resolution } = useResizeWatcher();

    const setDropdownPosition = () => {
        if (!isMobile() && window && menuButtonRef?.current && menuContentRef?.current) {
            setDropdownVerticalPosition(
                menuButtonRef.current.getBoundingClientRect().bottom + menuContentRef.current.getBoundingClientRect().height <
                    window.innerHeight
                    ? 'bottom'
                    : 'top'
            );

            setDropdownHorizontalPosition(
                menuButtonRef.current.getBoundingClientRect().left + menuContentRef.current.getBoundingClientRect().width <
                    window.innerWidth
                    ? 'left'
                    : 'right'
            );
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
            onClick();
        }
        setDropdownPosition();

        const target = e.target as HTMLTextAreaElement;
        const trigger = triggerButton as any; // :|

        if (isDropdownMenuOpen && ignoreInsideClick) {
            if (target.className.includes(trigger.type.styledComponentId)) setIsDropdownMenuOpen(false);
            return;
        }
        if (!keepOpen) {
            setIsDropdownMenuOpen(prev => !prev);
        }
    };

    const handleMouseEnter = () => {
        onMouseEnterCb();

        if (keepOpen) return;
        clearTimeout(hideAnimationTimeout.current);

        if (openOnMouseEnter) {
            setDropdownPosition();
            setIsDropdownMenuOpen(true);
        }
    };

    const handleMouseLeave = () => {
        onMouseLeaveCb();

        if (keepOpen) return;
        hideAnimationTimeout.current = setTimeout(() => {
            setIsDropdownMenuOpen(false);
        }, MOUSE_LEAVE_TIMEOUT_AMOUNT);
    };

    useKeyboardShortcuts({
        backspace: handleMouseLeave,
    });

    const onSwipeUp = () => {
        if (!canExpand) return;
        setExpandedDropdown(true);
    };

    const onSwipeDown = () => {
        if (!expandedDropdown) {
            setIsDropdownMenuOpen(false);
        }
    };

    useEffect(() => {
        const handleCustomEvent = () => {
            setIsDropdownMenuOpen(prev => !prev);
        };

        document.addEventListener(PLAYER_SETTINGS_DROPDOWN_EVENT, handleCustomEvent);
        return () => {
            document.removeEventListener(PLAYER_SETTINGS_DROPDOWN_EVENT, handleCustomEvent);
        };
    }, []);

    const handleFocusOut = event => {
        if (!menuContentRef.current?.contains(event.relatedTarget)) {
            setIsDropdownMenuOpen(false);
        }
    };

    const getDesktopView = () => {
        return (
            <DropdownContent
                showMenuContent={isDropdownMenuOpen}
                isMqMobile={isMobile()}
                verticalPosition={verticalPosition || autoVerticalPosition}
                horizontalPosition={horizontalPosition || autoHorizontalPosition}
            >
                {withScrollbarDesktop ? (
                    <Scrollbar ref={scrollBarRef} style={{ height: maxHeight }} tabIndex={-1} scrollerProps={{ tabIndex: -1 }}>
                        <DropdownMenuList
                            ref={ref => {
                                menuContentRef.current = ref;
                            }}
                            onBlur={handleFocusOut}
                        >
                            {children}
                        </DropdownMenuList>
                    </Scrollbar>
                ) : (
                    <DropdownMenuList
                        ref={ref => {
                            menuContentRef.current = ref;
                        }}
                        onBlur={handleFocusOut}
                    >
                        {children}
                    </DropdownMenuList>
                )}
            </DropdownContent>
        );
    };

    const getMobileView = () => {
        const content = (
            <RemoveScroll enabled={isDropdownMenuOpen}>
                <Swipeable onSwipedUp={onSwipeUp} onSwipedDown={onSwipeDown}>
                    <DropdownContent
                        showMenuContent={isDropdownMenuOpen}
                        isMqMobile={isMobile()}
                        verticalPosition={verticalPosition || autoVerticalPosition}
                        horizontalPosition={horizontalPosition || autoHorizontalPosition}
                    >
                        <DropdownMenuList>
                            {!expandedDropdown && canExpand && (
                                <ExpendableBarWrapper>
                                    <ExpendableBar />
                                </ExpendableBarWrapper>
                            )}
                            {(title || expandedDropdown) && (
                                <DropdownTitle
                                    showBottomBorder={!!title || expandedDropdown}
                                    selected={false}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {title}
                                    {expandedDropdown && (
                                        <IconClear
                                            className={'clear-icon'}
                                            svg={icons.closeIcon}
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                setExpandedDropdown(false);
                                            }}
                                        />
                                    )}
                                </DropdownTitle>
                            )}
                            <Scrollbar
                                ref={scrollBarRef}
                                style={{ height: scrollHeight }}
                                noScroll={!expandedDropdown}
                                tabIndex={-1}
                                scrollerProps={{ tabIndex: -1 }}
                            >
                                <MenuContent
                                    ref={ref => {
                                        menuContentRef.current = ref;
                                    }}
                                >
                                    {children}
                                </MenuContent>
                            </Scrollbar>
                        </DropdownMenuList>
                    </DropdownContent>
                </Swipeable>
            </RemoveScroll>
        );

        if (!stripMobilePortal) {
            return createPortal(content, document.getElementById('modal-root'));
        }

        return content;
    };

    useEffect(() => {
        setWithScrollbarDesktop(menuContentRef?.current?.getBoundingClientRect()?.height > maxHeight);

        return () => {
            clearTimeout(hideAnimationTimeout.current);
        };
    }, [children]);

    useEffect(() => {
        const index = selectedIndex > 1 ? selectedIndex - 1 : 1;
        const selectedElement: HTMLElement = menuContentRef.current.querySelector(`*:nth-child(${index})`);

        if (selectedElement && scrollBarRef?.current) {
            scrollBarRef.current.scrollTop = selectedElement.offsetTop;
        }
    }, [selectedIndex, isDropdownMenuOpen, expandedDropdown]);

    useEffect(() => {
        if (visibilityChangeCallback) {
            visibilityChangeCallback(isDropdownMenuOpen);
        }
    }, [isDropdownMenuOpen]);

    useEffect(() => {
        setDropdownPosition();
    }, [resolution]);

    useEffect(() => {
        const { clientHeight } = document.body;

        if (menuContentRef.current?.clientHeight < scrollBarRef.current?.clientHeight) {
            setScrollHeight(`${menuContentRef.current?.clientHeight}px`);
            setCanExpand(false);
        } else {
            const notExpandedHeight = (menuContentRef.current?.children[0]?.clientHeight ?? 48) * 5;

            setScrollHeight(expandedDropdown ? `${clientHeight * 0.6}px` : ` ${notExpandedHeight}px`);
            setCanExpand(true);
        }
    }, [expandedDropdown, resolution, isDropdownMenuOpen]);

    useEffect(() => {
        if (!isDropdownMenuOpen) {
            setExpandedDropdown(false);
        }
    }, [isDropdownMenuOpen]);

    return (
        <DropdownWrapper
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={ref => {
                menuButtonRef.current = ref;
            }}
            onClick={e => handleClick(e)}
        >
            {triggerButton}
            {!isMobile() ? getDesktopView() : getMobileView()}
        </DropdownWrapper>
    );
};

export type DropdownItemWithSvgProp = {
    svg: {};
    href?: string;
    onClick?: (e) => void;
    hideOnDesktop?: boolean;
    tabindex?: number;
};

export const DropdownItemWithSvg: React.FC<DropdownItemWithSvgProp> = ({
    svg = {},
    href = '',
    onClick,
    children,
    hideOnDesktop = false,
    tabindex,
}) => {
    const handleClick = e => {
        if (onClick) {
            e.preventDefault();
            onClick(e);
        }
    };
    return (
        <DropdownItemWithSvgWrapper href={href} onClick={handleClick} hideOnDesktop={hideOnDesktop} tabIndex={tabindex}>
            <SVGWrapper>
                <SVGInline svg={svg} />
            </SVGWrapper>
            <DropdownLabel>{children}</DropdownLabel>
        </DropdownItemWithSvgWrapper>
    );
};

export type DropdownItemSimpleProp = {
    selected?: boolean;
    href?: string;
    onClick?: (e) => void;
    withSelectedIcon?: boolean;
    tabindex?: number;
};

export const DropdownItemSimple: React.FC<DropdownItemSimpleProp> = ({
    href = '',
    onClick,
    children,
    selected = false,
    withSelectedIcon = true,
    tabindex,
}) => {
    const { isMobile } = useResizeWatcher();
    const handleClick = e => {
        if (onClick) {
            e.preventDefault();
            onClick(e);
        }
    };

    return (
        <DropdownItemSimpleWrapper
            data-test-id={'header-account-dropdownoptions'}
            selected={selected}
            href={href}
            onClick={handleClick}
            tabIndex={tabindex}
        >
            {isMobile() && withSelectedIcon && <SVGInline svg={selected ? icons.icoCheckboxChecked : icons.icoCheckboxUnchecked} />}
            {children}
            {!isMobile() && selected && (
                <RightSvgWrapper>
                    <SVGInline className={'right'} svg={icons.checkMarkIcon} />
                </RightSvgWrapper>
            )}
        </DropdownItemSimpleWrapper>
    );
};

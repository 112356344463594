import React, { useEffect, useRef } from 'react';
import { LinkTrackable } from 'components/Trackable/LinkTrackable';
import { LinkShowAll } from '../LinkShowAll/LinkShowAll';
import icons from '../../style';
import { ShowAllButton, SliderTitle, SliderTitleText, SliderWrapper, TilesContainer } from './Slider.css';
import { StripeConfigType } from '../Stripes/StripeMixed/StripeMixed';
import { useScreenState } from '../../hooks/useScreenState/useScreenState';
import { ArrowIcon } from '../Lane/Lane.css';
import { Lane } from '../Lane/Lane';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { getCollectionNavigationPath } from '../../utils/fnCollections';
import { CoverAsset } from '../../types/Asset';
import translate from '../../utils/fnTranslate';
import { StripeMixedLoadingUI } from '../Stripes/StripeMixed/StripeMixedLoadingUI';
import { buildCollectionPageUrl } from '../../utils/fnUrl';
import { useCollectionLocationParams } from '../../hooks/useCollectionLocationParams';
import { EditorialItem } from '../../types/Config';
import { StripeTypes } from '../../types/Common';
import { useNavigation } from '../../hooks/useNavigation';
import { isMenuTabItem, isPageItem } from '../../utils/fnEditorial';
import { StripePresenterSelector } from '../PresenterSelector/StripePresenterSelector';
import { useHint } from '../../providers/useHint/HintContext';
import { useDetailLocationParams } from '../../hooks/useDetailLocationParams';
import { getLocalStorage } from '../../utils/fnStorage';
import { CollectionRoute } from '../../types/RouteTypes';
import { useApp } from '../../providers/useApp/AppContext';

type SliderProps = {
    title: string;
    showAll?: boolean;
    miniEpg?: boolean;
    editorialAll?: EditorialItem;
    assets: CoverAsset[];
    isLoading?: boolean;
    stripePosition: number;
    screenName: string;
    config: StripeConfigType;
    disableUiStoreState?: boolean;
    initScrollTo?: number;
    resetScroll?: boolean;
    onCardClick?: (event: React.MouseEvent<any>, data: any) => void;
    onVisibleIndexesChanged?: (first: number, last: number) => void;
    scrollPercentage?: (percentage) => void;
    onScrollRightEdgeReached?: () => void;
    onScrollLeftEdgeReached?: () => void;
    onScrollStopped?: (scrollPosition) => void;
};

export const Slider: React.FC<SliderProps> = ({
    title,
    screenName,
    assets,
    isLoading = false,
    showAll = false,
    miniEpg = false,
    editorialAll,
    stripePosition = 0,
    disableUiStoreState = false,
    initScrollTo,
    resetScroll,
    config,
    onCardClick,
    onVisibleIndexesChanged = () => {},
    onScrollStopped = () => {},
    scrollPercentage = () => {},
    onScrollLeftEdgeReached = () => {},
    onScrollRightEdgeReached = () => {},
}) => {
    const { storeState, obtainState } = useScreenState();
    const storeKey = `slider_${stripePosition}${title}`;
    const storedData = !disableUiStoreState ? obtainState(storeKey) : null;
    const { config: appConfig } = useConfig();
    const locationParamsPath = useCollectionLocationParams();
    const { history } = useNavigation();
    const hintContainerRef = useRef<HTMLDivElement>(null);
    const { isDetailPage } = useDetailLocationParams();
    const isMobile = getLocalStorage('isMobile');
    const { appLanguage } = useApp();

    const { emptyStates } = useScreenState();

    const { detachHint, attachHint } = useHint(
        translate('HINT_TEXT_PERSON_ADD_REMOVE_WEB'),
        hintContainerRef,
        'userDismissedHintPersonStripe',
        config?.type === StripeTypes.ROUNDED && isDetailPage,
        { desktop: -34, tablet: -34, mobile: -34 },
        { desktop: 40, tablet: 32, mobile: 16 }
    );

    let showMorePath =
        miniEpg && showAll
            ? CollectionRoute[appLanguage]?.NOW
            : getCollectionNavigationPath(showAll, appConfig, config?.moduleConfig?.collection?.id, editorialAll);

    const isAbsolutePath = miniEpg && showAll;

    if (!isPageItem(editorialAll) && !isMenuTabItem(editorialAll) && showMorePath) {
        showMorePath = buildCollectionPageUrl(locationParamsPath, showMorePath);

        // append url query params to the collection url if there are
        const urlQueryParams = new URLSearchParams(history.location.search);
        showMorePath += `?${urlQueryParams.toString()}`;
    }

    const hasTitle = title != null && title.length > 0;

    useEffect(() => {
        return () => {
            detachHint();
        };
    }, []);

    useEffect(() => {
        if (assets.length) {
            attachHint();
        }
    }, [assets]);

    useEffect(() => {
        if (resetScroll) {
            storeState(storeKey, 0);
        }
    }, [resetScroll]);

    if (resetScroll) {
        return <StripeMixedLoadingUI stripeType={config?.type as StripeTypes} withTitle={hasTitle} />;
    }

    const cardPresenters = StripePresenterSelector.create()
        .setPosition(stripePosition)
        .setType(config?.type as StripeTypes)
        .setTitle(translate(title))
        .setScreenName(translate(screenName))
        .setCardClickListener(onCardClick)
        .setAssets(assets ?? [])
        .get();

    return !(assets?.length > 0) ? (
        isLoading ? (
            <StripeMixedLoadingUI stripeType={config?.type as StripeTypes} withTitle={hasTitle} />
        ) : null
    ) : (
        cardPresenters?.length > 0 && (
            <SliderWrapper ref={hintContainerRef} isMobile={isMobile}>
                {showAll && showMorePath ? (
                    <SliderTitle>
                        <LinkTrackable
                            to={showMorePath}
                            useAbsolutePath={isAbsolutePath}
                            onClick={() => {
                                emptyStates(showMorePath);
                            }}
                            data-event-name={'click'}
                            data-track-id={'stripe_view_all'}
                            data-page-name={'Home'}
                            data-module-title={title}
                            visibleFocus="underline"
                        >
                            <SliderTitleText
                                data-test-id={`slider-movieresults-${title}`}
                                /* maxWidth={showAll ? 'calc(100% - 110px)' : '100%'} */
                            >
                                {translate(title)}
                            </SliderTitleText>
                        </LinkTrackable>
                        <ShowAllButton>
                            <LinkShowAll />
                        </ShowAllButton>
                    </SliderTitle>
                ) : (
                    <SliderTitle>
                        {title && (
                            <SliderTitleText
                                data-test-id={`slider-movieresults-${title}`}
                                /* maxWidth={showAll ? 'calc(100% - 110px)' : '100%'} */
                            >
                                {translate(title)}
                            </SliderTitleText>
                        )}
                    </SliderTitle>
                )}
                <TilesContainer data-test-id={'homepage-home-tilesection'}>
                    <Lane
                        arrowLeft={<ArrowIcon data-test-id={'tv-detailscreen-castleftarrow'} svg={icons.leftArrowIcon} />}
                        arrowRight={<ArrowIcon data-test-id={'tv-detailscreen-castrightarrow'} svg={icons.rightArrowIcon} />}
                        scrollLeft={resetScroll ? 0 : !disableUiStoreState ? storedData?.value ?? 0 : -1}
                        isMobile={isMobile}
                        navigateTo={
                            initScrollTo
                                ? {
                                      number: initScrollTo,
                                      smooth: false,
                                  }
                                : null
                        }
                        scrollable={true}
                        onScrollStopped={scroll => {
                            if (!disableUiStoreState) {
                                storeState(storeKey, scroll);
                            }
                            onScrollStopped(scroll);
                        }}
                        onScrollLeftEdgeReached={onScrollLeftEdgeReached}
                        onScrollRightEdgeReached={onScrollRightEdgeReached}
                        scrolledPercentage={scrollPercentage}
                        onVisibleIndexesChanged={onVisibleIndexesChanged}
                    >
                        {cardPresenters}
                    </Lane>
                </TilesContainer>
            </SliderWrapper>
        )
    );
};

import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { getLocalStorage } from 'utils/fnStorage';
import { HelmetData } from '../../types/Common';

/**
 * Wrapper which received a HelmetData object and based on that updates the meta title, description etc
 *
 * @param title
 * @param canonicalLink
 * @param openGraph
 * @param description
 * @param robots
 * @param children
 * @constructor
 */
export const HelmetWrapper: FC<HelmetData> = ({ title, canonicalLink, openGraph, description, robots = 'noindex', children }) => {
    const appLanguageStorage = getLocalStorage('appLanguage');

    return (
        <>
            <Helmet htmlAttributes={{ lang: appLanguageStorage || 'et' }}>
                <title>{title}</title>
                <meta name="robots" content={robots} />
                {canonicalLink && <link rel="canonical" href={canonicalLink} />}
                {description && <meta name="description" content={description} />}
                {Object.keys(openGraph).map(ogKey => {
                    if (!openGraph[ogKey]) return null;
                    return <meta key={ogKey} property={ogKey} content={openGraph[ogKey]} />;
                })}
            </Helmet>
            {children}
        </>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { SearchInputLabel } from 'components/Header/HeaderSearch/HeaderSearch.css';
import { FilterWrapper, FilterInput, IconSearchDelete, IconSearch } from './EpgFilterSearch.css';
import { useEpgStore } from '../../../providers/useEpgStore/EpgContext';
import icons from '../../../style';

export const EpgFilterSearch: React.FC<{ placeholder: string; onSearch?: () => void }> = ({ placeholder, onSearch }) => {
    const { searched, search } = useEpgStore();
    const [searchQuery, setSearchQuery] = useState<string>(searched);
    const searchTimeout = useRef(null);
    const searchDelay = 400;
    const searchInput = useRef(null);

    const onChange = e => {
        setSearchQuery(e.target.value);
        onSearch();
    };

    const clearSearch = () => {
        setSearchQuery(null);
    };

    useEffect(() => {
        return () => {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            search(searchQuery?.trim());
        }, searchDelay);
    }, [searchQuery]);

    return (
        <FilterWrapper>
            <SearchInputLabel htmlFor="channelSearch">{placeholder}</SearchInputLabel>
            <FilterInput
                id="channelSearch"
                isEmpty={!searched}
                type={'text'}
                placeholder={placeholder}
                onChange={onChange}
                value={searchQuery || ''}
                ref={searchInput}
            />
            <IconSearch data-test-id={'tv-tvguide-searchbutton'} svg={icons.searchIcon} />
            <IconSearchDelete data-test-id={'tv-tvguide-clearsearchbarbutton'} svg={icons.closeIcon} onMouseDown={clearSearch} />
        </FilterWrapper>
    );
};

import React, { FC, useEffect, useRef, useState } from 'react';
import { useGlobalNetworkError } from 'hooks/withNetworkCheck/withNetworkCheck';
import { useHistory } from 'react-router';
import { Channel, CoverAsset } from '../../types/Asset';
import { Slider } from '../Slider/Slider';
import { SliderWrapper } from '../Slider/Slider.css';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { isNowAndNext } from '../../utils/fnTypeGuards';
import { StripeTypes } from '../../types/Common';
import { getLivePlayerURL } from '../../utils/fnUrl';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { DataSourceMethod, DataSourceService } from '../../types/ApiTypes';
import { nowAndNextCollectionParser } from '../../utils/fnParser';
import { MiniEpgDataLoader } from '../../providers/useEpgStore/MiniEpgDataLoader';
import { ControlGroup } from '../Player/PlayerWrapper';
import { usePlayer } from '../../providers/player/PlayerContext';
import { PlayingMode } from '../../types/Player';

type PropTypes = {
    currentChannel: string;
    channels: Channel[];
    activeGroup: ControlGroup;
};

const MiniEpgWrapper = styled.div`
    ${SliderWrapper} {
        padding-bottom: 0;
        padding-top: 0;
    }
`;

const MINI_EPG_TITLE = 'MINI_EPG_TITLE';
const LOAD_DELAY = 400;
const RESET_CURRENT_CHANNEL_ON_GROUP_CHANGE = true;

export const MiniEpg: FC<PropTypes> = ({ currentChannel, channels, activeGroup }) => {
    const [broadcasts, setBroadcasts] = useState<CoverAsset[]>(null);
    const [initialScrollPosition, setInitialScrollPosition] = useState<number>(null);

    const dataLoader = useRef<MiniEpgDataLoader>();
    const shouldJumpToSelected = useRef<boolean>(true);
    const loadTimeoutRef = useRef<number>(null);

    const { config } = useConfig();

    const history = useHistory();
    const { setPlayingMode } = usePlayer();
    const { onNetworkError } = useGlobalNetworkError();

    const initLoader = () => {
        if (dataLoader.current != null) {
            return;
        }

        dataLoader.current = new MiniEpgDataLoader(
            {
                Service: DataSourceService.TV,
                request: {
                    method: DataSourceMethod.GET_NOW_NEXT_REQUEST,
                },
            },
            nowAndNextCollectionParser,
            config.api_config.page_size
        )
            .setDataChangedListener(data => {
                setBroadcasts(data?.getMergedData()?.items);
            })
            .setNetworkErrorCallback(onNetworkError)
            .generatePlaceholderChunks(channels.length);
    };

    const loaderCleanup = () => {
        if (dataLoader.current) {
            dataLoader.current.setDataChangedListener(null).destroy();
            dataLoader.current = null;
        }
        clearTimeout(loadTimeoutRef.current);
    };

    useEffect(() => {
        if (currentChannel && channels && broadcasts) {
            setInitialScrollPosition(channels?.findIndex(channel => channel.id === currentChannel) ?? -1);
        }
    }, [currentChannel, broadcasts, channels, activeGroup]);

    useEffect(() => {
        if (currentChannel || (activeGroup !== ControlGroup.EXTRA && RESET_CURRENT_CHANNEL_ON_GROUP_CHANGE)) {
            shouldJumpToSelected.current = true;
        }
    }, [currentChannel, activeGroup]);

    useEffect(() => {
        if (initialScrollPosition) {
            shouldJumpToSelected.current = false;
            setInitialScrollPosition(null);
        }
    }, [initialScrollPosition]);

    useEffect(() => {
        if (channels && channels.length) {
            setBroadcasts(null);
            initLoader();
        }
    }, [channels]);

    useEffect(() => {
        return loaderCleanup;
    }, []);

    const onVisibleIndexesChanged = (first: number, last: number) => {
        if (dataLoader.current) {
            clearTimeout(loadTimeoutRef.current);
            loadTimeoutRef.current = window.setTimeout(() => {
                dataLoader.current.loadDataFor(first, last);
            }, LOAD_DELAY);
        }
    };

    if (!broadcasts) {
        return null;
    }

    return (
        <MiniEpgWrapper>
            <Slider
                title={MINI_EPG_TITLE}
                assets={broadcasts}
                isLoading={!broadcasts}
                stripePosition={0}
                screenName={''}
                showAll={true}
                miniEpg={true}
                onCardClick={(event, data) => {
                    if (isNowAndNext(data)) {
                        setPlayingMode(PlayingMode.NORMAL);
                        history.replace(getLivePlayerURL(data.channelId));
                    }
                }}
                config={{
                    type: StripeTypes.NOW_NEXT,
                    moduleConfig: null,
                    variant: 'a1',
                }}
                initScrollTo={shouldJumpToSelected.current ? initialScrollPosition : null}
                onVisibleIndexesChanged={onVisibleIndexesChanged}
            />
        </MiniEpgWrapper>
    );
};

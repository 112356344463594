/**
 * Default icon set
 * Naming is important!
 */

import accountIcon from './ico-account.svg';
import disclosureArrowIcon from './ico-arrow-disclosure.svg';
import leftArrowIcon from './ico-arrow-left.svg';
import rightArrowIcon from './ico-arrow-right.svg';
import bottomArrowIcon from './ico-arrow-bottom.svg';
import bottomArrowSmallIcon from './ico-arrow-down-small.svg';
import rightArrowIconSmall from './ico-arrow-right-small.svg';
import downArrowIcon from './ico_arrow_down.svg';
import bookmarkIcon from './ico-bookmark.svg';
import bookmarkFullIcon from './ico-bookmark-full.svg';
import bookmarkMiniIcon from './ico_bookmark_mini_add.svg';
import bookmarkMiniFullIcon from './ico_bookmark_mini_remove.svg';
import bookmarkMiniIconBlack from './ico_bookmark_mini_add_black.svg';
import closeIcon from './ico-close.svg';
import closeIconNew from './ico-new-close.svg';
import deleteIcon from './ico-delete-filled-grey.svg';
import eyeIcon from './ico_eye.svg';
import eyeClosedIcon from './ico_eye_closed.svg';
import facebookIcon from './ico-facebook.svg';
import facebookIconFilled from './ico-facebook-filled.svg';
import hamburgerIcon from './ico-hamburger.svg';
import instagramIcon from './ico-instagram.svg';
import rdySmileIcon from './ico-rdy-smile.svg';
import liveTagIcon from './ico-live-tag.svg';
import logoutIcon from './ico-logout.svg';
import nextIcon from './ico-next.svg';
import paperPlaneIcon from './ico-paper-plane.svg';
import playIcon from './ico-play.svg';
import playIconEpgHovered from './ico-play-epg-hovered.svg';
import playOutlineIcon from './ico-play-outline.svg';
import prevIcon from './ico-prev.svg';
import searchIcon from './ico-search.svg';
import twitterIcon from './ico-twitter.svg';
import twitterIconFilled from './ico-twitter-filled.svg';
import warningIcon from './ico-warning.svg';
import youtubeIcon from './ico-youtube.svg';
import accountDetailsIcon from './ico-account-details.svg';
import accountSettingsIcon from './ico-account-settings.svg';
import premiumIcon from './ico-premium.svg';
import dropdownIcon from './ico-arrow-down.svg';
import timeIndicatorIcon from './time-indicator.svg';
import primeTimeIndicatorIcon from './prime-time-indicator.svg';
import tvPlaceholderIcon from './ico-tv-placeholder.svg';
import shareIcon from './ico-share.svg';
import mailIcon from './ico-mail.svg';
import epgScrubber from './epg-slider.svg';
import pauseIconSimple from './ico_pause.svg';
import playIconSimple from './ico_play.svg';
import iconJumpBackward from './ico_skip_backward.svg';
import iconJumpForward from './ico_skip_forward.svg';
import iconJumpBackwardStrong from './ico_skip_backward_strong.svg';
import iconJumpForwardStrong from './ico_skip_forward_strong.svg';
import volumeIcon from './ico_audio.svg';
import volumeMinIcon from './ico_audio_min.svg';
import subtitlesIconPlayer from './ico_subtitles.svg';
import volumeMutedIcon from './ico_audio_muted.svg';
import volumeIcoSubs from './ico_substract.svg';
import volumeIcoAdd from './ico_add.svg';
import moreMediaIcon from './ico_more_media.svg';
import nextAssetIcon from './ico_next.svg';
import infoIcon from './ico_info.svg';
import infoIconFull from './ico_info_full.svg';
import castIcon from './ico_cast.svg';
import castConnectedIcon from './ico-cast-connected.svg';
import castAvailableIcon from './ico-cast-available.svg';
import fullScreenIcon from './ico-full-screen.svg';
import shrinkScreenIcon from './ico-full-screen-reverse.svg';
import ico_home from './ico_home.svg';
import ico_tv from './ico_tv.svg';
import ico_on_demand from './ico_on_demand.svg';
import ico_movies from './ico_movies.svg';
import ico_kids from './ico_kids.svg';
import audioLanguageIcon from './ico_flag.svg';
import subtitlesIcon from './ico_sub.svg';
import userIcon from './ico_user.svg';
import avatarIcon from './avatar_icon.svg';
import appLogo from './app-logo.svg';
import triangleIcon from './ico-triangle.svg';
import imdbIcon from './ico-imdb.svg';
import showMoreIcon from './ico_show_more.svg';
import dolbyIcon from './ico_dolby.svg';
import catchUpIcon from './ico_catchup.svg';
import lockIcon from './ico_lock.svg';
import unlockIcon from './ico-unlock.svg';
import starIcon from './ico_star.svg';
import icoResolutionSD from './ico_sd.svg';
import icoResolutionHD from './ico_hd.svg';
import icoResolution4K from './ico_4k.svg';
import icoResolution8K from './ico_8k.svg';
import heartIcon from './ico_heart.svg';
import checkMarkIcon from './ico_checkmark.svg';
import emptyHeartIcon from './ico_heart_empty.svg';
import icon404 from './404-icon.svg';
import passwordAsterisk from './ico-password-asterisk.svg';
import icoOngoingRecording from './ico_rec_ongoing.svg';
import icoScheduledRecording from './ico_rec_scheduled.svg';
import icoRecordedRecording from './ico_rec_recorded.svg';
import icoRecord from './ico_record.svg';
import icoEndRecording from './ico_end_recording.svg';
import icoDeleteRecording from './ico_delete_recording.svg';
import icoFailedRecording from './ico_rec_recfailed.svg';
import icoGoToLiveProgram from './live_icon.svg';
import icoStartOver from './ico_start_over.svg';
import icoSettings from './ico_settings.svg';
import icoDeviceDesktop from './ico-device-desktop.svg';
import icoDevicePhone from './ico-device-phone.svg';
import icoDeviceTablet from './ico-device-tablet.svg';
import icoDeviceTv from './ico-device-tv.svg';
import icoDevices from './ico-devices.svg';
import icoSettingsPlaceholder from './ico-settings-placeholder.svg';
import icoTrash from './ico_trash.svg';
import icoLanguage from './ico_language.svg';
import EnFlag from './ico_enFlag.svg';
import EtFlag from './ico_etFlag.svg';
import RuFlag from './ico_ruFlag.svg';
import icoFailed from './ico_failed.svg';
import icoSearchHistory from './ico-search-history.svg';
import icoFavoriteEmpty from './ico-favorite-empty.svg';
import icoFavorite from './ico-favorite.svg';
import icoMinimize from './ico-minimize.svg';
import icoCheckboxUnchecked from './ico-checkbox-unchecked.svg';
import icoCheckboxChecked from './ico-checkbox-checked.svg';
import icoPlayIconHovered from './ico-playIcon-hovered.svg';
import icoSpeed from './ico-speed.svg';
import icoFilter from './ico_filter.svg';
import icoGalleryPlay from './ico-new-gallery-play.svg';
import icoGalleryPause from './ico-new-gallery-pause.svg';
import icoGalleryLeft from './ico-new-gallery-left.svg';
import icoGalleryRight from './ico-new-gallery-right.svg';
import externalLink from './external-link.svg';
import accessibility from './ico-accessibility.svg';
import moreEpisodes from './ico-show-more-series.svg';
import chevronDown from './ico-new-chevron-down.svg';
import icoCalendar from './ico-new-calendar.svg';
import icoChannels from './ico-new-channels.svg';

export default {
    accountIcon,
    disclosureArrowIcon,
    leftArrowIcon,
    rightArrowIcon,
    bottomArrowIcon,
    rightArrowIconSmall,
    bottomArrowSmallIcon,
    downArrowIcon,
    bookmarkIcon,
    bookmarkFullIcon,
    bookmarkMiniIcon,
    bookmarkMiniFullIcon,
    bookmarkMiniIconBlack,
    closeIcon,
    closeIconNew,
    deleteIcon,
    eyeIcon,
    eyeClosedIcon,
    facebookIcon,
    facebookIconFilled,
    hamburgerIcon,
    instagramIcon,
    rdySmileIcon,
    liveTagIcon,
    logoutIcon,
    nextIcon,
    paperPlaneIcon,
    playIcon,
    playIconEpgHovered,
    playOutlineIcon,
    prevIcon,
    searchIcon,
    twitterIcon,
    twitterIconFilled,
    warningIcon,
    youtubeIcon,
    accountDetailsIcon,
    accountSettingsIcon,
    premiumIcon,
    dropdownIcon,
    timeIndicatorIcon,
    primeTimeIndicatorIcon,
    tvPlaceholderIcon,
    epgScrubber,
    shareIcon,
    mailIcon,
    playIconSimple,
    iconJumpForward,
    iconJumpBackward,
    iconJumpForwardStrong,
    iconJumpBackwardStrong,
    subtitlesIconPlayer,
    moreMediaIcon,
    nextAssetIcon,
    infoIcon,
    infoIconFull,
    castIcon,
    castConnectedIcon,
    castAvailableIcon,
    pauseIconSimple,
    volumeIcon,
    volumeMinIcon,
    volumeMutedIcon,
    volumeIcoSubs,
    volumeIcoAdd,
    fullScreenIcon,
    shrinkScreenIcon,
    userIcon,
    avatarIcon,
    appLogo,
    triangleIcon,
    imdbIcon,
    showMoreIcon,
    ico_home,
    ico_tv,
    ico_on_demand,
    ico_movies,
    ico_kids,
    dolbyIcon,
    catchUpIcon,
    audioLanguageIcon,
    subtitlesIcon,
    lockIcon,
    unlockIcon,
    starIcon,
    icoResolutionSD,
    icoResolutionHD,
    icoResolution4K,
    icoResolution8K,
    heartIcon,
    checkMarkIcon,
    emptyHeartIcon,
    icon404,
    passwordAsterisk,
    icoOngoingRecording,
    icoScheduledRecording,
    icoRecordedRecording,
    icoFailedRecording,
    icoRecord,
    icoEndRecording,
    icoDeleteRecording,
    icoGoToLiveProgram,
    icoStartOver,
    icoSettings,
    icoDeviceDesktop,
    icoDevicePhone,
    icoDeviceTablet,
    icoDeviceTv,
    icoDevices,
    icoTrash,
    icoLanguage,
    EnFlag,
    EtFlag,
    RuFlag,
    icoFailed,
    icoSearchHistory,
    icoFavoriteEmpty,
    icoFavorite,
    icoMinimize,
    icoCheckboxChecked,
    icoCheckboxUnchecked,
    icoPlayIconHovered,
    icoSettingsPlaceholder,
    icoSpeed,
    icoFilter,
    icoGalleryPlay,
    icoGalleryPause,
    icoGalleryLeft,
    icoGalleryRight,
    externalLink,
    accessibility,
    moreEpisodes,
    chevronDown,
    icoCalendar,
    icoChannels,
};

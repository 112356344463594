import { ReactNode } from 'react';
import { IPlayer } from '../providers/player/abstract/IPlayer';
import { AiringTime, Recordable, Rentable, Subscribable } from './CommonTypes';
import { Marker, Person } from './Asset';

export type PlayerLocationParams = {
    channelId?: string;
    broadcastId?: string;
    assetId?: string;
    recordingId?: string;
};

export type PlayerProps = {
    type: PlayingAssetType;
    params: PlayerLocationParams;
};

export type Track = {
    role: string;
    language: string;
    label: string;
    channelsCount?: number;
    raw?: any;
};

export type BitrateTrack = {
    height: number;
    width: number;
    bandwidth: any;
    raw?: any;
};

export enum PlayerEvents {
    BUFFERING = 'buffering',
    WAITING = 'waiting',
    VOLUME_CHANGE = 'volumechange',
    PLAY = 'play',
    BUFFER_END = 'bufferEnd',
    PLAYING = 'playing',
    PAUSE = 'pause',
    ENDED = 'ended',
    TRACKS_CHANGED = 'trackschanged',
    TIME_UPDATE = 'timeupdate',
    END_OF_STREAM = 'end_of_stream',
    JUMP_TO_LIVE = 'jump_to_live',
    CAST_STATUS_CHANGED = 'caststatuschanged',
    MEDIA_CHANGED = 'media_changed',
}

export type PlayerAsset = {
    id: string;
    manifestUrl: string;
    hasStream: boolean;
    contentId: string;
    keepAliveInterval: number;
    sessionId: string;
    image: string;
};

export type PlaybackSession = {
    manifestUrl: string;
    keepAliveInterval: number;
    sessionId: string;
};

export enum PlayingAssetType {
    LIVE = 'live',
    CATCHUP = 'catchup',
    VOD = 'vod',
    TRAILER = 'trailer',
    RECORDING = 'recording',
}

export enum PlayingMode {
    NORMAL = 'normal',
    TIME_SHIFT = 'time_shift',
}

export enum PlayerControl {
    JUMP_BACK = 'jump-back',
    JUMP_FORWARD = 'jump-fwd',
    PLAY_PAUSE = 'play-pause',
    CAST = 'cast',
    MORE_MEDIA = 'more-media',
    NEXT_ASSET = 'next-asset',
    SUBTITLE = 'subtitle',
    RECORDING = 'recording',
    FULL_SCREEN = 'fullscreen',
    VOLUME = 'volume',
    INFO = 'info',
    GO_TO_LIVE = 'go_to_live',
    START_OVER = 'start_over',
    CLOSE = 'close',
    MINIMIZE = 'minimize',
    SEEKBAR = 'seekbar',
}

export enum PlayerIcon {
    CATCHUP = 'catchUpIcon',
    ONGOING_RECORDING = 'icoOngoingRecording',
    SCHEDULED_RECORDING = 'icoScheduledRecording',
    RECORDED_RECORDING = 'icoRecordedRecording',
}

type BookmarkPlayerAssetInfo = {
    titleId: string;
    duration: number;
    bookmark?: number;
};

export type BroadcastPlayerAsset = PlayerAsset &
    AiringTime &
    BookmarkPlayerAssetInfo &
    Recordable & {
        type: PlayingAssetType;
        title: string;
        subtitle?: string;
        episodeName?: string;
        id: string;
        logicalChannelNumber: string;
        channelId: string;
        channelLogo: string;
        channelName: string;
        channelProductCode: string;
        releaseYear?: string;
        countries?: string[];
        channelHasReplay: boolean;
        channelSubscribed: boolean;
        seriesId?: string;
        genres: any[];
        cast: Person[];
        captionLanguages?: string[];
        originalLanguages?: string[];
        description: string;
    };

export type LivePlayerAssetInfo = BroadcastPlayerAsset &
    Subscribable &
    BookmarkPlayerAssetInfo & {
        isLive: boolean;
        replayContentId: string;
        prePadding?: number;
        postPadding?: number;
    };

export type CatchupPlayerAssetInfo = BroadcastPlayerAsset &
    BookmarkPlayerAssetInfo & {
        prePadding?: number;
        postPadding?: number;
    };

export type TrailerPlayerAssetInfo = PlayerAsset & {
    type: PlayingAssetType;
    id: string;
    title: string;
    genres: any[];
    subtitle?: string;
    episodeName?: string;
    releaseYear?: string;
    countries?: string[];
    cast: Person[];
    captionLanguages?: string[];
    originalLanguages?: string[];
    description: string;
    duration: number;
};

export type VodPlayerAssetInfo = PlayerAsset &
    BookmarkPlayerAssetInfo &
    Rentable & {
        type: PlayingAssetType;
        id: string;
        title: string;
        subtitle?: string;
        episodeName?: string;
        releaseYear?: string;
        countries?: string[];
        isMovie: boolean;
        duration: number;
        seriesId?: string;
        seasonNumber?: number;
        episodeNumber?: number;
        genres: any[];
        cast: Person[];
        captionLanguages?: string[];
        originalLanguages?: string[];
        description: string;
    };

export type RecordingPlayerAssetInfo = Omit<
    CatchupPlayerAssetInfo,
    'channelSubscribed' | 'channelHasReplay' | 'channelProductCode' | 'isRecordingSubscribed' | 'recordingProductCode'
>;

export type PlayingAsset =
    | LivePlayerAssetInfo
    | CatchupPlayerAssetInfo
    | RecordingPlayerAssetInfo
    | TrailerPlayerAssetInfo
    | VodPlayerAssetInfo;

export type PlayerError = {
    code: any;
    category?: any;
    message?: string;
    linkClickListener?: (e) => void;
    devMessage?: string;
    details?: { [key: string]: string };
};
export type PlayerConfig = {
    errorHandler: (error: PlayerError) => void;
    onInitialized: (instance: IPlayer) => void;
    onNetworkError: () => void;
};

export const SUBTITLE_NONE = 'NONE';
export const SUBTITLE_UNDEFINED = 'und';
export const BITRATE_TRACK_AUTO = 'AUTO';
export const AUDIO_DEFAULT = 'DEFAULT';

export type PropTypesPlayerUICommon = {
    isFullScreen: boolean;
    shouldHideLayout: boolean;
    loading: boolean;
    hasTracks: boolean;
    canStartOver: boolean;
    casting: boolean;
    playingMode: PlayingMode;
    onControlsRef: (ref: HTMLDivElement) => void;
    onBackClicked: () => void;
    toggleFullScreen: () => void;
    forceExcludedControls?: string[];
    extraControls?: ReactNode;
    isEmbedded?: boolean;
    setLayoutHidden: (isHidden: boolean) => void;
};

export type PropTypesAdditional = {
    metaData: string[];
    embeddedPlayerMetadata: string[];
    metaIcons: PlayerIcon[];
    moreOverlayMetaData: {
        firstLine: string[];
        secondLine: string[];
    };
    contentMarker: Marker;
    controls: PlayerControl[];
    disabledControls: PlayerControl[];
    logo?: ReactNode;
    seekEnabled: boolean;
    title: string;
    subtitle: string;
    actionPopup?: {
        [key in PlayerControl]?: ReactNode;
    };
    actionLabels?: {
        [key in PlayerControl]?: string;
    };
    mobileOverlayActionHandlers?: ReactNode[];
    logicalChannelNumber?: string;
    description: string;
    cast: Person[];
    originalLanguages?: string[];
    captionLanguages?: string[];
};

export enum PlayerKeyboardControl {
    SPACE = 'Space',
    LEFTARROW = 'ArrowLeft',
    RIGHTARROW = 'ArrowRight',
    UPARROW = 'ArrowUp',
    DOWNARROW = 'ArrowDown',
    ESCAPE = 'Escape',
    F = 'KeyF',
    M = 'KeyM',
}

import { useState, useRef, useCallback, useEffect } from 'react';
import { PlayerControl } from '../../types/Player';

interface UsePlayerTabNavigationProps {
    disabledControls: PlayerControl[];
    setLayoutHidden: (hidden: boolean) => void;
    onVolumeButtonClicked: () => void;
}

export const usePlayerTabNavigation = ({ disabledControls, setLayoutHidden, onVolumeButtonClicked }: UsePlayerTabNavigationProps) => {
    const [focusedControl, setFocusedControl] = useState<PlayerControl>(null);
    // Get the refs of player controls (buttons)
    const controlRefs = useRef(new Map<PlayerControl, HTMLElement>());

    // Set/remove a ref for a player control
    const setControlRef = useCallback(
        (control: PlayerControl) => (element: HTMLElement | null) =>
            element ? controlRefs.current.set(control, element) : controlRefs.current.delete(control),
        []
    );

    // get all focusable elements (NOT disabled ones)
    const getFocusableElements = useCallback(
        () =>
            Array.from(controlRefs.current.entries())
                .filter(([control, element]) => element && !disabledControls.includes(control) && element.offsetParent)
                .map(([control, element]) => ({ control, element })),
        [disabledControls]
    );

    const focusElement = useCallback(
        ({ control, element }: { control: PlayerControl; element: HTMLElement }) => {
            element.focus();
            setFocusedControl(control);
            setLayoutHidden(false);
        },
        [setLayoutHidden]
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter' && focusedControl) {
                event.preventDefault();
                if (focusedControl === PlayerControl.VOLUME) {
                    onVolumeButtonClicked();
                } else {
                    controlRefs.current.get(focusedControl)?.click();
                }
                return;
            }

            if (event.key !== 'Tab') return;

            const elements = getFocusableElements();

            if (elements.length === 0) return;

            const currentIndex = focusedControl ? elements.findIndex(e => e.control === focusedControl) : -1;
            const firstElement = currentIndex <= 0;
            const lastElement = currentIndex === elements.length - 1;

            // If it is the first or last element then continue with browser focus
            if ((event.shiftKey && firstElement) || (!event.shiftKey && lastElement)) {
                setFocusedControl(null);
                setLayoutHidden(true);
                return;
            }

            event.preventDefault();
            const nextIndex = (currentIndex + (event.shiftKey ? -1 : 1) + elements.length) % elements.length;
            focusElement(elements[nextIndex]);
        },
        [focusedControl, getFocusableElements, focusElement, setLayoutHidden, onVolumeButtonClicked]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    return {
        focusedControl,
        setFocusedControl,
        setControlRef,
        controlRefs,
    };
};

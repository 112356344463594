import { useEffect, useRef, useState } from 'react';
import { isMQDesktop, isMQTablet, isMQMobileLandscape } from '../../style/styled-components/cssMediaQueries';

/**
 * Utility hook to cluster resolution on window resize
 *
 * @param checkDelay - time to wait between resize events - default: 100ms
 */

type ResolutionCluster = 'mobile' | 'mobileLandscape' | 'tablet' | 'desktop';

export const useResizeWatcher = (checkDelay = 100, resizeCallback?: () => void) => {
    const getCluster = (): ResolutionCluster => {
        if (isMQDesktop()) {
            return 'desktop';
        }
        if (isMQTablet()) {
            return 'tablet';
        }

        if (isMQMobileLandscape()) {
            return 'mobileLandscape';
        }

        return 'mobile';
    };

    const [resolution, setResolution] = useState<ResolutionCluster>(getCluster());
    const resizeTimeout = useRef<number>(null);

    const resizeHandler = () => {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = window.setTimeout(() => {
            setResolution(getCluster());
            if (resizeCallback) {
                resizeCallback();
            }
        }, checkDelay);
    };

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    return {
        resolution,
        isMobile: () => resolution === 'mobile',
        isMobileLandscape: () => resolution === 'mobileLandscape',
        isTablet: () => resolution === 'tablet',
        isDesktop: () => resolution === 'desktop',
    };
};

import React, { useEffect, useState } from 'react';
import icons from 'assets/images/icons';
import SVGInline from 'react-svg-inline';
import { DropdownItemSimple, DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { useConfig } from 'providers/useConfig/ConfigContext';
import translate from 'utils/fnTranslate';
import { useApp } from 'providers/useApp/AppContext';
import { useElementInteractionTracking } from 'providers/useTracking/TrackingContext';
import { useHistory } from 'react-router';
import { getFlag } from 'utils/fnImages';
import { LanguageDropdownButton, LanguageDropdownWrapper } from './Header.css';

export const LanguageSettingsDropdown: React.FC = () => {
    const { config, changeLanguage } = useConfig();
    const { appLanguage } = useApp();
    const interactionTracking = useElementInteractionTracking();
    const history = useHistory();
    const [selectedLanguage, setSelectedLanguage] = useState(appLanguage);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (selectedLanguage === appLanguage) return;

        changeLanguage(selectedLanguage)
            .then(() => {
                history.push('/');
                window.location.reload();
            })
            .catch(() => {});
    }, [selectedLanguage]);

    const getLanguages = () => {
        const translationKeys = Object.keys(config.app_config.languages);

        return translationKeys.map(key => {
            return {
                name: key,
                label: translate(`SETTINGS_LANGUAGE_${key.toUpperCase()}`),
            };
        });
    };

    const onLanguageChange = (value: string) => {
        interactionTracking({
            'data-track-id': 'settings_language_radio_button',
            'data-button-title': value,
        });

        setSelectedLanguage(value);
    };

    return (
        <LanguageDropdownWrapper>
            <DropdownMenu
                triggerButton={
                    <LanguageDropdownButton
                        data-test-id={'language-dropdown-button'}
                        aria-label={translate('ARIA_LANGUAGE_BUTTON')}
                        aria-haspopup
                        tabIndex={0}
                    >
                        <SVGInline svg={icons.icoLanguage} />
                    </LanguageDropdownButton>
                }
                horizontalPosition={'right'}
                verticalPosition={'bottom'}
                visibilityChangeCallback={setIsOpen}
            >
                {getLanguages()?.map(language => {
                    return (
                        <DropdownItemSimple
                            key={language.name}
                            selected={appLanguage === language.name}
                            onClick={() => {
                                onLanguageChange(language.name);
                            }}
                            tabindex={isOpen ? 0 : -1}
                        >
                            <SVGInline className="colored" id={language.name} svg={getFlag(language.name)} />
                            {language.label}
                            <span className="sr-only">{translate('ARIA_HINT_RELOADS_APP')}</span>
                        </DropdownItemSimple>
                    );
                })}
            </DropdownMenu>
        </LanguageDropdownWrapper>
    );
};

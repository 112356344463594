import React, { FC, useEffect } from 'react';
import { useAuth } from 'providers/useAuth/AuthContext';
import { useConfig } from 'providers/useConfig/ConfigContext';
import { isMQDesktop } from 'style/styled-components/cssMediaQueries';
import { useHistory } from 'react-router';
import { useApp } from 'providers/useApp/AppContext';
import { StaticPageRoutes } from 'types/RouteTypes';
import { redirectTo } from 'utils/fnSettings';
import { CenterButton, OptionName, PageWrapper, Section, Subtitle } from '../PageSettingsCommon.css';
import translate from '../../../../utils/fnTranslate';
import icons from '../../../../style';
import OptionFocusable from '../OptionFocusable';

export const AccountSettings: FC = () => {
    const { userInfoDetails } = useAuth();
    const { config } = useConfig();
    const history = useHistory();

    if (!userInfoDetails) {
        return null;
    }

    const { subscription, email } = userInfoDetails;
    const changeCredentialsURL = config.api_config.routes.Auth.change_credentials.replace('{id}', subscription);

    const getDesktopSection = () => {
        return (
            <Section aria-label={translate('SETTINGS_ACCOUNT_INFO_WEB')}>
                <Subtitle hideOnSmallRes>{translate('SETTINGS_ACCOUNT_INFO_WEB')}</Subtitle>

                <OptionFocusable
                    title={translate('CHANGE_EMAIL_LINK')}
                    subtitle={email}
                    icon={icons.mailIcon}
                    isExternalLink
                    onClick={() => redirectTo(changeCredentialsURL)}
                />
                <OptionFocusable
                    title={translate('CHANGE_PASSWORD_LINK')}
                    subtitle="**********"
                    icon={icons.lockIcon}
                    isExternalLink
                    onClick={() => redirectTo(changeCredentialsURL)}
                />
            </Section>
        );
    };

    const getMobileSection = () => {
        return (
            <OptionFocusable
                title={translate('SETTINGS_ACCOUNT_INFO')}
                icon={icons.accountIcon}
                onClick={() => history.push(StaticPageRoutes.ACCOUNT)}
            />
        );
    };

    return isMQDesktop() ? getDesktopSection() : getMobileSection();
};

export const AccountSettingsPage: FC = () => {
    const { setPageTitle } = useApp();
    const { userInfoDetails } = useAuth();
    const { config } = useConfig();

    useEffect(() => {
        setPageTitle(translate('SETTINGS_ACCOUNT_INFO'));
    }, []);

    if (!userInfoDetails) {
        return null;
    }

    const { subscription, email } = userInfoDetails;
    const changeCredentialsURL = config.api_config.routes.Auth.change_credentials.replace('{id}', subscription);

    return (
        <PageWrapper>
            <OptionFocusable title={translate('SETTINGS_ACCOUNT_INFO_EMAIL')} actionComponent={<OptionName>{email}</OptionName>} />
            <OptionFocusable
                title={translate('SETTINGS_ACCOUNT_INFO_PASSWORD')}
                actionComponent={<OptionName>{'**********'}</OptionName>}
            />
            <CenterButton label={translate('CHANGE_ACCOUNT_INFO_BUTTON')} onClick={() => redirectTo(changeCredentialsURL)} isPrimary />
        </PageWrapper>
    );
};

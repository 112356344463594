import { css } from 'styled-components';
import { StyledSVG } from 'style/styled-components/reusable.css';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';
import { ButtonPlayOutline } from '../../Buttons/ButtonPlayOutline/ButtonPlayOutline';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../../utils/fnColors';

export const landscapeCardWidth = 288;
export const landscapeCardHeight = 204;

export const landscapeCardHoveredWidth = 315;
export const landscapeCardHoveredHeight = 228;

export const ImageWrapperHeight = 162;

export const animationType = 'ease-in-out';
export const animationDuration = '150ms';
export const animationDelay = '150ms';

export const ImageWrapper = styled.div`
    height: ${ImageWrapperHeight}px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    opacity: 0.9;

    img {
        width: 100%;
    }
`;

export const StyledButtonPlay = styled(ButtonPlayOutline)`
    opacity: 0;
    pointer-events: none;
`;

export const InfoWrapper = styled.div<{ hasDescription?: boolean }>`
    position: absolute;
    width: 100%;
    height: 44px;
    padding: 10px 8px 10px 0px;

    ${props =>
        props.hasDescription
            ? css`
                  top: calc(90vw * (9 / 16));

                  ${MQ(Breakpoints.xs)} {
                      top: 0;
                      left: 300px;
                  }
              `
            : css`
                  top: ${ImageWrapperHeight}px;
              `}

    ${MQ(Breakpoints.s)} {
        top: ${ImageWrapperHeight}px;
        left: 0;
    }
`;

export const DescriptionWrapper = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90vw;
    font-size: 12px;
    line-height: 16px;
    clear: both;
    white-space: normal;
    color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.7)};

    ${MQ(Breakpoints.xs)} {
        width: 50vw;
    }

    ${MQ(Breakpoints.s)} {
        width: ${landscapeCardWidth}px;
    }
`;

export const Title = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    font-family: ${fontBold};
    width: 90vw;

    ${MQ(Breakpoints.xs)} {
        width: 50vw;
    }

    ${MQ(Breakpoints.s)} {
        width: 100%;
    }

    ${StyledSVG} {
        margin-right: 4px;
    }
`;

export const MetaData = styled.p<{ hasDescription?: boolean }>`
    color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.7)};
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    clear: both;
    margin-top: ${props => (props?.hasDescription ? '8px' : '0px')};
`;

export const CardLandscapeContainer = styled.div<{ defaultRenderSecondLine?: boolean; hasDescription?: boolean }>`
    ${MetaData} {
        transition: opacity 300ms ${animationType} 0ms;
        opacity: ${props => (props.defaultRenderSecondLine || props.hasDescription ? 1 : 0)};
    }

    ${props => {
        return !props.defaultRenderSecondLine || !props.hasDescription
            ? css`
                  &:hover {
                      ${MetaData} {
                          transition: opacity 300ms ${animationType} 200ms;
                          opacity: 1;
                      }
                  }
              `
            : css``;
    }};
`;

export const CardLandscapeWrapper = styled.div<{ defaultRenderSecondLine?: boolean }>`
    width: ${landscapeCardWidth}px;
    height: ${props => (props.defaultRenderSecondLine ? `${landscapeCardHoveredHeight}px` : `${landscapeCardHeight}px`)};
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    outline: 2px solid transparent;
    box-shadow: 0 4px 8px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.05)};

    ${StyledButtonPlay} {
        transition: opacity 300ms ${animationType} 0ms;
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            ${StyledButtonPlay} {
                transition: opacity 300ms ${animationType} 200ms;
                pointer-events: all;
                opacity: 1;
            }
        }

        ${props => {
            return !props.defaultRenderSecondLine
                ? css`
                      &:hover {
                          ${ImageWrapper} {
                              border: 2px solid ${props.theme.brandedConfig.white_color};
                              opacity: 1;
                          }
                      }
                  `
                : css``;
        }};
    }
`;

export const LandscapeImageWrapper = styled.div<{ isBackgroundBlur: boolean }>`
    width: 100%;
    height: 100%;
    background: #232438;

    ${props =>
        props.isBackgroundBlur
            ? css`
                  filter: blur(16px);
              `
            : ''};
`;

import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, fontNormal } from '../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';

export const ShowAllButton = styled.div`
    float: left;
    cursor: pointer;
    position: relative;
    font-family: ${fontNormal};

    ${MQ(Breakpoints.m)} {
        padding-top: 2px;
    }

    a {
        display: block;
        position: relative;
    }
`;

export const SliderWrapper = styled.div<{ isMobile: boolean }>`
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    margin-top: 40px;
    position: relative;
    text-align: left;

    .slide-container {
        white-space: nowrap;
    }
`;

export const SliderTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
    transition: opacity 0.5s ease-in-out;

    ${MQ(Breakpoints.s)} {
        padding-left: 40px;
        padding-right: 40px;
    }

    ${MQ(Breakpoints.m)} {
        padding-left: 48px;
        padding-right: 48px;
    }
`;

export const SliderTitleText = styled.h2<{ maxWidth?: string }>`
    float: left;
    font-size: 20px;
    max-width: ${props => props.maxWidth};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TilesContainer = styled.div`
    position: relative;
`;

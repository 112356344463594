import SVGInline from 'react-svg-inline';
import { css } from 'styled-components';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';
import { fontBold } from '../../style/styled-components/cssGlobalVariables';

function getTranslate(placement) {
    const pos = placement.split('-');
    const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1];
    const translateMap = {
        right: 'translate3d(120%, 0, 0)',
        left: 'translate3d(-120%, 0, 0)',
        bottom: 'translate3d(0, 120%, 0)',
        top: 'translate3d(0, -120%, 0)',
    };

    return translateMap[relevantPlacement];
}

export const toastStates = (placement: string) => ({
    entering: { transform: getTranslate(placement) },
    entered: { transform: 'translate3d(0,0,0)' },
    exiting: { transform: 'scale(0.66)', opacity: 0 },
    exited: { transform: getTranslate(placement) },
});

export const NotificationIcon = styled(SVGInline)`
    height: 48px;
    width: 48px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const NotificationIconWrapper = styled.div`
    position: relative;
`;

export const NotificationTitle = styled.p`
    font-family: ${fontBold};
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
    color: ${props => props.theme.brandedConfig.high_contrast};
`;

export const NotificationMessage = styled.p`
    font-size: 12px;
    color: ${props => props.theme.brandedConfig.high_contrast};
    line-height: 16px;
`;

export const NotificationMessageWrapper = styled.div`
    overflow: hidden;
`;

export const NotificationCloser = styled.div`
    display: none;
    align-self: start;

    ${MQ(Breakpoints.m)} {
        display: block;
    }

    .SVGInline {
        height: 26px;
        width: 26px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }
    }
`;

export const NotificationWrapper = styled.div<{ transitionDuration: number; isHidden?: boolean }>`
    max-width: 268px;
    background: #232438;
    border-radius: 4px;
    padding: 16px 20px;
    min-height: 50px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.25);
    transition: ${props => `transform ${props.transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${props.transitionDuration}ms`};

    ${props =>
        props.isHidden
            ? css`
                  position: absolute;
                  left: -10000000px;
              `
            : ''};

    ${MQ(Breakpoints.m)} {
        max-width: 340px;
    }
`;

import SVGInline from 'react-svg-inline';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { MQ_NOT_TOUCH } from '../../style/styled-components/cssMediaQueries';

export const CastIcon = styled(SVGInline)`
    width: auto;
`;

export const CastButtonWrapper = styled.div`
    cursor: pointer;
    float: right;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-visible {
        outline: var(--focus-outline);
    }

    ${MQ_NOT_TOUCH} {
        &:hover {
            opacity: 0.7;
        }
    }
`;

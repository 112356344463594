import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold } from '../../../style/styled-components/cssGlobalVariables';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { hexToRgba } from '../../../utils/fnColors';

export const landscapeCardWidthMobile = 328;
export const landscapeCardHeightMobile = 264;

export const landscapeCardWidth = 288;
export const landscapeCardHeight = 242;

export const landscapeCardHoveredWidth = 320;
export const landscapeCardHoveredHeight = 264;

export const landscapeInfoWrapperHeight = 80;

export const animationType = 'ease-in-out';
export const animationDuration = '150ms';
export const animationDelay = '150ms';

export const InfoWrapper = styled.div`
    width: 100%;
    height: ${landscapeInfoWrapperHeight}px;
    padding: 10px 8px;
    transition: height ${animationDuration} ${animationType} ${animationDelay},
        padding ${animationDuration} ${animationType} ${animationDelay};
`;

export const Title = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: ${fontBold};
    text-align: center;
`;

export const SubscriptionType = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    clear: both;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 8px;
`;

export const SubscriptionPricing = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    clear: both;
    text-align: center;
    margin-bottom: 8px;
`;

export const ImageWrapper = styled.div`
    height: calc(100% - 80px);
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
    }
`;

export const CardWrapper = styled.div`
    display: inline-block;
    position: relative;
    width: ${landscapeCardWidthMobile}px;
    height: ${landscapeCardHeightMobile}px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;

    &:focus-visible {
        outline: var(--focus-outline);
    }

    ${MQ(Breakpoints.s)} {
        width: ${landscapeCardWidth}px;
        height: ${landscapeCardHeight}px;
    }
`;

export const CardLandscapeWrapper = styled.div`
    background: ${props => props.theme.brandedConfig.low_contrast};
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    box-shadow: 0 4px 8px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.05)};
`;

/* eslint-disable no-nested-ternary */

import Scrollbar from 'react-scrollbars-custom';
import { css } from 'styled-components';
import { JumpPosition, JumpLabels } from './EpgTimeLine';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, fontNormal, maxZIndex } from '../../../style/styled-components/cssGlobalVariables';
import { MQ_TOUCH } from '../../../style/styled-components/cssMediaQueries';

const labelValueOffset = 17;

export const TimeLineWrapper = styled.div`
    float: left;
    width: calc(100% - 136px);
    height: 100%;
    position: relative;
`;

export const TimeLine = styled(Scrollbar)`
    width: 100%;
    margin-bottom: -45px;

    .ScrollbarsCustom-Wrapper {
        height: 100%;
    }

    .ScrollbarsCustom-TrackX {
        height: 30px !important;
        bottom: 2px !important;
        background: transparent !important;

        ${MQ_TOUCH} {
            display: none;
        }
    }
`;

export const EpgTimeLineInnerWrapper = styled.div<{ offset: number }>`
    height: 36px;
    width: 8640px;
    background: transparent;
    position: relative;
    margin-left: ${props => props.offset}px;
`;

export const TimeLabel = styled.span<{ width: number }>`
    display: inline-block;
    width: ${props => props.width + labelValueOffset}px;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: -${labelValueOffset}px;
    float: left;
    font-size: 14px;
    color: var(--color-primary);
    font-family: ${fontNormal};

    &.hidden {
        opacity: 0;
    }
`;

export const NowTimeIndicator = styled.div<{ left: number }>`
    left: ${props => props.left}px;
    width: 12px;
    height: 8px;
    position: absolute;
    bottom: 4px;

    path {
        fill: var(--color-live);
    }
`;

export const PrimeTimeIndicator = styled(NowTimeIndicator)`
    path {
        fill: var(--color-accent-dark);
    }
`;

export const Thumb = styled.div`
    height: 32px;
    cursor: pointer;
`;

export const JumpToTimeIndicator = styled.div<{ position: JumpPosition }>`
    position: absolute;
    bottom: 29px;
    height: 24px;
    cursor: pointer;
    z-index: ${maxZIndex};
    display: ${props => (props.position == null ? 'none' : 'block')};
    left: ${props => (props.position === 'past' ? '15px' : 'auto')};
    right: ${props => (props.position === 'past' ? 'auto' : '15px')};

    &:focus-visible {
        outline: var(--focus-outline);
    }
`;

export const TimeLineTimeLabels = styled.div`
    position: absolute;
    margin-bottom: -24px;
    width: 100%;
    height: 100%;
`;

export const JumpToTimeIndicatorLabel = styled.span<{ position: JumpPosition; isDayJumper?: boolean }>`
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    padding-left: ${props => (props.position === 'past' ? '3px' : '7px')};
    padding-right: ${props => (props.position === 'past' ? '7px' : '3px')};
    color: ${props => (props.isDayJumper ? props.theme.brandedConfig.low_contrast : props.theme.brandedConfig.high_contrast)};
    font-family: ${fontBold};
    position: relative;
    display: inline-block;
    width: 100%;
`;

export const JumpToTimeIcon = styled.span<{ position: JumpPosition; isNow: boolean; type: JumpLabels }>`
    transform: rotate(${props => (props.isNow ? (props.position === 'future' ? -180 : 0) : props.position === 'future' ? 0 : -180)}deg);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: inline-block;

    ${props =>
        props.type === 'now' &&
        css`
            background: var(--color-live-bg);
        `}
     ${props =>
         props.type === 'primeTime' &&
         css`
             background: var(--color-accent-dark);
         `}
     ${props =>
         props.type === 'nextDay' &&
         css`
             background: var(--color-light-background);
             color: var(--color-light-body);
         `}


    &::after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 3px;
        top: 3px;
        transform: rotate(45deg);
        width: 18px;
        height: 18px;

        ${props =>
            props.type === 'now' &&
            css`
                background: var(--color-live-bg);
                left: -8px;
            `}
         ${props =>
             props.type === 'primeTime' &&
             css`
                 background: var(--color-accent-dark);
                 right: -8px;
             `}
         ${props =>
             props.type === 'nextDay' &&
             css`
                 background: var(--color-light-background);
                 right: -8px;
             `}

        
        
    }
`;

import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { PlayerControl, PlayerIcon, PlayingAsset, PlayingMode, PropTypesAdditional, PropTypesPlayerUICommon } from '../../types/Player';
import { ChanelLogo, ChannelLogoFallback } from '../../components/PlayerUI/PlayerUI.css';
import { Picture } from '../../components/Picture/Picture';
import {
    castEnabled,
    getBroadcastPlayerActionComponents,
    getBroadcastPlayerMetaData,
    getBroadcastPlayerMoreOverlayMetaData,
    getBroadcastEmbeddedPlayerMetaData,
} from '../../utils/fnPlayerUI';
import { getLivePlayerAssetContentMarker } from '../../utils/fnContentMarker';
import { isLivePlayerAsset } from '../../utils/fnTypeGuards';
import { useConfig } from '../../providers/useConfig/ConfigContext';
import { useAssetReplayCapable } from '../useAssetReplayCapable/useAssetReplayCapable';
import { useBroadcastTimeCluster } from '../useBroadcastTimeCluster/useBroadcastTimeCluster';
import { getRecordingIconName } from '../../utils/fnData';
import { TimeClusterRecordingTypeMap } from '../../types/RecordingTypes';

export const useLivePlayerUI = (common: PropTypesPlayerUICommon, asset: PlayingAsset): PropTypesAdditional => {
    const { isReplayCapable } = useAssetReplayCapable(asset);
    const { config } = useConfig();
    const timeCluster = useBroadcastTimeCluster(isLivePlayerAsset(asset) ? asset : null);

    if (!isLivePlayerAsset(asset)) {
        return null;
    }

    const isTimeShiftEnabled = config && config.isFeatureActive('timeshift') && isReplayCapable;

    const {
        channelLogo,
        channelName,
        isRecorded,
        channelSubscribed,
        title,
        subtitle,
        recordingProductCode,
        isRecordingSubscribed,
        startTime,
        endTime,
        logicalChannelNumber,
        description,
        cast,
        originalLanguages,
    } = asset;

    const timeShiftEnableDuration = endTime - startTime < config.app_config.player_settings.timeshift_disable_duration_threshold;

    const getIcons = (): PlayerIcon[] => {
        const metaIcons: PlayerIcon[] = [];

        if (isReplayCapable) {
            metaIcons.push(PlayerIcon.CATCHUP);
        }

        if (isRecorded) {
            metaIcons.push(getRecordingIconName(TimeClusterRecordingTypeMap.LIVE) as PlayerIcon);
        }

        return metaIcons;
    };

    const getControls = (): PlayerControl[] => {
        const controls: PlayerControl[] = [PlayerControl.FULL_SCREEN, PlayerControl.VOLUME];

        if (castEnabled()) {
            controls.push(PlayerControl.CAST);
        }

        controls.push(PlayerControl.INFO);

        controls.push(PlayerControl.SUBTITLE);

        if (isTimeShiftEnabled && timeShiftEnableDuration) {
            if (!isMobile && !isTablet) {
                controls.push(PlayerControl.JUMP_BACK);
                controls.push(PlayerControl.JUMP_FORWARD);
            }

            if (common.playingMode === PlayingMode.TIME_SHIFT) {
                controls.push(PlayerControl.GO_TO_LIVE);
            }

            controls.push(PlayerControl.START_OVER);
        }

        controls.push(PlayerControl.PLAY_PAUSE);

        if (channelSubscribed && recordingProductCode && isRecordingSubscribed && config && config.isFeatureActive('pvr')) {
            if (timeCluster !== 'PAST' || (timeCluster === 'PAST' && isRecorded)) {
                controls.push(PlayerControl.RECORDING);
            }
        }

        return controls;
    };

    const getDisabledControls = (): PlayerControl[] => {
        const controls: PlayerControl[] = [];

        if (common.playingMode === PlayingMode.NORMAL) {
            controls.push(PlayerControl.JUMP_FORWARD);
        }

        return controls;
    };

    const controls = getControls();
    const { actionPopup, mobileOverlayActionHandlers, actionLabels } = getBroadcastPlayerActionComponents(controls, asset);

    return {
        logo: (channelLogo || channelName) && (
            <ChanelLogo>
                <Picture src={channelLogo} hPosition={'left'} vPosition={'center'} fit={'scale-down'}>
                    {channelName && <ChannelLogoFallback>{channelName}</ChannelLogoFallback>}
                </Picture>
            </ChanelLogo>
        ),
        logicalChannelNumber,
        metaData: getBroadcastPlayerMetaData(asset),
        embeddedPlayerMetadata: getBroadcastEmbeddedPlayerMetaData(asset),
        moreOverlayMetaData: getBroadcastPlayerMoreOverlayMetaData(asset),
        contentMarker: getLivePlayerAssetContentMarker(asset, timeCluster),
        metaIcons: getIcons(),
        controls,
        disabledControls: getDisabledControls(),
        seekEnabled: isTimeShiftEnabled && timeShiftEnableDuration,
        title,
        subtitle,
        actionPopup,
        actionLabels,
        mobileOverlayActionHandlers,
        description,
        cast,
        originalLanguages,
    };
};

import { hexToRgba } from 'utils/fnColors';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';

export const ToggleLabel = styled.label<{ ignoreClick?: boolean }>`
    position: relative;
    display: inline-block;
    width: 56px;
    height: 32px;
    pointer-events: ${props => (props.ignoreClick ? 'none' : 'auto')};
`;

export const ToggleSlider = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => hexToRgba(props.theme.brandedConfig.high_contrast, 0.2)};
    transition: 0.4s;
    --webkit-transition: 0.4s;
    border-radius: 32px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        height: 28px;
        width: 28px;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        background-color: ${props => props.theme.brandedConfig.high_contrast};
        box-shadow: 0px 2px 4px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        transition: 0.4s;
        --webkit-transition: 0.4s;
    }
`;

export const ToggleCheckBox = styled.input`
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + ${ToggleSlider} {
        background-color: ${props => props.theme.brandedConfig.primary};
    }

    &:checked + ${ToggleSlider}:before {
        transform: translateX(24px);
    }
`;

import React from 'react';
import SVGInline from 'react-svg-inline';
import icons from '../../../style';
import translate from '../../../utils/fnTranslate';
import { AriaHint, Option, OptionButton, OptionIcon, OptionName, OptionNameSub, OptionRightSide } from './PageSettingsCommon.css';

interface OptionFocusableProps {
    title: string;
    subtitle?: string;
    icon?: string;
    actionComponent?: any;
    isExternalLink?: boolean;
    dataTestId?: string;
    isDisabled?: boolean;
    ariaHint?: string;
    role?: string;
    onClick?: () => void;
}

const OptionFocusable: React.FC<OptionFocusableProps> = ({
    title,
    subtitle,
    icon,
    actionComponent,
    isExternalLink,
    dataTestId,
    isDisabled,
    ariaHint,
    role,
    onClick,
}) => {
    return (
        <Option
            data-test-id={`header-account-settingsbuttons-${dataTestId}`}
            tabIndex={isDisabled ? -1 : 0}
            onClick={!isDisabled && onClick}
            clickable={!isDisabled}
            role={role || 'link'}
            reducedHeight={!!subtitle}
            isDisabled={isDisabled}
        >
            {icon && (
                <OptionIcon>
                    <SVGInline svg={icon} />
                </OptionIcon>
            )}

            <OptionName data-test-id={`header-account-settingsoptions-${title}`}>
                {translate(title)}
                {subtitle && <OptionNameSub aria-hidden>{subtitle}</OptionNameSub>}
                {isExternalLink && <AriaHint>{translate('ARIA_HINT_NEW_TAB')}</AriaHint>}
                {ariaHint && <AriaHint>{ariaHint}</AriaHint>}
            </OptionName>

            <OptionRightSide>
                {actionComponent || (
                    <OptionButton isExternal={isExternalLink}>
                        <SVGInline svg={isExternalLink ? icons.externalLink : icons.rightArrowIcon} />
                    </OptionButton>
                )}
            </OptionRightSide>
        </Option>
    );
};

export default OptionFocusable;

import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { css } from 'styled-components';
import { CircleCardImageContainer } from 'components/Cards/CardCircle/CardCircle.css';
import { ImageWrapper } from 'components/Cards/CardCollection/CardCollection.css';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { TrackingElementPropTypes } from '../../types/Tracking';
import { useTracking } from '../../providers/useTracking/TrackingContext';
import { trackingClickHandler } from '../../utils/fnTracking';

type FocusVisibleTypes = 'outline' | 'underline';
type ExtraProps = { to: string; useAbsolutePath?: boolean; visibleFocus?: FocusVisibleTypes; ignoreFocus?: boolean };
export type TrackableLinkProps = LinkProps & TrackingElementPropTypes & ExtraProps;

const StyledLinkTrackable = styled(Link)<{ focus?: FocusVisibleTypes }>`
    ${props =>
        props.focus === 'outline'
            ? css`
                  &:focus-visible {
                      outline: var(--focus-outline);
                      border-radius: 5px;
                      transition: none !important;
                  }
                  &:focus-visible {
                      ${CircleCardImageContainer} {
                          outline: var(--focus-outline);
                          border-radius: 50%;
                      }
                      ${ImageWrapper} {
                          outline: var(--focus-outline);
                          border-radius: 5px;
                      }
                      .focus-image-wrapper {
                          outline: var(--focus-outline);
                          opacity: 1;
                      }
                  }
              `
            : ``}
    ${props =>
        props.focus === 'underline'
            ? css`
                  &:focus-visible {
                      border-bottom: 2px solid #ccc;
                      margin-bottom: -2px;
                      transition: none !important;
                  }
              `
            : ``}
`;

export const LinkTrackable = React.forwardRef<HTMLAnchorElement, TrackableLinkProps>((props, ref) => {
    const track = useTracking();
    const location = useLocation();

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        trackingClickHandler(
            event,
            () => {
                if (props.to && props.to.indexOf('http') > -1) {
                    event.preventDefault();
                    event.stopPropagation();

                    if (props.target) {
                        const win = window.open(props.to, props.target || '');
                        win?.focus();
                    } else {
                        window.location.href = props.to;
                    }
                }
            },
            props,
            track
        );
    };

    const linkProps = { to: '', ...props };

    if (props.useAbsolutePath) {
        const currentPath = location.pathname;

        if (linkProps.to.startsWith(currentPath)) {
            linkProps.to = linkProps.to.slice(currentPath.length);
        }

        if (!linkProps.to.startsWith('/')) {
            linkProps.to = `/${linkProps.to}`;
        }
    }

    if (linkProps.to === null) {
        linkProps.to = '';
    }

    const { visibleFocus, ignoreFocus, ...pureLinkProps } = linkProps;
    delete pureLinkProps.useAbsolutePath;

    return (
        <StyledLinkTrackable
            {...pureLinkProps}
            focus={visibleFocus}
            ref={ref}
            tabIndex={ignoreFocus ? -1 : 0}
            onClick={event => {
                handleLinkClick(event);

                if (linkProps.to == null || linkProps.to === '') {
                    event.preventDefault();
                }

                if (linkProps.onClick) {
                    linkProps.onClick(event);
                }
            }}
        >
            {linkProps.children}
        </StyledLinkTrackable>
    );
});

LinkTrackable.displayName = 'LinkTrackable';

export default LinkTrackable;

import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import SVGInline from 'react-svg-inline';
import { createPortal } from 'react-dom';
import { useKeyboardShortcuts } from 'hooks/useKeyboardShortcuts/useKeyboardShortcuts';
import {
    ButtonContainer,
    ImageWrapper,
    ImageWrapperInner,
    InfoWrapper,
    MetaContainer,
    MetaTitle,
    OptionsContent,
    StyledButton,
    TermsAndConditions,
} from './PurchaseOptionConfirmation.css';
import icons from '../../style';
import { usePurchase } from '../../providers/usePurchase/PurchaseContext';
import translate from '../../utils/fnTranslate';
import { Marker, ResolutionType } from '../../types/Asset';
import { Offer } from '../../types/Entitlement';
import { PictureWithFallback } from '../Picture/PictureWithFallback';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import fallbackImageLandscape from '../../assets/images/landscape_fallback.png';
import fallbackImagePortrait from '../../assets/images/portrait_fallback.png';
import { buildRentalPeriod, secondsToTime } from '../../utils/fnDate';
import { withNoScroll } from '../../hooks/withNoScroll/withNoScroll';
import {
    AudioSubtitleBlock,
    ContentMarker,
    ModalCloseButton,
    ModalNavigation,
    ModalWrapper,
} from '../../style/styled-components/reusable.css';
import { isEpisode } from '../../utils/fnTypeGuards';
import { getVodContentMarker } from '../../utils/fnContentMarker';
import { getCurrencySymbol, getLanguage, secondsToTimeString } from '../../utils/fnData';

const PurchaseConfirmation: FC = () => {
    const { cancel, selectPurchaseOffer, asset, purchasing, isBuyFlow } = usePurchase();
    const focusRef = useRef(null);

    useEffect(() => {
        if (focusRef?.current) {
            focusRef.current.focus();
        }
    }, [focusRef]);

    const onClose = () => {
        cancel();
    };

    const onOfferSelected = (offer: Offer) => {
        selectPurchaseOffer(offer);
    };

    const getRentDuration = (): ReactNode => {
        if (isBuyFlow) {
            return null;
        }

        const rentDurationInMinutes = asset?.rentOffers?.[0]?.rentalPeriodInMinutes ?? -1;

        if (rentDurationInMinutes === -1) return null;
        return (
            <InfoWrapper
                aria-label={translate('DETAIL_RENTAL_TIME_TEMPLATE', {
                    RENTAL_DURATION: secondsToTimeString(rentDurationInMinutes * 60),
                }).slice(0, -1)}
                key={'rent-for'}
                tabIndex={0}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: translate('DETAIL_RENTAL_TIME_TEMPLATE', {
                            RENTAL_DURATION: `<span class="rent-duration">${buildRentalPeriod(rentDurationInMinutes)}</span>`,
                        }),
                    }}
                />
            </InfoWrapper>
        );
    };

    const getButtonText = (offer: Offer, isEpisodeAsset): string => {
        const currencySymbol = getCurrencySymbol(offer.currency);

        if (isEpisodeAsset) {
            if (offer.offerValue === 0) {
                return translate('RENT_EPISODE_FOR_FREE_BUTTON');
            }

            return translate(offer.isBuyOffer ? 'BUY_EPISODE_FOR_PRICE_BUTTON' : 'RENT_EPISODE_FOR_PRICE_BUTTON', {
                price: `${offer.offerValue}${currencySymbol}`,
            });
        }
        if (offer.offerValue === 0) {
            return translate('RENT_FOR_FREE_BUTTON');
        }

        let buttonKey;

        switch (offer.resolution) {
            case ResolutionType.SD:
                buttonKey = offer.isBuyOffer ? 'BUY_SD_FOR_PRICE_BUTTON' : 'RENT_SD_FOR_PRICE_BUTTON';
                break;
            case ResolutionType.HD:
                buttonKey = offer.isBuyOffer ? 'BUY_HD_FOR_PRICE_BUTTON' : 'RENT_HD_FOR_PRICE_BUTTON';
                break;
            case ResolutionType['4K']:
                buttonKey = offer.isBuyOffer ? 'BUY_4K_FOR_PRICE_BUTTON' : 'RENT_4K_FOR_PRICE_BUTTON';
                break;
            default:
                buttonKey = offer.isBuyOffer ? 'BUY_FOR_PRICE_BUTTON' : 'RENT_FOR_PRICE_BUTTON';
                break;
        }

        return translate(buttonKey, { price: `${offer.offerValue}${currencySymbol}` });
    };

    const primaryClickListener = useCallback(() => {
        const offers = isBuyFlow ? asset.buyOffers : asset.rentOffers;

        if (offers && offers.length > 0) {
            const primaryOffer = offers.sort((a, b) => b.offerValue - a.offerValue)[0];
            onOfferSelected(primaryOffer);
        }
    }, [isBuyFlow, asset.buyOffers, asset.rentOffers, onOfferSelected]);

    useKeyboardShortcuts({
        escape: onClose,
        enter: primaryClickListener,
    });

    const getButtons = (): ReactNode => {
        const offers = isBuyFlow ? asset.buyOffers : asset.rentOffers;

        if (offers?.length) {
            return offers
                .sort((a, b) => b.offerValue - a.offerValue)
                .sort((a, b) => {
                    if (!b.offerValue && a.offerValue) {
                        return 1;
                    }
                    return -1;
                })
                .map((offer, index) => {
                    const clickListener = () => onOfferSelected(offer);
                    const buttonText = getButtonText(offer, isEpisode(asset));

                    return (
                        <StyledButton
                            key={`rent-${offer.offerId}-${index}`}
                            label={buttonText}
                            onClick={clickListener}
                            isPrimary={index === 0}
                            data-event-name={'click'}
                            data-track-id={'button_rent'}
                            data-button-title={buttonText}
                            data-page-name={'RentConfirmation'}
                        />
                    );
                });
        }

        return null;
    };

    const getAudios = (): ReactNode => {
        const offers = isBuyFlow ? asset.buyOffers : asset.rentOffers;

        if (offers.length === 0) return null;
        const { audios } = offers[0];

        if (audios.length === 0) return null;

        return (
            <AudioSubtitleBlock tabIndex={0}>
                {`${translate('DETAIL_AUDIO_LANGUAGE')} `}
                {audios.map(audio => getLanguage(audio, translate(`LANGUAGE_${audio.toUpperCase()}`))).join(', ')}
            </AudioSubtitleBlock>
        );
    };

    const getSubtitles = (): ReactNode => {
        const offers = isBuyFlow ? asset.buyOffers : asset.rentOffers;

        if (offers.length === 0) return null;
        const { subtitles } = offers[0];

        return (
            <AudioSubtitleBlock tabIndex={0}>
                {`${translate('DETAIL_SUBTITLES')} `}
                {subtitles.length > 0
                    ? subtitles.map(audio => getLanguage(audio, translate(`LANGUAGE_${audio.toUpperCase()}`))).join(', ')
                    : translate('SUBTITLES_NONE')}
            </AudioSubtitleBlock>
        );
    };

    const getContentMarker = (): ReactNode => {
        const { price, firstAvailability } = asset;
        const contentMarker: Marker = getVodContentMarker(price, firstAvailability);

        return (
            contentMarker && (
                <ContentMarker marker={contentMarker} tabIndex={0}>
                    {contentMarker.value}
                </ContentMarker>
            )
        );
    };

    useEffect(() => {
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
        const root = document.getElementById('root');
        root.setAttribute('inert', '');

        return () => {
            root.removeAttribute('inert');
        };
    }, []);

    return createPortal(
        <ModalWrapper data-test-id={'header-rentals-rent-modal'} opacity={1} aria-modal>
            <ModalNavigation>
                <ModalCloseButton data-test-id={'header-rentals-modal-close-button'} onClick={onClose} tabIndex={0}>
                    <SVGInline svg={icons.closeIcon} />
                </ModalCloseButton>
            </ModalNavigation>
            <OptionsContent>
                <ImageWrapper>
                    <ImageWrapperInner>
                        <PictureWithFallback
                            src={asset.image}
                            fit={'cover'}
                            fallbackImage={isEpisode(asset) ? fallbackImageLandscape : fallbackImagePortrait}
                        />
                        {getContentMarker()}
                    </ImageWrapperInner>
                </ImageWrapper>
                <MetaContainer>
                    <MetaTitle data-test-id={'header-rentals-modal-title'} tabIndex={0} ref={focusRef}>{`${translate(
                        isBuyFlow ? 'SCREEN_PURCHASE_CONFIRMATION_HEADER' : 'SCREEN_RENT_CONFIRMATION_HEADER',
                        {
                            name: `"${asset.title}"`,
                        }
                    )}`}</MetaTitle>
                    {getContentMarker()}
                    {getAudios()}
                    {getSubtitles()}
                    <InfoWrapper aria-label={secondsToTimeString(asset.duration)} key={'duration'} tabIndex={0}>
                        {secondsToTime(asset.duration)}
                    </InfoWrapper>
                    <TermsAndConditions tabIndex={0}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: translate(isBuyFlow ? 'SCREEN_PURCHASE_CONFIRMATION_TERMS' : 'SCREEN_RENT_CONFIRMATION_TERMS', {
                                    title: `"${asset.title}"`,
                                }).replace(/\n/g, '<div class="spacer"></div>'),
                            }}
                        />
                    </TermsAndConditions>
                    {getRentDuration()}
                    <ButtonContainer>{getButtons()}</ButtonContainer>
                </MetaContainer>
            </OptionsContent>
            <LoadingIndicator overlay={true} isLoading={purchasing} />
        </ModalWrapper>,
        document.getElementById('modal-root')
    );
};

export const PurchaseOptionConfirmation = withNoScroll(PurchaseConfirmation);

import { format } from 'date-fns';
import React, { ReactNode } from 'react';
import { isChrome, isIOS, isMobile, isTablet } from 'react-device-detect';
import { formatDate } from './fnDate';
import { TIME_FORMAT_HH_MM } from './fnParser';
import { BroadcastPlayerAsset, PlayerControl, PlayingAsset, RecordingPlayerAssetInfo } from '../types/Player';
import { PlayerRecordingActionWindowMobile } from '../components/PlayerRecordingActionWindow/PlayerRecordingActionWindow';
import { StyledSVGInline } from '../components/PlayerUI/PlayerUI.css';
import icons from '../style';
import { PlayerTrackSelector, PlayerTrackSelectorMobile } from '../components/PlayerTrackSelector/PlayerTrackSelector';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { isCatchupPlayerAsset, isLivePlayerAsset, isRecordingPlayerAsset, isVodPlayerAsset } from './fnTypeGuards';
import { CastAssetType } from '../types/Cast';
import { AspectRatio } from '../types/Common';

export const getBroadcastPlayerMetaData = (asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo) => {
    if (!asset) return [];

    const { startTime, endTime, releaseYear, countries, genres } = asset;
    const metaData = [];

    if (startTime && endTime) {
        metaData.push(formatDate(new Date(startTime)));
        metaData.push(`${format(startTime, TIME_FORMAT_HH_MM)} - ${format(endTime, TIME_FORMAT_HH_MM)}`);
    }

    if (releaseYear || countries.length) {
        metaData.push(`${countries?.slice(0, 2).join(',')} ${releaseYear || ''}`);
    }

    if (genres && genres.length) {
        metaData.push(
            genres
                ?.map(genre => genre.Value)
                .slice(0, 2)
                .join(', ')
        );
    }

    return metaData;
};

export const getBroadcastEmbeddedPlayerMetaData = (asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo) => {
    if (!asset) return [];

    const { startTime, endTime } = asset;
    const metaData = [];

    if (startTime && endTime) {
        metaData.push(formatDate(new Date(startTime)));
        metaData.push(`${format(startTime, TIME_FORMAT_HH_MM)} - ${format(endTime, TIME_FORMAT_HH_MM)}`);
    }

    return metaData;
};

export const getBroadcastPlayerMoreOverlayMetaData = asset => {
    const metaData = {
        firstLine: [],
        secondLine: [],
    };

    if (!asset) return metaData;

    const { startTime, endTime, releaseYear, countries, genres, channelName } = asset;

    if (channelName) {
        metaData.firstLine.push(channelName);
    }

    if (startTime && endTime) {
        metaData.firstLine.push(formatDate(new Date(startTime)));
        metaData.firstLine.push(`${format(startTime, TIME_FORMAT_HH_MM)} - ${format(endTime, TIME_FORMAT_HH_MM)}`);
    }

    if (releaseYear || countries.length) {
        metaData.secondLine.push(`${countries?.slice(0, 2).join(',')} ${releaseYear || ''}`);
    }

    if (genres && genres.length) {
        metaData.secondLine.push(
            genres
                ?.map(genre => genre.Value)
                .slice(0, 2)
                .join(', ')
        );
    }

    return metaData;
};

export const getTrailerVODPlayerControls = (hasTracks: boolean, canStartOver: boolean, isCastEnabled: boolean) => {
    const controls: PlayerControl[] = [PlayerControl.FULL_SCREEN, PlayerControl.VOLUME];

    if (isCastEnabled) {
        controls.push(PlayerControl.CAST);
    }

    controls.push(PlayerControl.INFO);

    controls.push(PlayerControl.SUBTITLE);

    if (canStartOver) {
        controls.push(PlayerControl.START_OVER);
    }

    if (!isMobile && !isTablet) {
        controls.push(PlayerControl.JUMP_BACK);
        controls.push(PlayerControl.JUMP_FORWARD);
    }
    controls.push(PlayerControl.PLAY_PAUSE);

    return controls;
};

export const getBroadcastPlayerActionComponents = (controls: PlayerControl[], asset: BroadcastPlayerAsset | RecordingPlayerAssetInfo) => {
    const mobileOverlayActionHandlers = [];
    const isRecordingInPast = asset?.endTime ? new Date().getTime() > asset?.endTime : false;
    const actionPopup: {
        [key in PlayerControl]?: ReactNode;
    } = {};
    const actionLabels: {
        [key in PlayerControl]?: string;
    } = {};

    if (controls.includes(PlayerControl.RECORDING)) {
        mobileOverlayActionHandlers.push(<PlayerRecordingActionWindowMobile asset={asset} key={PlayerControl.RECORDING} />);
        if (isRecordingInPast) {
            actionLabels[PlayerControl.RECORDING] = 'DELETE_RECORDING_BUTTON';
        } else {
            actionLabels[PlayerControl.RECORDING] = asset?.isRecorded ? 'STOP_RECORDING_BUTTON' : 'SCREEN_PLAYER_RECORD_BUTTON';
        }
    }

    if (controls.includes(PlayerControl.SUBTITLE)) {
        mobileOverlayActionHandlers.push(<PlayerTrackSelectorMobile key={PlayerControl.SUBTITLE} />);
        actionPopup[PlayerControl.SUBTITLE] = <PlayerTrackSelector triggerButton={<StyledSVGInline svg={icons.icoSettings} />} />;
    }

    return {
        mobileOverlayActionHandlers,
        actionPopup,
        actionLabels,
    };
};

export const getTrailerVODPlayerActionComponents = (controls: PlayerControl[]) => {
    const mobileOverlayActionHandlers = [];
    const actionPopup: {
        [key in PlayerControl]?: ReactNode;
    } = {};

    if (controls.includes(PlayerControl.SUBTITLE)) {
        mobileOverlayActionHandlers.push(<PlayerTrackSelectorMobile key={PlayerControl.SUBTITLE} />);
        actionPopup[PlayerControl.SUBTITLE] = <PlayerTrackSelector triggerButton={<StyledSVGInline svg={icons.icoSettings} />} />;
    }

    return {
        mobileOverlayActionHandlers,
        actionPopup,
    };
};

export const castAssetType = (asset: PlayingAsset): CastAssetType => {
    if (isLivePlayerAsset(asset)) {
        return 'LIVE_TV';
    }

    if (isCatchupPlayerAsset(asset)) {
        return 'CATCHUP';
    }

    if (isRecordingPlayerAsset(asset)) {
        return 'RECORDING';
    }

    if (isVodPlayerAsset(asset)) {
        return 'VOD';
    }

    return 'TRAILER';
};

const castCapable = (): boolean => isChrome && !isIOS;

export const castEnabled = () => castCapable() && ApplicationConfig && ApplicationConfig.isFeatureActive('google_cast');

export const setVideoAspectRatio = (video: HTMLVideoElement, videoAspectRatio: AspectRatio, wrapper?: HTMLDivElement) => {
    if (!video) {
        return;
    }

    const viewportWidth = wrapper?.clientWidth ?? window.innerWidth;
    const viewportHeight = wrapper?.clientHeight ?? window.innerHeight;
    const neededAspectRatio = videoAspectRatio.width / videoAspectRatio.height;
    const screenRatio = viewportWidth / viewportHeight;
    const applyCoeff = screenRatio / neededAspectRatio;
    if (applyCoeff >= 1) {
        // dimension.width >= dimension.height
        video.height = viewportHeight;
        video.width = viewportHeight * neededAspectRatio;
    } else {
        // dimension.width < dimension.height
        video.width = viewportWidth;
        video.height = viewportWidth / neededAspectRatio;
    }
};

import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { StyledSVGInline } from '../EpgModule/EpgModule.css';
import { Breakpoints, MQ } from '../../../style/styled-components/cssMediaQueries';
import { levelingZIndex, fontBold, maxZIndex } from '../../../style/styled-components/cssGlobalVariables';
import { hexToRgba } from '../../../utils/fnColors';
import { DropDownLabelWrapper, FilterSelector } from '../EpgFilter/EpgFilter.css';
import { DropdownContent, DropdownWrapper } from '../../DropdownMenu/DropdownMenu.css';

const channelListWidth = 96;

export const EpgWrapper = styled.div<{ isIOS: boolean }>`
    // this hack needs to be applied because of the native bottom menu bar on the iOS devices
    // if you remove it please also remove it from the PageScrollerWrapper also
    margin-bottom: ${props => (props.isIOS ? '-50px' : '-35px')};

    ${MQ(Breakpoints.m)} {
        padding: 0 72px;
    }
`;

export const SearchFilterWrapper = styled.div`
    float: right;
    width: 290px;
    height: 40px;
    display: none;
    margin-bottom: 6px;

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const FilterTimeLineWrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 1;
    clear: both;
    padding-bottom: 14px;
    padding-top: 14px;
`;

export const TimeSelectorWrapper = styled.div`
    height: 50px;
    text-align: left;
    line-height: 21px;
    width: 100%;
    position: relative;
    margin-bottom: -40px;

    ${FilterSelector} {
        float: left;
        height: 24px;
        width: 136px;

        ${DropdownContent} {
            ${MQ(Breakpoints.s)} {
                top: -250%;
                left: 100%;
            }

            ${MQ(Breakpoints.m)} {
                top: 100%;
                left: 0;
            }

            &:before {
                right: 24px;
            }
        }
    }
`;

export const ChanelListProgramGridWrapper = styled.div<{ height: number }>`
    width: 100%;
    position: relative;
    height: ${props => props.height}px;

    .ScrollbarsCustom-Wrapper {
        width: 100%;

        .ScrollbarsCustom-ThumbY {
            background: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.4)} !important;
        }

        .ScrollbarsCustom-TrackY {
            right: 0 !important;
            width: 7px !important;

            &:hover {
                width: 15px !important;
            }
        }
    }
`;

const NavigationArrow = styled.div`
    position: absolute;
    top: calc(50% - 25px);
    width: 32px;
    height: 32px;
    z-index: ${levelingZIndex};
    cursor: pointer;
    display: none;
    &:active {
        opacity: 0.5;
    }

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

export const LeftNavigationArrow = styled(NavigationArrow)`
    left: -42px;
`;

export const RightNavigationArrow = styled(NavigationArrow)`
    right: -42px;

    ${StyledSVGInline} {
        text-align: right;
    }
`;

export const GridContainer = styled.div<{ height: number }>`
    float: left;
    width: calc(100% - 145px);
    height: ${props => props.height}px;
    background-color: ${props => props.theme.brandedConfig.low_contrast};
`;

export const GridInner = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: scroll;

    /* Hide horizontal scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide horizontal scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const EpgGrid = styled.div<{ pixelsPerMinute: number }>`
    width: ${props => props.pixelsPerMinute * 60 * 24 + 1}px;
    position: relative;
    height: 100%;
    overflow: hidden;
`;

export const EpgRowHolder = styled.div<{ top: number }>`
    transform: translateY(${props => props.top}px);
    position: absolute;
    left: 0;
`;

export const PastOverlay = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 2px);
    pointer-events: none;
    z-index: ${levelingZIndex};
    border-right-width: 1px;
    border-right-style: solid;

    border-color: var(--color-live);

    &:after {
        content: ' ';
        position: absolute;
        height: 2px;
        top: 0;
        left: 0;
        width: 100%;
    }
`;

export const ChannelListContainer = styled.div<{ height: number }>`
    float: left;
    width: ${channelListWidth}px;
    margin-right: 4px;
    height: ${props => props.height}px;
    background-color: var(--color-dark-foreground);
`;

export const ChannelListInnerContainer = styled.div<{ top: number }>`
    width: 100%;
    height: 100%;
    transform: translateY(${props => props.top}px);
    position: absolute;
    left: 0;
`;

export const FavoriteIconWrapper = styled.div<{ isFavorite: boolean }>`
    opacity: ${props => (props.isFavorite ? '1' : '0')};

    &:focus-visible {
        border-radius: 50%;
        border: 2px solid #fff;
    }
`;

export const ChannelItemContainer = styled.a<{ rowHeight: number; isMobile: boolean; isSelected?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    height: ${props => props.rowHeight}px;
    opacity: 1;
    position: relative;
    width: ${channelListWidth}px;
    z-index: ${levelingZIndex};
    cursor: pointer;

    &:after {
        content: '';
        background-color: ${props =>
            props.isSelected ? props.theme.brandedConfig.epg_live_program_color : props.theme.brandedConfig.low_contrast};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        margin: 2px 0;
    }

    &:before {
        content: '';
        background-color: ${props => props.theme.brandedConfig.background};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:hover {
        &:before {
            opacity: 1;
        }

        ${FavoriteIconWrapper} {
            opacity: 1;
        }
    }

    .img-placeholder {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
`;

export const ChannelPlaceholderImage = styled(StyledSVGInline)`
    margin: auto;
    width: 48px;
    opacity: 0.2;
    padding: 6px 0;
`;

export const ChannelImageHolder = styled.div`
    max-width: 80%;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 76px;
    height: 32px;
    z-index: 1;
    margin-left: 18px;

    img {
        max-width: 80%;
        object-position: center;
    }
`;

export const ChannelFallbackIcon = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
`;

export const ChannelTitleWrapper = styled.span`
    height: 32px;
    width: 76px;
    display: flex;
    align-items: center;
`;

export const ChannelTitle = styled.span`
    font-size: 12px;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    position: absolute;
`;

export const ChannelText = styled.div`
    position: absolute;
    top: 5px;
    right: 3px;
    color: var(--color-dark-body);
    font-size: 12px;
    z-index: 1;

    svg {
        width: 14px;
        height: 14px;
    }
`;

export const EpgNoResult = styled.p`
    width: 100%;
    padding: 164px 0 0 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-dark-body);
    font-family: ${fontBold};
`;

export const EpgChannelFilter = styled.div`
    position: fixed;
    bottom: 64px;
    right: 16px;
    display: flex;
    gap: 16px;
    z-index: ${maxZIndex + 1};

    ${MQ(Breakpoints.m)} {
        position: static;
    }

    ${FilterSelector} {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: ${props => props.theme.brandedConfig.primary};

        ${DropdownWrapper} {
            height: 100%;

            ${MQ(Breakpoints.m)} {
                height: auto;
            }
        }

        ${DropdownContent} {
            min-width: 168px;

            ${MQ(Breakpoints.s)} {
                bottom: 0;
                right: calc(100% + 8px);
                top: unset;
                left: unset;
            }

            ${MQ(Breakpoints.m)} {
                right: unset;
                bottom: unset;
            }
        }

        ${DropDownLabelWrapper} {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;

            /* at 200% zoom the EPG icons go loopy (bottom right blue circles) */

            ${MQ(Breakpoints.m)} {
                position: static;
                transform: none;
                top: auto;
                left: auto;
                width: auto;
                height: auto;
            }
        }

        ${MQ(Breakpoints.m)} {
            border: 1px solid var(--color-dark-body-50);
            height: 40px;
            width: 170px;
            border-radius: 4px;
            padding: 10px 0;
            background-color: transparent;
        }
    }
`;

import React, { FC, useEffect } from 'react';
import { ToggleButton } from 'components/Buttons/ToggleButton/ToggleButton';
import { usePlayer } from 'providers/player/PlayerContext';
import { useAuth } from 'providers/useAuth/AuthContext';
import { PageWrapper, Section, Subtitle } from '../PageSettingsCommon.css';
import translate from '../../../../utils/fnTranslate';
import { useTrackingScreen } from '../../../../providers/useTracking/TrackingContext';
import { useApp } from '../../../../providers/useApp/AppContext';
import { HelmetWrapper } from '../../../HelmetWrapper/HelmetWrapper';
import { getHelmetData } from '../../../../utils/fnHelmet';
import OptionFocusable from '../OptionFocusable';

const PageAccessibility: FC = () => {
    const { setPageTitle } = useApp();
    const trackScreen = useTrackingScreen(() => true);
    const { accessibility, setAccessibility } = usePlayer();
    // const savedKeyboardState = localStorage.getItem('a11y_alwaysUseKeyboardNav');
    // const [alwaysUseKeyboardNav, setAlwaysUseKeyboardNav] = useState(savedKeyboardState === 'true');
    const { isGuest } = useAuth();

    /* NOTE: deprecated for now, maybe useful in EPG at one point
    const checkboxHandler = () => {
        localStorage.setItem('a11y_alwaysUseKeyboardNav', `${!alwaysUseKeyboardNav}`);
        setAlwaysUseKeyboardNav(!alwaysUseKeyboardNav);
    };
    */

    useEffect(() => {
        const title = translate('SETTINGS_ACCESSIBILITY');

        setPageTitle(title);
        trackScreen(title);
    }, []);

    return (
        <HelmetWrapper {...getHelmetData(translate('SETTINGS_ACCESSIBILITY'))}>
            <PageWrapper>
                {/* <Section aria-label={translate('SETTINGS_ACCESSIBILITY_NAVIGATION')}>
                    <Subtitle hideOnSmallRes>{translate('SETTINGS_ACCESSIBILITY_NAVIGATION')}</Subtitle>

                    <OptionFocusable
                        title={translate('SETTINGS_KEYBOARD_ACCESSIBILITY')}
                        actionComponent={<ToggleButton checked={alwaysUseKeyboardNav} ignoreClick />}
                        aria-checked={alwaysUseKeyboardNav}
                        onClick={checkboxHandler}
                        role="switch"
                    />
                </Section> */}

                {!isGuest && (
                    <Section aria-label={translate('SETTINGS_ACCESSIBILITY_AUDIO')}>
                        <Subtitle hideOnSmallRes>{translate('SETTINGS_ACCESSIBILITY_AUDIO')}</Subtitle>

                        <OptionFocusable
                            title={translate('SETTINGS_LANGUAGE_ACCESSIBILITY_HINT')}
                            actionComponent={<ToggleButton checked={accessibility} ignoreClick />}
                            aria-checked={accessibility}
                            onClick={() => setAccessibility(prev => !prev)}
                            dataTestId="language-options-button"
                            role="switch"
                        />
                    </Section>
                )}
            </PageWrapper>
        </HelmetWrapper>
    );
};

export default PageAccessibility;

import React, { useState, useEffect } from 'react';
import playerIcons from 'assets/images/player';
import { OverlayContainer, IconWrapper } from './KeyboardShortcutOverlay.css';
import { JumpButtonIcon, JumpButtonLabel, JumpButtonWrapper } from '../PlayerUI/PlayerUI.css';

interface KeyboardShortcutOverlayProps {
    iconName: string;
    overlayTrigger: number;
    isEnabled: boolean;
    forwardJumpValue: number;
    backwardJumpValue: number;
}

const KeyboardShortcutOverlay: React.FC<KeyboardShortcutOverlayProps> = ({
    iconName,
    overlayTrigger,
    isEnabled,
    forwardJumpValue,
    backwardJumpValue,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isEnabled && overlayTrigger) {
            setIsVisible(true);
            const timer = setTimeout(() => setIsVisible(false), 200);
            return () => clearTimeout(timer);
        }
    }, [overlayTrigger, isEnabled]);

    const getIcon = (name: string) => {
        const iconKey = `${name}Icon` as keyof typeof playerIcons;
        return playerIcons[iconKey] || playerIcons.playIcon;
    };

    const getPosition = (): 'left' | 'right' | 'center' => {
        switch (iconName) {
            case 'jumpFw':
                return 'right';
            case 'jumpBw':
                return 'left';
            default:
                return 'center';
        }
    };

    const renderContent = () => {
        if (iconName === 'jumpFw' || iconName === 'jumpBw') {
            const jumpValue = iconName === 'jumpFw' ? forwardJumpValue : backwardJumpValue;
            return (
                <JumpButtonWrapper>
                    <JumpButtonIcon>
                        <img src={getIcon(iconName)} alt={`${iconName} icon`} />
                    </JumpButtonIcon>
                    <JumpButtonLabel>{jumpValue}</JumpButtonLabel>
                </JumpButtonWrapper>
            );
        }

        return (
            <IconWrapper>
                <img src={getIcon(iconName)} alt={`${iconName} icon`} />
            </IconWrapper>
        );
    };

    return (
        <OverlayContainer visible={isVisible} position={getPosition()}>
            {renderContent()}
        </OverlayContainer>
    );
};

export default KeyboardShortcutOverlay;

import React, { FC, ReactNode, useEffect, useState } from 'react';
import SVGInline from 'react-svg-inline';
import { format } from 'date-fns';
import { PageWrapper, Title, SelectHint, DeviceHint, DeviceList, SignOutButton, SignOutLabel, Icon } from './PageDeviceManager.css';
import { ErrorMessage } from '../Settings/PageSettingsCommon.css';
import translate from '../../../utils/fnTranslate';
import icons from '../../../style';
import { useApp } from '../../../providers/useApp/AppContext';
import { useTrackingScreen } from '../../../providers/useTracking/TrackingContext';
import { useGlobalNetworkError, useGlobalNoInternet } from '../../../hooks/withNetworkCheck/withNetworkCheck';
import { useDataFetcher } from '../../../hooks/useDataFetcher/useDataFetcher';
import Api from '../../../api/Api';
import { TrackingElementPropTypes } from '../../../types/Tracking';
import { AlertDialog } from '../../AlertDialog/AlertDialog';
import { FetchParamById } from '../../../types/ApiTypes';
import { useAuth } from '../../../providers/useAuth/AuthContext';
import { userInfo } from '../../../providers/useAuth/AuthService';
import OptionFocusable from '../Settings/OptionFocusable';

export enum DeviceType {
    'mobile' = 'mobile',
    'tablet' = 'tablet',
    'computer' = 'computer',
    'tv' = 'tv',
    'stb' = 'stb',
}

type DevicePropType = TrackingElementPropTypes & {
    icon: any;
    name: string;
    description: string;
    signOutLabel: string;
    isDisabled: boolean;
    onClick: () => void;
    index: number;
};

const Alert: React.FC<{ uuid: string; onSuccess: () => void; onClose: () => void }> = ({ uuid, onSuccess, onClose }) => {
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const { error, fetcher: removeDevice, responseCode } = useDataFetcher<any, FetchParamById>(
        params => Api.removeDevice(params.id),
        onNoInternet,
        onNetworkError
    );

    useEffect(() => {
        if (responseCode?.toString().startsWith('2')) {
            onSuccess();
        }
    }, [responseCode, onSuccess]);

    useEffect(() => {
        if ((responseCode && !responseCode?.toString().startsWith('2')) || error) {
            setShowErrorAlert(true);
        }
    }, [responseCode, error]);

    return (
        <AlertDialog
            title={translate(
                showErrorAlert ? 'SCREEN_DEVICE_MANAGEMENT_SIGNOUT_FAILED_HEADER' : 'SCREEN_DEVICE_MANAGEMENT_DELETE_CONFIRMATION_HEADER'
            )}
            bodyText={translate(
                showErrorAlert ? 'SCREEN_DEVICE_MANAGEMENT_SIGNOUT_FAILED_HINT' : 'SCREEN_DEVICE_MANAGEMENT_DELETE_CONFIRMATION_HINT'
            )}
            buttons={[
                {
                    text: translate('CANCEL_BUTTON'),
                    onClick: onClose,
                },
                {
                    text: translate(showErrorAlert ? 'TRY_AGAIN_BUTTON' : 'SIGN_OUT_DEVICE_BUTTON'),
                    onClick: () => {
                        removeDevice({ id: uuid });
                    },
                },
            ]}
        />
    );
};

const DeviceItem: React.FC<DevicePropType> = ({ icon, name, description, signOutLabel, isDisabled, onClick, index }) => {
    return (
        <OptionFocusable
            title={name}
            subtitle={description}
            isDisabled={isDisabled}
            icon={icon}
            role="button"
            onClick={onClick}
            actionComponent={
                <SignOutButton aria-hidden>
                    <SignOutLabel>{signOutLabel}</SignOutLabel>
                    <Icon>
                        <SVGInline svg={icons.icoTrash} />
                    </Icon>
                </SignOutButton>
            }
            ariaHint={translate('ARIA_HINT_DEVICE_SIGNOUT')}
        />
    );
};

export const PageDeviceManager: FC = () => {
    const { setPageTitle } = useApp();
    const { drmInit, isDeviceLimitReached } = useAuth();
    const trackScreen = useTrackingScreen(() => true);
    const { onNoInternet } = useGlobalNoInternet();
    const { onNetworkError } = useGlobalNetworkError();
    const [deviceToRemove, setDeviceToRemove] = useState<string | null>(null);

    const { response, error, fetcher: getAllDevices } = useDataFetcher<any, any>(() => Api.getAllDevices(), onNoInternet, onNetworkError);

    const getHintTitle = (deviceCount: number, maxDeviceCount: number): string => {
        if (deviceCount === 1) {
            return translate('SETTINGS_DEVICE_MANAGEMENT_SCREEN_ONE_DEVICE_HINT', {
                max_device_count: maxDeviceCount,
            });
        }
        if (deviceCount > 1) {
            return translate('SETTINGS_DEVICE_MANAGEMENT_SCREEN_DEVICES_HINT', {
                device_count: deviceCount,
                max_device_count: maxDeviceCount,
            });
        }

        return null;
    };

    const getDeviceIcon = (deviceType: string) => {
        switch (deviceType) {
            case DeviceType.mobile:
                return icons.icoDevicePhone;
            case DeviceType.tablet:
                return icons.icoDeviceTablet;
            case DeviceType.computer:
                return icons.icoDeviceDesktop;
            case DeviceType.tv:
                return icons.icoDeviceTv;
            case DeviceType.stb:
                return icons.icoDeviceTv;
            default:
                return null;
        }
    };

    const deviceItems: ReactNode[] = response?.devices
        ? response.devices.map((device, index) => (
              <DeviceItem
                  key={`device-${index}`}
                  icon={getDeviceIcon(device.screen_type)}
                  name={`${device.friendly_name}, ${device.os_type}`}
                  description={`${translate('SETTINGS_DEVICE_LAST_USED')}: ${format(Date.parse(device.last_seen), 'dd.MM.yyyy')}`}
                  signOutLabel={translate('SIGN_OUT_DEVICE_BUTTON')}
                  isDisabled={!device.is_replaceable}
                  onClick={() => setDeviceToRemove(device.uuid)}
                  index={index}
              />
          ))
        : null;

    useEffect(() => {
        getAllDevices(null);
        const title = translate('SETTINGS_DEVICE_MANAGEMENT');

        setPageTitle(title);
        trackScreen(title);
    }, []);

    return (
        <>
            <PageWrapper>
                <Title>{translate('SETTINGS_DEVICE_MANAGEMENT')}</Title>
                {error ? (
                    <ErrorMessage tabIndex={0}>{translate('SETTINGS_ERROR_HINT')}</ErrorMessage>
                ) : (
                    <>
                        <SelectHint tabIndex={0}>{translate('SETTINGS_DEVICE_MANAGEMENT_SCREEN_WEB')}</SelectHint>
                        <DeviceHint tabIndex={0}>{getHintTitle(response?.device_count, response?.max_device_count)}</DeviceHint>
                        {deviceItems?.length > 0 && <DeviceList>{deviceItems}</DeviceList>}
                    </>
                )}
            </PageWrapper>
            {deviceToRemove && (
                <Alert
                    uuid={deviceToRemove}
                    onSuccess={() => {
                        setDeviceToRemove(null);
                        getAllDevices(null);
                        if (isDeviceLimitReached) {
                            userInfo(async (userData: any) => {
                                drmInit(userData, true);
                            });
                        }
                    }}
                    onClose={() => setDeviceToRemove(null)}
                />
            )}
        </>
    );
};

export default PageDeviceManager;

import { fontBold } from 'style/styled-components/cssGlobalVariables';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { hexToRgba } from '../../utils/fnColors';
import { Breakpoints, MQ } from '../../style/styled-components/cssMediaQueries';

export const SeekBarContainer = styled.div`
    width: 100%;
    display: flex;
    margin-left: 0;

    ${MQ(Breakpoints.m)} {
        /* margin-left: 26px; */
    }

    &::after {
        display: block;
        content: '';
        clear: both;
    }
`;

export const ProgressAndScrubber = styled.div`
    position: relative;
    flex-grow: 1;
`;

export const TimeIndicator = styled.div`
    display: block;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    &:first-of-type {
        padding-right: 16px;
        text-align: right;
    }

    &:last-child {
        padding-left: 16px;
        text-align: left;
    }

    ${MQ(Breakpoints.m)} {
        font-size: 14px;
    }
`;

export const SeekBarValue = styled.div`
    position: absolute;
    top: -28px;
    font-family: ${fontBold};
    font-size: 12px;
    display: none;
`;

export const ProgressBar = styled.div`
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 100px;
    overflow: hidden;
    z-index: 1;

    &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.2;
        background-color: ${props => props.theme.brandedConfig.white_color};
    }
`;

export const BufferLine = styled.div`
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 100px;
    opacity: 0.3;
    background-color: ${props => props.theme.brandedConfig.white_color};
`;

export const HoverLine = styled.div`
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 100px;
    opacity: 0.45;
    background-color: ${props => props.theme.brandedConfig.white_color};
`;

export const ThumbnailContainer = styled.div`
    position: absolute;
    background-color: ${props => props.theme.brandedConfig.background};
    overflow: hidden;
    display: none;
    border: 2px solid #fff;
    border-radius: 4px;
`;

export const ThumbnailImage = styled.img<{ positionX: number; positionY: number }>`
    transform: translate(${props => props.positionX}px, ${props => props.positionY}px);
`;

export const ProgressLine = styled.div`
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 100px;
    background-color: ${props => props.theme.brandedConfig.high_contrast};
`;

export const Scrubber = styled.input<{ disabled: boolean }>`
    width: 100%;
    height: 20px;
    display: inline-block;
    margin: 0;
    -webkit-appearance: none;
    border-radius: 3px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background: transparent;
    position: relative;
    z-index: 1;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-thumb {
        border-radius: 50% !important;
        outline: none !important;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 5px 9px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        background-color: ${props => props.theme.brandedConfig.high_contrast};
        display: ${props => (props.disabled ? 'none' : 'block')};
    }

    &::-moz-range-thumb {
        border-radius: 50% !important;
        outline: none !important;
        width: 20px;
        height: 20px;
        margin-top: -17px;
        margin-left: -10px;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 5px 9px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        background-color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: ${props => (props.disabled ? '0' : '1')} !important;
    }

    &::-ms-thumb {
        border-radius: 50% !important;
        outline: none !important;
        width: 20px;
        height: 20px;
        margin-top: -17px;
        margin-left: -10px;
        -webkit-appearance: none;
        border: none;
        box-shadow: 0 5px 9px ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.2)};
        background-color: ${props => props.theme.brandedConfig.high_contrast};
        display: ${props => (props.disabled ? 'none' : 'block')};
    }

    &::-webkit-slider-runnable-track {
        height: 0;
        width: 100%;
        border: none;
        -webkit-appearance: none;
    }

    &:focus::-webkit-slider-thumb {
        outline: var(--focus-outline) !important;
    }

    &:focus::-moz-range-thumb {
        outline: var(--focus-outline) !important;
    }

    &:focus::-ms-thumb {
        outline: var(--focus-outline) !important;
    }
`;

import React from 'react';
import { Breakpoints, MQ } from 'style/styled-components/cssMediaQueries';
import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';
import { headerZIndex } from '../../style/styled-components/cssGlobalVariables';
import { LinkTrackable } from '../Trackable/LinkTrackable';

export const ExternalLink = styled.a<{ active?: string }>`
    color: ${props => props.theme.brandedConfig.high_contrast};
    opacity: ${props => (props.active ? '1.0' : '0.66')};

    &:focus-visible {
        border-bottom: 2px solid ${props => props.theme.brandedConfig.high_contrast};
        margin-bottom: -2px;
        transition: none;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InternalLink = styled(({ active, ...rest }) => <LinkTrackable {...rest} />)<{ active?: string }>`
    color: ${props => props.theme.brandedConfig.high_contrast};
    opacity: ${props => (props.active ? '1.0' : '0.66')};

    &:focus-visible {
        border-bottom: 2px solid ${props => props.theme.brandedConfig.high_contrast};
        margin-bottom: -2px;
        transition: none;
    }
`;

export const LinksBlock = styled.nav`
    position: relative;
    height: 20px;
    top: 2px;

    ${InternalLink}, ${ExternalLink} {
        display: block;
        vertical-align: middle;
        font-size: 14px;
        line-height: 20px;

        &:hover {
            opacity: 1;
        }
    }
`;

export const FooterWrapper = styled.footer`
    position: relative;
    height: 48px;
    display: none;
    width: 100%;
    line-height: 24px;
    z-index: ${headerZIndex};
    text-align: left;
    background-color: ${props => props.theme.brandedConfig.background};
    padding-left: 40px;

    ${MQ(Breakpoints.xs)} {
        ${LinksBlock} {
            display: inline-block;
            width: auto;

            ${InternalLink}, ${ExternalLink} {
                margin-right: 24px;
                display: inline-block;
                vertical-align: middle;
            }

            hr {
                margin: 0;
                width: 100%;
                float: left;
            }
        }
    }

    ${MQ(Breakpoints.m)} {
        display: block;
    }
`;

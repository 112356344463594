import React, { ChangeEvent, FC, InputHTMLAttributes, useEffect, useRef } from 'react';
import { StyledInput, StyledInputContainer } from './InputPin.css';

export interface PinInputProps extends InputHTMLAttributes<HTMLInputElement> {
    numberOnly?: boolean;
    showDuration?: number;
    isInvalid?: boolean;
    isActive: boolean;
    value: string;
    index: number;
}

export const PinInput: FC<PinInputProps> = ({
    value = '',
    numberOnly = true,
    showDuration = 1000,
    onChange,
    isActive,
    isInvalid = false,
    index,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const focusActiveInput = () => {
        if (isActive && inputRef.current) {
            inputRef.current.focus();
        }
    };

    useEffect(() => {
        focusActiveInput();

        window.addEventListener('click', focusActiveInput);
        return () => {
            window.removeEventListener('click', focusActiveInput);
        };
    }, [isActive]);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current && index === 0) inputRef.current.focus();
        }, 250);
    }, []);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (numberOnly && e.target.value.match(/^\d?$/)) {
            if (onChange) {
                onChange(e);
            }
        }
    };

    return (
        <StyledInputContainer data-test-id={'header-settingsbuttons-enter-pin'}>
            <input
                type={'password'}
                id={`new-password-${index}`}
                name={`new-password-${index}`}
                style={{ display: 'none' }}
                autoComplete={'new-password'}
                aria-label={`Digit ${index + 1}`}
            />

            <StyledInput
                value={value}
                disabled={!isActive}
                isActive={isActive}
                ref={inputRef}
                isInvalid={isInvalid}
                maxLength={1}
                index={index}
                type={'numeric'}
                onChange={onInputChange}
                required={true}
                placeholder={'○'}
                aria-invalid={isInvalid}
                aria-labelledby={`pin-input-${index}`}
                autoComplete={'new-password'}
                showDuration={showDuration}
                {...rest}
            />
            <span aria-hidden="true">●</span>
        </StyledInputContainer>
    );
};

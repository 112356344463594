import { AlertDialog } from 'components/AlertDialog/AlertDialog';
import { HelmetWrapper } from 'components/HelmetWrapper/HelmetWrapper';
import { useApp } from 'providers/useApp/AppContext';
import { useRecording } from 'providers/useRecording/RecordingContext';
import { useTrackingScreen } from 'providers/useTracking/TrackingContext';
import React, { FC, useEffect, useState } from 'react';
import { getHelmetData } from 'utils/fnHelmet';
import { secondsToTimeString } from 'utils/fnData';
import translate from '../../../../utils/fnTranslate';
import { CenterButton, PageWrapper, Subtitle, ErrorMessage, Section } from '../PageSettingsCommon.css';
import { ProgressIndicator } from '../../../ProgressIndicator/ProgressIndicator';
import { RecordingLabel, RecordingProgressIndicator, RecordingProgressLabels, Value, Name } from './PageRecordingsSettings.css';
import { secondsToTime } from '../../../../utils/fnDate';

export const PageRecordingsSettings: FC = () => {
    const {
        getRecordingQuotaRequest,
        recordingQuotaResponse,
        setToDeleteAll,
        deleteAllRecordingsResponse,
        fetchRecordingError,
    } = useRecording();
    const { setPageTitle } = useApp();
    const trackScreen = useTrackingScreen(() => true);

    const [remainingTime, setRemainingTime] = useState<number>(null);
    const [totalTime, setTotalTime] = useState<number>(null);
    const [progress, setProgress] = useState(0);
    const [buttonIsHidden, setButtonIsHidden] = useState<boolean>(false);

    useEffect(() => {
        const title = translate('SETTINGS_RECORDINGS');
        setPageTitle(title);
        trackScreen(title);
    }, []);

    useEffect(() => {
        setButtonIsHidden(true);
    }, [fetchRecordingError]);

    useEffect(() => {
        getRecordingQuotaRequest(null);
    }, [deleteAllRecordingsResponse]);

    useEffect(() => {
        if (recordingQuotaResponse) {
            const { all, remaining } = recordingQuotaResponse;

            setProgress(((all - remaining) * 100) / all);

            setButtonIsHidden(all === remaining);

            setRemainingTime(remaining);
            setTotalTime(all);
        }
    }, [recordingQuotaResponse]);

    const showAlert = () => {
        if (buttonIsHidden) {
            return;
        }
        setToDeleteAll(true);
    };

    const notAvailableValue = () => {
        return fetchRecordingError ? 'N/A' : undefined;
    };

    return (
        <HelmetWrapper {...getHelmetData(translate('SETTINGS_RECORDINGS'))}>
            <PageWrapper>
                <Section aria-label={translate('SETTINGS_RECORDINGS_STORAGE')}>
                    <Subtitle data-test-id={'header-settingsbuttons-recording-remaining-time-title'}>
                        {translate('SETTINGS_RECORDINGS_STORAGE')}
                    </Subtitle>

                    <RecordingProgressIndicator>
                        <ProgressIndicator percentage={progress} displayWithNullProgress={true} />
                    </RecordingProgressIndicator>

                    <RecordingProgressLabels>
                        <RecordingLabel tabIndex={0}>
                            <Name>{translate('SETTINGS_RECORDINGS_STORAGE_TOTAL')}</Name>
                            <Value aria-label={secondsToTimeString(totalTime * 60)}>
                                {notAvailableValue() ?? secondsToTime(totalTime * 60)}
                            </Value>
                        </RecordingLabel>
                        <RecordingLabel tabIndex={0}>
                            <Name className={'highlighted'}>{translate('SETTINGS_RECORDINGS_STORAGE_RECORDED')}</Name>
                            <Value aria-label={secondsToTimeString((totalTime - remainingTime) * 60)}>
                                {notAvailableValue() ?? secondsToTime((totalTime - remainingTime) * 60)}
                            </Value>
                        </RecordingLabel>
                        <RecordingLabel tabIndex={0}>
                            <Name>{translate('SETTINGS_RECORDINGS_STORAGE_FREE')}</Name>
                            <Value aria-label={secondsToTimeString(remainingTime * 60)}>
                                {notAvailableValue() ?? secondsToTime(remainingTime * 60)}
                            </Value>
                        </RecordingLabel>
                    </RecordingProgressLabels>

                    <CenterButton
                        isDisabled={buttonIsHidden}
                        label={translate('DELETE_ALL_RECORDINGS_BUTTON')}
                        isPrimary={true}
                        onClick={showAlert}
                        data-test-id={'header-settingsbuttons-recordings-deleterecordings'}
                    />
                </Section>

                {fetchRecordingError && <ErrorMessage>{translate('SETTINGS_ERROR_HINT')}</ErrorMessage>}
            </PageWrapper>
        </HelmetWrapper>
    );
};

export default PageRecordingsSettings;

export const DeleteAllRecordingsConfirmation: FC = () => {
    const { toDeleteAll, setToDeleteAll, deleteAllRecordingsRequest } = useRecording();

    if (!toDeleteAll) {
        return null;
    }

    return (
        <AlertDialog
            title={translate('ALERT_DELETE_ALL_RECORDINGS_TITLE')}
            bodyText={translate('ALERT_DELETE_ALL_RECORDINGS_BODY')}
            buttons={[
                {
                    text: translate('CANCEL_BUTTON'),
                    onClick: () => {
                        setToDeleteAll(false);
                    },
                },
                {
                    text: translate('DELETE_BUTTON'),
                    onClick: () => {
                        setToDeleteAll(false);
                        deleteAllRecordingsRequest(null);
                    },
                },
            ]}
        />
    );
};

import { useEffect } from 'react';

type KeyHandler =
    | (() => void)
    | {
          handler: () => void;
          condition: () => boolean;
      };

interface KeyHandlers {
    escape?: KeyHandler;
    enter?: KeyHandler;
    backspace?: KeyHandler;
    p?: KeyHandler;
    f?: KeyHandler;
}

export const useKeyboardShortcuts = (handlers: KeyHandlers) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const handler = (() => {
                switch (event.key) {
                    case 'Escape':
                        return handlers.escape;
                    case 'Enter':
                        return handlers.enter;
                    case 'Backspace':
                        return handlers.backspace;
                    case 'p':
                        return handlers.p;
                    case 'f':
                        return handlers.f;
                    default:
                        return undefined;
                }
            })();

            const activeElement = document.activeElement as any;

            if (!handler && event.key === 'Enter' && activeElement.click()) {
                // NOTE: is it a hack? yes. does it work? yes :|
                activeElement.click();
            }

            if (!handler) return;

            if (typeof handler === 'function') {
                handler();
            } else if (handler.condition()) {
                handler.handler();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handlers]);
};

import React, { FC, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { VODAssetEntitlementState } from 'types/Entitlement';
import { hasProgressBar } from 'utils/fnData';
import { CardWrapper } from '../../Lane/Lane.css';
import {
    CardPortraitWrapper,
    FallbackTitle,
    ImageWrapper,
    portraitMixedCardHeight,
    portraitMixedCardWidth,
    portraitMovieCardHeight,
    portraitMovieCardWidth,
    StyledButtonPlay,
} from './CardPortrait.css';
import { CardImage, ContentMarker } from '../../../style/styled-components/reusable.css';
import { LinkTrackable } from '../../Trackable/LinkTrackable';
import fallbackImage from '../../../assets/images/portrait_fallback.png';
import { StandardCover } from '../../../types/Cover';
import { CardContext, StripeTypes } from '../../../types/Common';

type PropTypes = {
    asset: StandardCover;
    stripeType: string;
    cardContext?: CardContext;
};

export const CardPortrait: FC<PropTypes> = ({ asset, stripeType, cardContext = CardContext.STRIPE, ...rest }) => {
    const width = stripeType === StripeTypes.MOVIE || stripeType === StripeTypes.PORTRAIT ? portraitMovieCardWidth : portraitMixedCardWidth;
    const height =
        stripeType === StripeTypes.MOVIE || stripeType === StripeTypes.PORTRAIT ? portraitMovieCardHeight : portraitMixedCardHeight;

    const [showFallback, setShowFallback] = useState(false);

    const { path, title, image, renderPlayIcon, onPlayIconClicked, progressBar, contentMarker, rawData } = asset;
    const { vodAssetState } = rawData;

    const showProgress = vodAssetState ? vodAssetState === VODAssetEntitlementState.ENTITLED : true;
    const altText = `${rawData?.genres?.[0] ? rawData?.genres?.[0] : ''} ${title}`;

    const [ref, inView] = useInView({
        threshold: 0,
    });

    const cardHeight = cardContext === CardContext.COLLECTION ? 'auto' : height;
    const cardWidth = cardContext === CardContext.COLLECTION ? 'auto' : width;

    return (
        <CardWrapper className={`tile`} height={cardHeight} width={cardWidth} ref={ref}>
            {inView && (
                <LinkTrackable
                    to={path}
                    onClick={e => {
                        if (asset.onClick) {
                            e.preventDefault();
                            e.stopPropagation();

                            asset.onClick(e, asset.rawData);
                        }
                    }}
                    visibleFocus="outline"
                    style={{ display: 'inline-block', height: cardHeight, width: cardWidth }}
                    {...rest}
                >
                    <CardPortraitWrapper
                        data-test-id={'card-portrait-wrapper'}
                        mixed={stripeType !== StripeTypes.MOVIE && stripeType !== StripeTypes.PORTRAIT}
                    >
                        <ImageWrapper className="focus-image-wrapper">
                            {renderPlayIcon && <StyledButtonPlay onClick={onPlayIconClicked} />}
                            <CardImage
                                url={image}
                                fallbackImage={fallbackImage}
                                alt={altText}
                                {...{
                                    imageError: () => {
                                        setShowFallback(true);
                                    },
                                }}
                            />
                            {contentMarker != null && (
                                <ContentMarker withProgressBar={showProgress && hasProgressBar(progressBar)} marker={contentMarker}>
                                    {contentMarker.value}
                                </ContentMarker>
                            )}
                            {showFallback && (
                                <FallbackTitle>
                                    <span>{title}</span>
                                </FallbackTitle>
                            )}
                            {showProgress && progressBar}
                        </ImageWrapper>
                    </CardPortraitWrapper>
                </LinkTrackable>
            )}
        </CardWrapper>
    );
};

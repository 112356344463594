import React, { FC } from 'react';
import { CastButtonWrapper, CastIcon } from './CastButton.css';
import { useCast } from '../../providers/cast/CastContext';
import icons from '../../style';
import { castEnabled } from '../../utils/fnPlayerUI';
import translate from '../../utils/fnTranslate';

export const CastButton: FC = () => {
    const { toggleConnect, castConnected } = useCast();

    if (!castEnabled()) {
        return null;
    }

    return (
        <CastButtonWrapper onClick={toggleConnect} tabIndex={0} aria-haspopup="dialog" aria-label={translate('ARIA_CAST_BUTTON')}>
            <CastIcon svg={castConnected ? icons.castConnectedIcon : icons.castIcon} />
        </CastButtonWrapper>
    );
};

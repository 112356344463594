import { css } from 'styled-components';
import SVGInline from 'react-svg-inline';
import { DropdownContent, DropdownItemSimpleWrapper } from 'components/DropdownMenu/DropdownMenu.css';
import { styled } from '../../ThemeConfigConnector/ThemeConfigConnnector';
import { hexToRgba } from '../../../utils/fnColors';
import { Breakpoints, MQ, MQ_NOT_TOUCH } from '../../../style/styled-components/cssMediaQueries';
import { InputFieldWithPlaceholder } from '../../InputFieldWithPlaceholder/InputFieldWithPlaceholder';
import { Input, Placeholder } from '../../InputFieldWithPlaceholder/InputFieldWithPlaceholder.css';

const animationType = 'ease';
export const animationDuration = '400ms';

export const IconSearch = styled(SVGInline)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    transition: none;
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;

    ${MQ(Breakpoints.m)} {
        transition: all ${animationDuration} ${animationType};
    }

    svg {
        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }

    &:hover {
        opacity: 0.7;
    }

    &:focus-visible {
        outline: var(--focus-outline);
        border-radius: 50%;
        transition: none !important;
    }
`;

export const IconClear = styled(SVGInline)`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    transition: none;
    z-index: 1;

    svg {
        display: block;
        width: 14px;
        height: 14px;

        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }

    ${MQ(Breakpoints.m)} {
        transition: all ${animationDuration} ${animationType};
        right: 0;
    }
`;

export const ButtonClose = styled.a`
    display: block;
    float: right;
    width: 0;
    overflow: hidden;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    transition: none;

    ${MQ(Breakpoints.m)} {
        transition: all ${animationDuration} ${animationType};
    }
`;

export const SearchInputContainer = styled.div`
    position: relative;
    float: right;
    width: 0;
    transition: none;

    ${MQ(Breakpoints.m)} {
        transition: all ${animationDuration} ${animationType};
    }
`;

export const SearchInputLabel = styled.label`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;

export const SearchInput = styled(InputFieldWithPlaceholder)`
    display: block;
    height: 100%;
    outline: none;
    color: ${props => props.theme.brandedConfig.high_contrast};
    font-size: 16px;

    ${Input} {
        border-radius: 8px;
        padding: 5px 0;
        line-height: 24px;
        background-color: ${props => hexToRgba(props.theme.brandedConfig.white_color, 0.1)};
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        color: ${props => props.theme.brandedConfig.high_contrast};
        font-size: 16px;
        font-family: inherit;
    }

    ${Placeholder} {
        color: ${props => props.theme.brandedConfig.high_contrast};
        opacity: 0.5;
        top: 8px;
        font-size: 16px;
        font-family: inherit;
    }

    ${MQ(Breakpoints.m)} {
        ${Input} {
            background-color: transparent;
        }
    }
`;

export const SearchWrapper = styled.div<{ isOpen: boolean; closing: boolean }>`
    float: right;
    height: 32px;
    position: relative;
    transition: none;

    width: 20px;

    ${MQ(Breakpoints.m)} {
        transition: all ${animationDuration} ${animationType};
    }

    ${MQ(Breakpoints.m)} {
        background-color: transparent;
    }

    &:focus-visible,
    &:focus {
        background-color: green !important;
    }

    ${props =>
        props.isOpen && !props.closing
            ? css`
                  width: 100%;
                  margin-right: 0;
                  position: absolute;
                  right: 0;
                  z-index: 2;
                  padding-left: 12px;
                  background-color: ${props.theme.brandedConfig.black_color};

                  ${MQ(Breakpoints.m)} {
                      margin-right: 15px;
                      position: relative;
                      width: 210px;
                  }

                  ${SearchInputContainer} {
                      width: calc(100% - 82px);

                      ${MQ(Breakpoints.m)} {
                          width: 100%;
                      }
                  }

                  ${SearchInput} {
                      ${Input} {
                          padding: 5px 44px 5px 38px;

                          ${MQ(Breakpoints.m)} {
                              padding: 5px 20px 5px 0;
                          }
                      }

                      ${Placeholder} {
                          left: 38px;
                          width: calc(100% - 70px);

                          ${MQ(Breakpoints.m)} {
                              left: 0;
                              width: calc(100% - 18px);
                          }
                      }
                  }

                  ${IconSearch} {
                      right: calc(100% - 30px);

                      ${MQ(Breakpoints.m)} {
                          right: 100%;
                          margin-right: 10px;
                      }
                  }

                  ${IconClear} {
                      opacity: 1;
                  }

                  ${ButtonClose} {
                      width: 82px;

                      ${MQ(Breakpoints.m)} {
                          width: 0;
                      }
                  }
              `
            : ``}
`;

export const SearchHistoryDropdownWrapper = styled.div`
    ${DropdownContent} {
        width: 300px;
    }

    ${DropdownItemSimpleWrapper} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        min-height: 44px;

        .search-history {
            position: relative;
            top: 0;
            transform: none;
            right: 0;
            width: 20px;
            height: 20px;

            svg {
                position: relative;
                width: auto;
                height: 100%;
            }
        }

        .delete-history {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 24px;
            width: 24px;
            margin-right: 16px;

            svg {
                width: 14px;
                height: 14px;
            }
        }
    }
`;

export const DropdownHistoryText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
    white-space: nowrap;
    max-width: 250px;
`;

export const DropdownTitle = styled(DropdownItemSimpleWrapper)`
    font-weight: bold;
    opacity: 1;
    color: ${props => props.theme.brandedConfig.white_color};

    ${MQ_NOT_TOUCH} {
        &:hover {
            background-color: transparent;
        }
    }
`;

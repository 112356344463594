import { styled } from '../ThemeConfigConnector/ThemeConfigConnnector';

interface OverlayContainerProps {
    visible: boolean;
    position: 'left' | 'right' | 'center';
}

export const OverlayContainer = styled.div<OverlayContainerProps>`
    position: absolute;
    top: 50%;
    opacity: ${props => (props.visible ? 1 : 0)};
    transition: opacity 0.2s ease-out;
    z-index: 999;
    pointer-events: none;

    ${({ position }) => {
        switch (position) {
            case 'left':
                return `
                    left: 10%;
                    transform: translateY(-50%);
                `;
            case 'right':
                return `
                    right: 10%;
                    transform: translateY(-50%);
                `;
            default:
                return `
                    left: 50%;
                    transform: translate(-50%, -50%);
                `;
        }
    }}
`;

export const IconWrapper = styled.div`
    width: 80px;
    height: 80px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

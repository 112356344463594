import React, { useState } from 'react';
import SVGInline from 'react-svg-inline';
import { getInitials } from 'utils/fnData';
import translate from 'utils/fnTranslate';
import { PersonRole } from 'types/Asset';
import {
    CircleCardImageContainer,
    CircleCardTitle,
    CircleCardTitleWrapper,
    CircleCardWrapper,
    InitialsFallback,
    CircleCardSubtitle,
} from './CardCircle.css';
import { CardWrapper } from '../../Lane/Lane.css';
import fallbackPerson from '../../../assets/images/person_fallback.png';
import fallbackKid from '../../../assets/images/kid_fallback.png';
import { LinkTrackable } from '../../Trackable/LinkTrackable';
import { CardImage } from '../../../style/styled-components/reusable.css';
import icons from '../../../style';
import { StandardCover } from '../../../types/Cover';

export type PropTypes = {
    asset: StandardCover;
};

export const CardCircle: React.FC<PropTypes> = ({ asset, ...rest }) => {
    const [showFallBackInitials, setShowFallbackInitials] = useState<boolean>(false);
    const isKidCharacter = asset.path && asset.path.includes('kids');

    return (
        <CardWrapper width={'auto'} height={'auto'}>
            <LinkTrackable
                to={asset.path || ''}
                onClick={e => {
                    if (!asset.path) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                visibleFocus="outline"
                {...rest}
            >
                <CircleCardWrapper onClick={event => asset.onClick(event, null)}>
                    <CircleCardImageContainer data-test-id={'castmember-circle'}>
                        {showFallBackInitials &&
                            (isKidCharacter ? (
                                <CardImage url={fallbackKid} alt={asset.title} />
                            ) : (
                                <InitialsFallback>{getInitials(asset.title)}</InitialsFallback>
                            ))}
                        {!showFallBackInitials && (
                            <CardImage
                                url={asset.image}
                                fallbackImage={fallbackPerson}
                                alt={asset.title}
                                {...{
                                    imageError: () => {
                                        setShowFallbackInitials(true);
                                    },
                                }}
                            />
                        )}
                    </CircleCardImageContainer>
                    <CircleCardTitleWrapper>
                        <CircleCardTitle data-test-id={'tv-detailscreen-circlecardtitle'}>
                            {asset.isFavorite ? <SVGInline data-test-id={'tv-detailscreen-casthearticon'} svg={icons.heartIcon} /> : null}
                            {asset.title}
                        </CircleCardTitle>
                        {asset.role === PersonRole.DIRECTOR && <CircleCardSubtitle>{translate('PERSON_ROLE_DIRECTOR')}</CircleCardSubtitle>}
                    </CircleCardTitleWrapper>
                </CircleCardWrapper>
            </LinkTrackable>
        </CardWrapper>
    );
};

import { PlayingAsset, PlayingMode } from 'types/Player';
import ApplicationConfig from 'providers/useConfig/ApplicationConfig';
import { YouboraOptions } from 'types/Tracking';
import {
    isCatchupPlayerAsset,
    isLivePlayerAsset,
    isRecordingPlayerAsset,
    isTrailerPlayerAsset,
    isVodPlayerAsset,
} from 'utils/fnTypeGuards';
import npawAdapterConfig from 'config/customConfig/youbora-adapter.json';
import NpawPlugin from 'npaw-plugin-es5';

enum YouboraPlaybackTypes {
    LIVE = 'LIVE',
    TIMESHIFT = 'TIMESHIFT',
    CATCHUP = 'CATCHUP',
    VOD = 'VOD',
    TRAILER = 'TRAILER',
    RECORDING = 'RECORDING',
    UNKNOWN = 'UNKNOWN',
}

class YouboraTracking {
    private youboraPlugin = null;

    private debug = false;

    constructor() {
        this.youboraPlugin = new NpawPlugin(ApplicationConfig?.app_config?.miscellanious?.youbora_account_code);

        this.youboraPlugin.setLogLevel(6);
    }

    static getPlaybackType = (asset: PlayingAsset, playingMode: PlayingMode): YouboraPlaybackTypes => {
        if (isLivePlayerAsset(asset)) {
            if (playingMode === PlayingMode.NORMAL) {
                return YouboraPlaybackTypes.LIVE;
            }
            return YouboraPlaybackTypes.TIMESHIFT;
        }

        if (isRecordingPlayerAsset(asset)) {
            return YouboraPlaybackTypes.RECORDING;
        }

        if (isTrailerPlayerAsset(asset)) {
            return YouboraPlaybackTypes.TRAILER;
        }

        if (isVodPlayerAsset(asset)) {
            return YouboraPlaybackTypes.VOD;
        }

        if (isCatchupPlayerAsset(asset)) {
            return YouboraPlaybackTypes.CATCHUP;
        }

        return YouboraPlaybackTypes.UNKNOWN;
    };

    setShakaAdapter = engine => {
        this.youboraPlugin.registerAdapterFromString(engine, JSON.stringify(npawAdapterConfig));
    };

    setOptions = (options: YouboraOptions) => {
        this.youboraPlugin.setVideoOptions(options);
    };

    fireFatalError = (code: number, message: string, metadata: string) => {
        this.youboraPlugin.getAdapter().fireFatalError(code, message, metadata, undefined);
    };
}

export default YouboraTracking;

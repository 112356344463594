import { ReactNode, Component } from 'react';
import { RouteChildrenProps } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import { isMobile, isTablet } from 'react-device-detect';
import translate from './fnTranslate';
import { DAYS, MILLISECONDS, SECONDS } from './TimeUnit';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { PlayOption, PlayOptionsData } from '../types/CommonTypes';
import { isFuture } from './fnDate';
import { RecordingType } from '../types/RecordingTypes';
import { ModuleConfigs } from '../types/Config';
import {
    DetailEpisode,
    MovieDetails,
    ProgramDetails,
    RecordingDetails,
    ResolutionType,
    TVSeriesDetailEpisode,
    Episode,
} from '../types/Asset';
import {
    BitrateTrack,
    CatchupPlayerAssetInfo,
    RecordingPlayerAssetInfo,
    PlayingAssetType,
    Track,
    VodPlayerAssetInfo,
} from '../types/Player';
import { encodeId } from './fnUrl';
import { isCatchupPlayerAsset, isRecordingPlayerAsset } from './fnTypeGuards';

const notIgnoredVariables = ['collectionid', 'collectiontype'];

const mobileWidth = 240;
const mobileHeight = 135;

const tabletWidth = 288;
const tabletHeight = 162;

const desktopWidth = 400;
const desktopHeight = 225;

export const getParentRoute = (route: RouteChildrenProps['match'], params, setBackParamValues?) => {
    if (route) {
        let path = `${route.path}`;

        // is not collection page parent
        if (!path.startsWith('collection') && !path.startsWith('/collection')) {
            return path;
        }
        if (params && setBackParamValues) {
            // replace path variables with values
            // eslint-disable-next-line no-restricted-syntax
            for (const key in params) {
                if (key in params) {
                    if (key != null && notIgnoredVariables.indexOf(key) !== -1) {
                        path = path.replace(`:${key}`, params[key]);
                    }
                }
            }
        }
        return encodeURI(path);
    }

    return '/';
};

/**
 * Use this function for replay capability check
 *
 * @param start - start time of the broadcast in milliseconds
 * @param isChannelReplayCapable - boolean is replay capable (has HAS_REPLAY alias with TRUE value)
 * @param replayDays - number of replay days in the past (TimeMachine / TimeMachinePlusBased), or -1
 * if the user has no subscription tto that product
 */
export const isCatchUpCapable = (start: number, isChannelReplayCapable: boolean, replayDays: number) => {
    if (!isChannelReplayCapable || replayDays === -1) return false;
    return start >= Date.now() - DAYS.toMillis(replayDays);
};

/**
 * Use this function to transform camel case string to snake case
 *
 * @param str - input
 * @return snake cased string
 */
export const camelToSnakeCase = (str: string) => {
    let result = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    if (result.indexOf('_') === 0) {
        result = result.slice(1, result.length);
    }
    return result;
};

/**
 * Use to build episode title included the season / episode number with translation
 *
 * @param title
 * @param seasonNumber
 * @param episodeNumber
 */
export const buildEpisodeTitle = (title: string, seasonNumber?: number, episodeNumber?: number) => {
    const partSeason = `${seasonNumber != null && seasonNumber > 0 ? `${translate('HOVER_SEASON')}${seasonNumber} ` : ''}`;
    const partEpisode = `${episodeNumber != null ? `${translate('HOVER_EPISODE')}${episodeNumber} ` : ''}`;

    return `${partSeason}${partEpisode}${title}`;
};

/**
 * Find the highest possible length in config
 *
 */
export const getReplayWindowLength = () => {
    if (!ApplicationConfig?.app_config?.product_settings) {
        return -1;
    }

    return Object.keys(ApplicationConfig.app_config.product_settings)
        .filter(key => key.startsWith('replay_window'))
        .map(optionKey => ApplicationConfig.app_config.product_settings[optionKey])
        .sort((c1, c2) => c2 - c1)[0];
};

/**
 * Use this function to return the image remote path or the local image
 * according to de passed data
 *
 */

export const getLocalOrRemoteImagePath = (imagePath: string, images: {}) => {
    if (imagePath) {
        if (imagePath.startsWith('http')) {
            // is link
            return imagePath;
        }

        // is local image
        return images[imagePath];
    }

    return null;
};

/**
 * Find the replay window length by product
 *
 */
export const getReplayWindowLengthByProduct = (replayProduct: string) => {
    if (!replayProduct) return -1;
    const type = `replay_window_${camelToSnakeCase(replayProduct)}`;

    if (!ApplicationConfig.app_config.product_settings[type]) return -1;

    return ApplicationConfig.app_config.product_settings[type];
};

export const getPlayOptionByEventId = (option: PlayOptionsData, eventId: string): PlayOption => {
    return option?.options?.find(d => d.id === eventId);
};
const getPastOrRunningOptionsSorted = (option: PlayOptionsData): PlayOption[] => {
    if (!option || !option.options.length) {
        return [];
    }

    return (
        option.options
            // filter out future events
            ?.filter(opt => !isFuture(opt.startTime))
            // sort by time
            ?.sort((a, b) => b.startTime - a.startTime)
    );
};
const getPlayOptionFromClosestToNow = (option: PlayOptionsData): PlayOption => {
    return getPastOrRunningOptionsSorted(option)?.[0];
};
export const getPlayOptionFromClosestToNowFromEntitledChannel = (option: PlayOptionsData): PlayOption => {
    return getPastOrRunningOptionsSorted(option)?.filter(opt => opt.channel.subscription)?.[0] ?? getPlayOptionFromClosestToNow(option);
};

export const getUniquePlayOptions = (input: PlayOptionsData, replayWindowLength, referenceEvent: string, sort = false): PlayOption[] => {
    let filteredPlayOptions: PlayOption[] = [];

    if (input?.options) {
        input.options
            // filter out future options
            .filter(option => !isFuture(option.startTime))

            // filter out options out of replay window
            .filter(option => MILLISECONDS.toDays(Date.now() - option.startTime) <= replayWindowLength)

            .sort((a, b) => b.startTime - a.startTime)

            // filter out reruns
            .forEach(option => {
                const match = filteredPlayOptions.find(item => item.channel.id === option.channel.id);

                if (!match) {
                    filteredPlayOptions.push(option);
                } else if (referenceEvent && option.id === referenceEvent) {
                    const index = filteredPlayOptions.indexOf(match);
                    filteredPlayOptions[index] = option;
                }
            });

        filteredPlayOptions = filteredPlayOptions?.sort((a, b) => (sort ? b.startTime - a.startTime : 0));

        // sort not subscribed channels to the end
        filteredPlayOptions = filteredPlayOptions?.sort((a, b) => {
            if (a.channel.subscription && !b.channel.subscription) return -1;
            if (!a.channel.subscription && b.channel.subscription) return 1;
            return 0;
        });

        if (referenceEvent) {
            // move ref event to the first place
            const index = filteredPlayOptions.findIndex(option => option.id === referenceEvent);
            if (index > 0) {
                const match = filteredPlayOptions.splice(index, 1)?.[0];
                if (match) {
                    filteredPlayOptions.unshift(match);
                }
            }
        }
    }

    return filteredPlayOptions;
};

export const generateUUID = () => {
    const lut = [];
    for (let i = 0; i < 256; i += 1) {
        lut[i] = (i < 16 ? '0' : '') + i.toString(16);
    }
    const d0 = (Math.random() * 0xffffffff) | 0;
    const d1 = (Math.random() * 0xffffffff) | 0;
    const d2 = (Math.random() * 0xffffffff) | 0;
    const d3 = (Math.random() * 0xffffffff) | 0;
    return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${lut[d1 & 0xff]}${
        lut[(d1 >> 8) & 0xff]
    }-${lut[((d1 >> 16) & 0x0f) | 0x40]}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${lut[(d2 >> 8) & 0xff]}-${
        lut[(d2 >> 16) & 0xff]
    }${lut[(d2 >> 24) & 0xff]}${lut[d3 & 0xff]}${lut[(d3 >> 8) & 0xff]}${lut[(d3 >> 16) & 0xff]}${lut[(d3 >> 24) & 0xff]}`;
};

export const dynamicCollectionIdToTitle = (id: string): string => {
    if (!id) return null;

    const split = id.split('-');
    if (!split.length) return null;

    return split[split.length - 1].replace(/\+/g, ' ');
};

export const getRecordingIconName = (type: RecordingType) => {
    switch (type) {
        case 'RECORDED':
            return 'icoRecordedRecording';
        case 'ONGOING':
            return 'icoOngoingRecording';
        case 'SCHEDULED':
            return 'icoScheduledRecording';
        default:
            return null;
    }
};

/**
 * Clones the module configurations and injects the details and the imdb rating receiver
 *
 * @param details
 * @param pageModules
 * @param ratingReceiver
 * @param itemsReceiver
 */
export const detailPageModuleResolver = (
    details: RecordingDetails | ProgramDetails | MovieDetails | DetailEpisode | TVSeriesDetailEpisode,
    pageModules,
    ratingReceiver,
    itemsReceiver?
): ModuleConfigs[] => {
    let modules: ModuleConfigs[] = null;

    if (details) {
        modules = cloneDeep(pageModules);
        modules.forEach(module => {
            if (module.datasource.request) {
                if (!module.datasource.request.variables) {
                    module.datasource.request.variables = {};
                }

                // eslint-disable-next-line dot-notation
                module.datasource.request.variables['details'] = details;

                // eslint-disable-next-line dot-notation
                module.datasource.request.variables['ratingReceiver'] = ratingReceiver;

                if (itemsReceiver) {
                    // eslint-disable-next-line dot-notation
                    module.datasource.request.variables['itemsReceiver'] = itemsReceiver;
                }
            }
        });
    }

    return modules;
};

export const getResolution = (resolution: any, isHD: boolean): ResolutionType => {
    if (ResolutionType[resolution]) {
        return resolution;
    }

    return isHD ? ResolutionType.HD : ResolutionType.SD;
};

export const getCurrencySymbol = (currencyName: string): string => {
    const nameSymbolMap = {
        USD: '$', // US Dollar
        EUR: '€', // Euro
        CRC: '₡', // Costa Rican Colón
        GBP: '£', // British Pound Sterling
        ILS: '₪', // Israeli New Sheqel
        INR: '₹', // Indian Rupee
        JPY: '¥', // Japanese Yen
        KRW: '₩', // South Korean Won
        NGN: '₦', // Nigerian Naira
        PHP: '₱', // Philippine Peso
        PLN: 'zł', // Polish Zloty
        PYG: '₲', // Paraguayan Guarani
        THB: '฿', // Thai Baht
        UAH: '₴', // Ukrainian Hryvnia
        VND: '₫', // Vietnamese Dong
    };

    if (!currencyName) return ApplicationConfig.app_config.locale_settings.currency_sign;
    if (!(currencyName in nameSymbolMap)) return ApplicationConfig.app_config.locale_settings.currency_sign;
    return nameSymbolMap[currencyName];
};

export const getDefaultTrackStyling = () => {
    const style = new window.chrome.cast.media.TextTrackStyle();
    style.backgroundColor = '#00000000';
    style.edgeType = window.chrome.cast.media.TextTrackEdgeType.DROP_SHADOW;
    style.fontGenericFamily = window.chrome.cast.media.TextTrackFontGenericFamily.SANS_SERIF;
    style.edgeColor = '#000000';
    style.fontStyle = window.chrome.cast.media.TextTrackFontStyle.BOLD;
    return style;
};

export const compareTracks = (a: Track, b: Track) => {
    if ((a == null && b != null) || (b == null && a != null)) return false;
    return a.language === b.language && a.role === b.role && a.channelsCount === b.channelsCount;
};

export const compareBitrateTracks = (a: BitrateTrack, b: BitrateTrack) => {
    if ((a == null && b != null) || (b == null && a != null)) return false;
    return a.height === b.height;
};

export const getInitials = fullName => {
    const names = fullName.split(' ');
    const initials = names.map(initial => {
        return initial.charAt(0);
    });
    return initials.join('');
};

export const getLanguage = (code: string, translateKey: string) => {
    if (translateKey.startsWith('LANGUAGE')) {
        return code;
    }
    return translateKey;
};

export const getThumbnailSize = (thumb: any) => {
    const width = isMobile ? mobileWidth : isTablet ? tabletWidth : desktopWidth;
    const height = isMobile ? mobileHeight : isTablet ? tabletHeight : desktopHeight;

    const { uris, positionX, positionY } = thumb;

    const widthPositionX = isMobile ? positionX * 1.5 : isTablet ? positionX * 1.8 : positionX * 2.5;
    const heightPositionY = isMobile ? positionY * 1.5 : isTablet ? positionY * 1.8 : positionY * 2.5;

    // eslint-disable-next-line prefer-const
    let [path, query] = uris[0]?.split('?');

    if (query) {
        query = `sx=${width}&sy=${height}&scale=${width}x${height}`;
    } else {
        query = `sx=${width}&sy=${height}`;
    }

    const url = [path, query].join('?');

    return {
        ...thumb,
        width,
        height,
        uris: [url],
        positionX: widthPositionX,
        positionY: heightPositionY,
    };
};

export const getNextEpisode = (seasons: any, currentSeason: number, currentEpisode: number) => {
    const season = seasons?.find(item => item.number === currentSeason);
    let nextEpisode = season?.episodes.find(item => item.episodeNumber === currentEpisode + 1);

    if (!nextEpisode) {
        const nextSeason = seasons?.find(item => item.number === currentSeason + 1);

        if (nextSeason) {
            nextEpisode = nextSeason?.episodes[0];
            return nextEpisode;
        }
        return null;
    }

    return nextEpisode;
};

export const getMiniPlayerProps = (asset: any, type?: PlayingAssetType) => {
    let inferredType = type;

    if (!inferredType) {
        if (asset.channelId) {
            inferredType = PlayingAssetType.LIVE;
        } else if (asset.eventId) {
            inferredType = PlayingAssetType.CATCHUP;
        } else if (asset.episodeId) {
            inferredType = PlayingAssetType.VOD;
        } else if (asset.id) {
            inferredType = PlayingAssetType.RECORDING;
        }
    }

    if (!inferredType) {
        return null;
    }

    switch (inferredType) {
        case PlayingAssetType.VOD:
        case PlayingAssetType.TRAILER:
            return { type: inferredType, params: { assetId: encodeId(asset.episodeId ?? asset.id) } };
        case PlayingAssetType.CATCHUP:
            return { type: inferredType, params: { broadcastId: encodeId(asset.eventId ?? asset.id) } };
        case PlayingAssetType.LIVE:
            return { type: inferredType, params: { channelId: encodeId(asset.channelId) } };
        case PlayingAssetType.RECORDING:
            return { type: inferredType, params: { recordingId: encodeId(asset.id) } };
        default:
            return null;
    }
};

export const selectDefaultSubtitles = (
    prefferedSubtitles: { primarySubtitlesLanguage: string },
    subtitleTracks: Track[],
    accessibility: boolean,
    hohRoles: string[]
) => {
    const primaryLanguage = subtitleTracks.filter(track => track.language === prefferedSubtitles.primarySubtitlesLanguage);

    let accessibilityTrack: Track;

    if (accessibility) {
        accessibilityTrack =
            primaryLanguage.find(track => hohRoles.includes(track.role)) ?? subtitleTracks.find(track => hohRoles.includes(track.role));
    }

    return accessibilityTrack ?? primaryLanguage[0] ?? subtitleTracks[0];
};

export const selectDefaultAudio = (
    prefferedAudio: { primaryAudioLanguage: string },
    audioTracks: Track[],
    accessibility: boolean,
    visuallyImpairedRoles: string[]
) => {
    const primaryLanguage = audioTracks.filter(track => track.language === prefferedAudio.primaryAudioLanguage);
    let accessibilityTrack: Track;

    if (accessibility) {
        accessibilityTrack =
            primaryLanguage.find(track => visuallyImpairedRoles.includes(track.role)) ??
            audioTracks.find(track => visuallyImpairedRoles.includes(track.role));
    }

    return accessibilityTrack ?? primaryLanguage[0] ?? audioTracks[0];
};

export const isAssetWithinPaddingWindow = (playingAsset: CatchupPlayerAssetInfo | RecordingPlayerAssetInfo) => {
    if (isCatchupPlayerAsset(playingAsset) || isRecordingPlayerAsset(playingAsset)) {
        const { endTime, prePadding, postPadding } = playingAsset;

        const paddingTime = SECONDS.toMillis(postPadding ?? 0) + SECONDS.toMillis(prePadding ?? 0);
        const now = Date.now();
        if (endTime + paddingTime > now) {
            return true;
        }
    }
    return false;
};

export const isEpisodeTrailer = (episode: Episode | DetailEpisode | TVSeriesDetailEpisode | VodPlayerAssetInfo) => {
    if (episode?.titleId?.includes('preview')) {
        return true;
    }
    return false;
};

export const hasProgressBar = (progressBar: ReactNode) => {
    const progressBarComp = progressBar as Component<{ percentage?: number; startTime?: number }>;
    return !!progressBarComp?.props?.percentage || !!progressBarComp?.props?.startTime;
};

export const secondsToTimeString = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    let timeString = '';

    if (hours > 0) {
        timeString += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
    }
    if (minutes > 0) {
        timeString += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }

    return timeString || '0 minutes';
};

import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight, LaneScroller, LaneWrapper, paddingDesktop, paddingMobile, paddingTablet } from './Lane.css';
import { isMQDesktop, isMQTablet } from '../../style/styled-components/cssMediaQueries';

type LaneProps = {
    containerExtraClassName?: string;
    scrollerExtraClassName?: string;
    arrowLeft: any;
    arrowRight: any;
    scrollLeft?: number;
    keepArrows?: boolean;
    showArrowsWhenActive?: boolean;
    renderNavigationArrows?: boolean;
    infinite?: boolean;
    auto?: boolean;
    autoTimeout?: number;
    navigateTo?: {
        number: number;
        smooth: boolean;
    };
    navigateByViewPort?: boolean;
    useSnap?: boolean;
    withoutPadding?: boolean;
    scrollable?: boolean;
    onVisibleIndexesChanged?: (first: number, last: number) => void;
    onScrollRightEdgeReached?: () => void;
    onScrollLeftEdgeReached?: () => void;
    onScrollStopped?: (scrollPosition) => void;
    scrolledPercentage?: (percentage) => void;
    isMobile?: boolean;
};

const SCROLL_STOP_TIMEOUT = 100;
const CLASS_NAME_ACTIVE = 'active';
const CLASS_NAME_PARTIAL = 'partial';
const CLASS_NAME_FIRST_PARTIAL = 'first-partial';
const CLASS_NAME_TO_LEFT = 'to-left';
const CLASS_NAME_TO_RIGHT = 'to-right';
const CLASS_NAME_SMOOTH = 'smooth';

export const Lane: FC<LaneProps> = ({
    containerExtraClassName,
    scrollerExtraClassName,
    arrowLeft,
    arrowRight,
    scrollLeft = 0,
    keepArrows = false,
    showArrowsWhenActive = false,
    renderNavigationArrows = true,
    infinite = false,
    auto = false,
    autoTimeout = 4000,
    navigateTo = null,
    navigateByViewPort = false,
    useSnap = false,
    withoutPadding = false,
    scrollable = false,
    isMobile = false,
    onVisibleIndexesChanged,
    onScrollStopped,
    scrolledPercentage,
    onScrollLeftEdgeReached,
    onScrollRightEdgeReached,
    children,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollerRef = useRef<HTMLDivElement>(null);

    const scrollTimeout = useRef(null);
    const autoScrollInterval = useRef(null);
    const canNavigate = useRef(true);

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [hovered, setHovered] = useState(false);
    const scrollPadding = useRef(paddingMobile);

    const fullScreen = infinite;

    const childCount = React.Children.count(children);

    const updateVisibleArea = () => {
        scrollPadding.current = paddingMobile;

        if (isMQTablet()) {
            scrollPadding.current = paddingTablet;
        }

        if (isMQDesktop()) {
            scrollPadding.current = paddingDesktop;
        }

        if (fullScreen || withoutPadding) {
            scrollPadding.current = 2;
        }

        const visibleAreaStart = scrollerRef.current.scrollLeft;
        const visibleAreaEnd = visibleAreaStart + scrollerRef.current.clientWidth;

        let firstVisibleItemIndex = -1;
        let lastVisibleItemIndex = childCount;

        scrollerRef.current.childNodes.forEach((node, index) => {
            const divNode = node as HTMLDivElement;
            let isActive = false;

            divNode.classList.remove(CLASS_NAME_ACTIVE, CLASS_NAME_PARTIAL, CLASS_NAME_FIRST_PARTIAL);

            if (divNode.offsetLeft >= visibleAreaStart && divNode.offsetLeft + divNode.clientWidth < visibleAreaEnd) {
                isActive = true;
                divNode.classList.add(CLASS_NAME_ACTIVE);

                if (firstVisibleItemIndex === -1) {
                    firstVisibleItemIndex = index;
                }

                lastVisibleItemIndex = index;
            }

            if (!isActive && divNode.offsetLeft >= visibleAreaStart && divNode.offsetLeft <= visibleAreaEnd) {
                divNode.classList.add(CLASS_NAME_PARTIAL);
                if (index > lastVisibleItemIndex) {
                    lastVisibleItemIndex = index;
                }
            }

            if (divNode.offsetLeft < visibleAreaStart && divNode.offsetLeft + divNode.offsetWidth >= visibleAreaStart) {
                divNode.classList.add(CLASS_NAME_FIRST_PARTIAL);
                if (index < firstVisibleItemIndex) {
                    firstVisibleItemIndex = index;
                }
            }
        });

        if (onVisibleIndexesChanged) {
            onVisibleIndexesChanged(firstVisibleItemIndex, lastVisibleItemIndex);
        }

        if (scrollerRef?.current) {
            const scroller = scrollerRef.current;

            setShowLeftArrow(scroller.scrollLeft > (keepArrows ? 0 : scrollPadding.current));
            setShowRightArrow(Math.round(scroller.scrollLeft + scroller.clientWidth) < scroller.scrollWidth);
        }
    };

    const getLastPartiallyVisible = (): HTMLDivElement | null => {
        const partials = scrollerRef.current.getElementsByClassName(CLASS_NAME_PARTIAL);
        return partials?.length ? (partials.item(0) as HTMLDivElement) : null;
    };

    const getFirstPartiallyVisible = (): HTMLDivElement | null => {
        const partials = scrollerRef.current.getElementsByClassName(CLASS_NAME_FIRST_PARTIAL);
        return partials?.length ? (partials.item(0) as HTMLDivElement) : null;
    };

    const getFirstFullyVisible = (): HTMLDivElement | null => {
        const actives = scrollerRef.current.getElementsByClassName(CLASS_NAME_ACTIVE);
        return actives?.length ? (actives.item(0) as HTMLDivElement) : null;
    };

    const scrollTo = (index: number, smooth = true) => {
        const itemCount = scrollerRef.current.childNodes.length;

        if (index < 0 || index > itemCount - 1) {
            console.log(`Invalid navigation index. The index can be between 0 and ${itemCount - 1}`);
        }

        const targetItem = scrollerRef.current.childNodes.item(index) as HTMLDivElement;
        if (!targetItem) {
            return;
        }

        if (smooth) {
            scrollerRef.current.classList.add(CLASS_NAME_SMOOTH);
        }

        scrollerRef.current.scrollLeft = targetItem.offsetLeft - scrollPadding.current;
    };

    const scroll = (to: number) => {
        if (!canNavigate.current) {
            return;
        }

        canNavigate.current = false;
        scrollerRef.current.scrollBy({ left: to, behavior: 'smooth' });
    };

    const getRightScrollWidth = () => {
        // try to find the first not fully visible item
        const match: HTMLDivElement = !navigateByViewPort && getLastPartiallyVisible();

        if (match && !fullScreen) {
            if (useSnap) {
                return match.offsetLeft - scrollerRef.current.scrollLeft;
            }

            return match.offsetLeft - scrollerRef.current.scrollLeft - scrollPadding.current;
        }

        // in case there is no match scroll with view-port width
        const viewPortWidth = wrapperRef.current.clientWidth;
        return viewPortWidth - scrollPadding.current;
    };

    const onRightClicked = () => {
        scrollerRef.current.classList.add(CLASS_NAME_TO_RIGHT);
        scroll(getRightScrollWidth());
    };

    const onScrollStop = () => {
        if (!scrollerRef.current) return;

        if (onScrollStopped) {
            onScrollStopped(scrollerRef.current.scrollLeft);
        }

        canNavigate.current = true;

        scrollerRef.current.classList.remove(CLASS_NAME_TO_LEFT, CLASS_NAME_TO_RIGHT, CLASS_NAME_SMOOTH);

        updateVisibleArea();

        if (infinite && fullScreen && childCount > 1) {
            const viewPortWidth = scrollerRef.current.clientWidth;
            const activeIndex = Math.round(scrollerRef.current.scrollLeft / viewPortWidth);

            if (activeIndex > 2 * childCount || activeIndex < childCount) {
                let scrollToIdx = -1;
                if (activeIndex > 2 * childCount) {
                    scrollToIdx = activeIndex - childCount;
                }

                if (activeIndex < childCount) {
                    scrollToIdx = activeIndex + childCount;
                }
                if (scrollToIdx !== -1) {
                    // eslint-disable-next-line no-use-before-define
                    scrollTo(scrollToIdx, false);
                }
            }
        }

        if (auto && childCount > 1) {
            if (autoScrollInterval.current) {
                clearInterval(autoScrollInterval.current);
            }
            autoScrollInterval.current = setInterval(() => {
                // eslint-disable-next-line no-use-before-define
                onRightClicked();
            }, autoTimeout);
        }

        if (scrollerRef?.current) {
            const scroller = scrollerRef.current;

            if ((scroller.scrollLeft === 0 || scroller.scrollLeft === scrollPadding.current) && onScrollLeftEdgeReached) {
                onScrollLeftEdgeReached();
            }

            if (Math.round(scroller.scrollLeft + scroller.clientWidth) === scroller.scrollWidth && onScrollRightEdgeReached) {
                onScrollRightEdgeReached();
            }

            if (scrolledPercentage) {
                scrolledPercentage((scroller.scrollLeft * 100) / (scroller.scrollWidth - scroller.clientWidth));
            }
        }
    };

    const onScroll = () => {
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
            scrollTimeout.current = null;
        }

        if (autoScrollInterval.current) {
            clearInterval(autoScrollInterval.current);
        }

        scrollTimeout.current = setTimeout(() => {
            onScrollStop();
        }, SCROLL_STOP_TIMEOUT);
    };

    const getLeftScrollWidth = () => {
        const viewPortWidth = wrapperRef.current.clientWidth;
        const scroller = scrollerRef.current;

        if (useSnap) {
            // try to find the first not fully visible item on the left
            const firstPartial = !navigateByViewPort && getFirstPartiallyVisible();
            if (firstPartial && !fullScreen) {
                return -Math.min(
                    scroller.clientWidth + scroller.scrollLeft - firstPartial.offsetLeft - firstPartial.clientWidth,
                    viewPortWidth + scrollPadding.current
                );
            }

            // try to find the first not fully visible item on the right
            const match: HTMLDivElement = !navigateByViewPort && getLastPartiallyVisible();

            if (match && !fullScreen) {
                return scroller.scrollLeft - match.offsetLeft;
            }

            return -viewPortWidth + scrollPadding.current;
        }

        const firstActive = getFirstFullyVisible();
        const maximumScrollLength = viewPortWidth - 2 * scrollPadding.current;

        // find the active index
        let activeIndex = scroller.children.length;
        for (let i = scroller.children.length; i > 0; i -= 1) {
            if (scroller.children.item(i) === firstActive) {
                activeIndex = i;
                break;
            }
        }

        // find the last item which fits
        let helper = firstActive.offsetWidth;
        let lastItemFulfill: HTMLDivElement = null;
        for (let i = activeIndex; i > 0; i -= 1) {
            const previousItem = scroller.children.item(i - 1) as HTMLDivElement;

            if (helper + previousItem.offsetWidth < maximumScrollLength) {
                helper += previousItem.offsetWidth;
                lastItemFulfill = previousItem;
            } else {
                break;
            }
        }

        return -(scroller.scrollLeft - (lastItemFulfill?.offsetLeft ?? 0) + scrollPadding.current);
    };

    const onLeftClicked = () => {
        scrollerRef.current.classList.add(CLASS_NAME_TO_LEFT);
        scroll(getLeftScrollWidth());
    };

    const onMouseEnter = () => {
        if (hovered) {
            return;
        }
        setHovered(true);
    };

    const onMouseLeave = () => {
        if (!hovered) {
            return;
        }
        setHovered(false);
    };

    const renderSlides = () => {
        if (!infinite || childCount < 2) {
            return children;
        }

        const childrenArray = React.Children.toArray(children);

        return [...childrenArray, ...childrenArray, ...childrenArray].map((childItem, index) => {
            return React.cloneElement(childItem as ReactElement, {
                key: `lane-item-${index}`,
            });
        });
    };

    useEffect(() => {
        scrollerRef.current.scrollLeft = scrollLeft;
    }, [scrollLeft]);

    useEffect(() => {
        if (navigateTo != null) {
            scrollTo(navigateTo.number, navigateTo.smooth);
        }
    }, [navigateTo]);

    useEffect(() => {
        if (childCount < 2) {
            return;
        }

        if (hovered) {
            if (autoScrollInterval.current) {
                clearInterval(autoScrollInterval.current);
            }
        } else if (auto) {
            if (autoScrollInterval.current) {
                clearInterval(autoScrollInterval.current);
            }

            autoScrollInterval.current = setInterval(() => {
                onRightClicked();
            }, autoTimeout);
        }
    }, [hovered]);

    useEffect(() => {
        if (infinite && fullScreen && childCount > 1) {
            // scroll in the middle initially
            scrollTo(childCount, false);
        }
    }, [infinite, fullScreen]);

    useEffect(() => {
        const scroller = scrollerRef.current;
        setShowRightArrow(Math.round(scroller.scrollLeft + scroller.clientWidth) < scroller.scrollWidth);

        updateVisibleArea();
    }, [children]);

    useEffect(() => {
        window.addEventListener('resize', updateVisibleArea);
        return () => {
            window.removeEventListener('resize', updateVisibleArea);

            if (autoScrollInterval.current) {
                clearInterval(autoScrollInterval.current);
            }
        };
    }, []);

    let renderLeftArrow = ((showLeftArrow && renderNavigationArrows) || keepArrows) && childCount > 1 && !isMobile;
    let renderRightArrow = ((showRightArrow && renderNavigationArrows) || keepArrows) && childCount > 1 && !isMobile;

    if (!showLeftArrow && !showRightArrow) {
        renderLeftArrow = false;
        renderRightArrow = false;
    }

    return (
        <LaneWrapper className={containerExtraClassName || ''} ref={wrapperRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <LaneScroller
                isFullScreen={fullScreen}
                useSnap={useSnap || isMobile}
                scrollable={scrollable || isMobile}
                className={scrollerExtraClassName || ''}
                ref={scrollerRef}
                onScroll={onScroll}
            >
                {renderSlides()}
            </LaneScroller>
            {renderLeftArrow && (
                <ArrowLeft
                    alwaysOn={keepArrows || (showArrowsWhenActive && showLeftArrow)}
                    active={showLeftArrow}
                    onClick={() => {
                        if (!showLeftArrow) {
                            return;
                        }
                        onLeftClicked();
                    }}
                >
                    {arrowLeft}
                </ArrowLeft>
            )}
            {renderRightArrow && (
                <ArrowRight
                    alwaysOn={keepArrows || (showArrowsWhenActive && showRightArrow)}
                    active={showRightArrow}
                    onClick={() => {
                        if (!showRightArrow) {
                            return;
                        }
                        onRightClicked();
                    }}
                >
                    {arrowRight}
                </ArrowRight>
            )}
        </LaneWrapper>
    );
};

import React, { FC, useEffect, useMemo, useState, useRef, Suspense, lazy } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';

import {
    buildPlayerRoutes,
    buildSectionCollectionRouting,
    buildSectionDetailRouting,
    createPageRoutes,
    buildLanguageSettingsRoutes,
} from 'utils/fnRouting';
import { SECONDS } from 'utils/TimeUnit';

import { styled } from './ThemeConfigConnector/ThemeConfigConnnector';
import { CookieNotification } from './CookieNotification/CookieNotification';
import { PageLayout } from './PageLayout/PageLayout';
import { SplashScreen } from './SplashScreen/SplashScreen';
import ApplicationConfig from '../providers/useConfig/ApplicationConfig';
import { useConfig } from '../providers/useConfig/ConfigContext';
import { DeviceLimitAlert, DeviceRegistrationError, GuestAlert, SignOut, useAuth } from '../providers/useAuth/AuthContext';
import { usePurchase, usePurchaseFlowScreenProvider } from '../providers/usePurchase/PurchaseContext';
import { useApp, useAppHistory } from '../providers/useApp/AppContext';
import { useWorldContentChangeObserver } from '../providers/useWorld/WorldContext';
import { StaticPageRoutes } from '../types/RouteTypes';
import { SettingsOptions } from '../types/Config';
import { useExpiredDataCleaner } from '../hooks/useExpiredDataCleaner/useExpiredDataCleaner';
import { PurchasePinDialog } from '../providers/usePin/PinContext';
import { PlayOptionSelector } from './PlayOptionScreen/PlayOptionScreen';
import { useSubscribeFlowScreenProvider, useSubscription } from '../providers/useSubscription/SubscriptionContext';
import { useUILanguageChangedObserver } from '../hooks/useUILanguageChangedObserver/useUILanguageChangedObserver';
import { DevPage } from './Pages/DevPage/DevPage';
import {
    CreateRecordingError,
    DeleteRecordingConfirmation,
    ManageRecordingLoadingOverlay,
    useRecording,
    useRecordingContentChangeObserver,
} from '../providers/useRecording/RecordingContext';
import { CastMiniController } from './CastMiniController/CastMiniController';
import { Player } from './Player/Player';
import { DeleteAllRecordingsConfirmation } from './Pages/Settings/PageRecordings/PageRecordingsSettings';
import { SmartAppBanner } from './SmartAppBanner/SmartAppBanner';
import translate from '../utils/fnTranslate';
import { MaintenanceAlert } from './Maintenance/MaintenanceAlert';
import { LocalStorage } from '../types/Storage';
import { getLocalStorage, setLocalStorage } from '../utils/fnStorage';
import { HintModal } from '../providers/useHint/HintContext';
import { AccountSettingsPage } from './Pages/Settings/PageSettings/AccountSettings';
import PagePinProtection from './Pages/Settings/PagePinProtection/PagePinProtection';
import PageAccessibility from './Pages/Settings/PageAccessibility/PageAccessibility';

const CONFIG_UPDATE_APPLIED = 'CONFIG_UPDATE_APPLIED';

const PageDefault = lazy(() => import('./Pages/PageDefault/PageDefault'));
const PageSettings = lazy(() => import('./Pages/Settings/PageSettings/PageSettings'));
const PageAbout = lazy(() => import('./Pages/Settings/PageAbout/PageAbout'));
const PageRecordingsSettings = lazy(() => import('./Pages/Settings/PageRecordings/PageRecordingsSettings'));
const PageAutoPlay = lazy(() => import('./Pages/Settings/pageAutoplay/PageAutoPlay'));
const PageDeviceManager = lazy(() => import('./Pages/PageDeviceManager/PageDeviceManager'));
const PageErrorNotFound = lazy(() => import('./Pages/PageErrorNotFound/PageErrorNotFound'));

export const SETTINGS_PIN = 'SETTINGS_PIN';
export const SETTINGS_RECORDINGS = 'SETTINGS_RECORDINGS';
export const SETTINGS_AUTOPLAY = 'SETTINGS_AUTOPLAY';
export const SETTINGS_ABOUT_APP = 'SETTINGS_ABOUT_APP';
export const SETTINGS_LANGUAGE = 'SETTINGS_LANGUAGE';
export const SETTINGS_DEVICE_MANAGEMENT = 'SETTINGS_DEVICE_MANAGEMENT';
export const SETTINGS_ACCOUNT = 'SETTINGS_ACCOUNT';
export const SETTINGS_ACCESSIBILITY = 'SETTINGS_ACCESSIBILITY';

const RentSubscribeScreenProvider: FC = () => {
    const { cancel: exitRentingFlow } = usePurchase();
    const { cancel: exitSubscribeFlow } = useSubscription();
    const { pathname } = useLocation();

    const rentingScreen = usePurchaseFlowScreenProvider();
    const subscribeScreen = useSubscribeFlowScreenProvider();

    useEffect(() => {
        return () => {
            exitSubscribeFlow();
            exitRentingFlow();
        };
    }, [pathname]);

    return (
        <React.Fragment>
            {rentingScreen()}
            {subscribeScreen()}
        </React.Fragment>
    );
};

const NavigationContainer = styled.div<{ blur?: boolean; isSmartBannerOpen: boolean }>`
    filter: ${props => (props.blur ? 'blur(10px)' : 'none')};
    padding-top: ${props => (props.isSmartBannerOpen ? '80' : '0')}px;
`;

const BuildPageRoutes: React.FC<{ config: typeof ApplicationConfig; isSmartBannerOpen: boolean }> = ({ config, isSmartBannerOpen }) => {
    const pageRoutes = useMemo(() => createPageRoutes(config), [config]);
    const location = useLocation();
    const { authApiInitialized, isLoggedIn, isGuest, hasNPVR } = useAuth();
    const { appLanguage } = useApp();

    const enableRoutes = authApiInitialized && isLoggedIn;

    const history = useHistory();

    const isSettingOptionEnabled = (id: SettingsOptions): boolean => {
        return isLoggedIn && config?.content_config?.settings_menu?.find(item => item.id === id) != null;
    };

    useEffect(() => {
        return history.listen(() => {
            if (getLocalStorage(CONFIG_UPDATE_APPLIED as keyof LocalStorage) === false) {
                setLocalStorage(CONFIG_UPDATE_APPLIED as keyof LocalStorage, true);
                window.location.reload();
            }
        });
    }, [history]);

    return (
        <Suspense fallback={null}>
            <Switch>
                {/* Pages without header / footer / page layout */}

                {config?.isFeatureActive('dev_mode') && <Route component={DevPage} path={StaticPageRoutes.DEV} exact={true} />}

                {/* Player */}
                {buildPlayerRoutes(enableRoutes)}

                {enableRoutes && (
                    <PageLayout pathname={location.pathname}>
                        <NavigationContainer isSmartBannerOpen={isSmartBannerOpen}>
                            <Switch>
                                {buildSectionDetailRouting(appLanguage)}

                                {/* CollectionGrid page */}
                                {buildSectionCollectionRouting()}

                                {isLoggedIn && <Route component={PageSettings} exact={true} path={StaticPageRoutes.SETTINGS} />}
                                {isSettingOptionEnabled(SETTINGS_LANGUAGE) && buildLanguageSettingsRoutes()}
                                {isSettingOptionEnabled(SETTINGS_ABOUT_APP) && (
                                    <Route exact={true} component={PageAbout} path={StaticPageRoutes.ABOUT} />
                                )}
                                {isSettingOptionEnabled(SETTINGS_RECORDINGS) && !isGuest && hasNPVR() && (
                                    <Route exact={true} component={PageRecordingsSettings} path={StaticPageRoutes.RECORDINGS_SETTINGS} />
                                )}
                                {isSettingOptionEnabled(SETTINGS_AUTOPLAY) && !isGuest && (
                                    <Route exact={true} component={PageAutoPlay} path={StaticPageRoutes.AUTOPLAY} />
                                )}
                                {isSettingOptionEnabled(SETTINGS_DEVICE_MANAGEMENT) && (
                                    <Route exact={true} component={PageDeviceManager} path={StaticPageRoutes.DEVICE_MANAGER} />
                                )}
                                {isSettingOptionEnabled(SETTINGS_ACCOUNT) && !isGuest && (
                                    <Route exact={true} component={AccountSettingsPage} path={StaticPageRoutes.ACCOUNT} />
                                )}
                                {isSettingOptionEnabled(SETTINGS_ACCOUNT) && !isGuest && (
                                    <Route exact={true} component={PagePinProtection} path={StaticPageRoutes.PIN_PROTECTION} />
                                )}
                                {isSettingOptionEnabled(SETTINGS_ACCOUNT) && (
                                    <Route exact={true} component={PageAccessibility} path={StaticPageRoutes.ACCESSIBILITY} />
                                )}

                                {/* Generated from config */}
                                {pageRoutes.map((route, key) => {
                                    if (route.path === StaticPageRoutes.SEARCH && !config.isFeatureActive('search')) {
                                        return null;
                                    }

                                    const PageType = route.component || PageErrorNotFound;
                                    return (
                                        <Route
                                            key={key}
                                            path={route.path}
                                            render={props => {
                                                return <PageType {...{ ...route.pageConfig, ...props }} />;
                                            }}
                                            exact
                                        />
                                    );
                                })}
                                <Route component={PageDefault} />
                            </Switch>
                        </NavigationContainer>
                    </PageLayout>
                )}
            </Switch>
        </Suspense>
    );
};

export const Application: React.FC = () => {
    const { config, translations } = useConfig();
    const {
        isLoggedIn,
        authApiInitialized,
        purchasedProductsLoaded,
        loadPurchasedProducts,
        closeGuestAlert,
        closeLogOutAlert,
        logUserOut,
        isGuest,
        isDeviceLimitReached,
        deviceRegistrationError,
    } = useAuth();
    const { resetApi } = usePurchase();
    const { resetSubscriptionOffers } = useSubscription();
    const [isAppBooted, setIsAppBooted] = useState(false);
    const { setToDelete, setError } = useRecording();
    const [isSmartBannerOpen, setIsSmartBannerOpen] = useState<boolean>(false);
    const [isMaintenanceActive, setIsMaintenanceActive] = useState<boolean>(false);
    const maintenanceStart = useRef(null);
    const maintenanceEnd = useRef(null);
    const maintenanceTimeout = useRef(null);

    useWorldContentChangeObserver();
    useRecordingContentChangeObserver();
    useUILanguageChangedObserver();
    useAppHistory();
    useExpiredDataCleaner();

    const accessGranted = authApiInitialized && isLoggedIn;

    const closeAlerts = () => {
        closeGuestAlert();
        closeLogOutAlert();
        resetApi();
        setError(null);
        resetSubscriptionOffers();
        setToDelete(null);
    };

    useEffect(() => {
        window.onpopstate = closeAlerts;
    }, []);

    useEffect(() => {
        if (config && translations) {
            maintenanceStart.current = SECONDS.toMillis(config?.api_config?.maintenance_mode?.start);
            maintenanceEnd.current = SECONDS.toMillis(config?.api_config?.maintenance_mode?.end);
            const now = Date.now();

            if (maintenanceStart.current && maintenanceEnd.current && !isMaintenanceActive) {
                if (maintenanceStart.current > now && now < maintenanceEnd.current) {
                    if (maintenanceTimeout.current) {
                        clearTimeout(maintenanceTimeout.current);
                    }
                    maintenanceTimeout.current = setTimeout(() => {
                        if (isLoggedIn) {
                            logUserOut(false, true);
                        }
                        setIsMaintenanceActive(true);
                    }, maintenanceStart.current - now);
                } else if (maintenanceStart.current < now && now < maintenanceEnd.current) {
                    if (isLoggedIn) {
                        logUserOut(false, true);
                    }
                    setIsMaintenanceActive(true);
                }
            }
        }
    }, [config, translations, isLoggedIn]);

    useEffect(() => {
        if (!isMaintenanceActive) {
            if (config && !purchasedProductsLoaded && accessGranted) {
                loadPurchasedProducts();
            }

            if (config && translations && accessGranted && purchasedProductsLoaded) {
                // add other boot things here
                setIsAppBooted(true);
            }
        }
    }, [config, translations, purchasedProductsLoaded, isLoggedIn, authApiInitialized, isMaintenanceActive]);

    if (isMaintenanceActive) {
        return (
            <MaintenanceAlert
                startTime={maintenanceStart.current}
                endTime={maintenanceEnd.current}
                hideMaintenance={() => {
                    setIsMaintenanceActive(false);
                }}
            />
        );
    }

    if (!isAppBooted || !authApiInitialized) {
        return <SplashScreen />;
    }

    if (isDeviceLimitReached) {
        return <DeviceLimitAlert />;
    }

    if (deviceRegistrationError) {
        return <DeviceRegistrationError />;
    }

    return (
        <>
            <BuildPageRoutes config={config} isSmartBannerOpen={isSmartBannerOpen} />

            <Player />

            <CastMiniController />

            {/* Notifications */}
            <CookieNotification />

            <RentSubscribeScreenProvider />

            {!isGuest && <HintModal />}

            <PurchasePinDialog />
            <PlayOptionSelector />

            <DeleteRecordingConfirmation />
            <DeleteAllRecordingsConfirmation />
            <ManageRecordingLoadingOverlay />
            <CreateRecordingError />

            <GuestAlert />
            <SignOut />

            <SmartAppBanner
                name={translate('APP_BANNER_TITLE')}
                hintText={translate('APP_BANNER_HINT')}
                onOpen={() => setIsSmartBannerOpen(true)}
                onClose={() => setIsSmartBannerOpen(false)}
                viewButton={translate('APP_BANNER_VIEW_BUTTON')}
                androidAppId={config?.app_config.smart_app_banner.android_app_id}
                iOsAppId={config?.app_config.smart_app_banner.ios_app_id}
            />
        </>
    );
};
export default Application;

import React from 'react';
import SVGInline from 'react-svg-inline';
import { css } from 'styled-components';
import { styled } from '../../components/ThemeConfigConnector/ThemeConfigConnnector';
import { fontBold, modalZIndex } from './cssGlobalVariables';
import { PictureWithFallback } from '../../components/Picture/PictureWithFallback';
import { Breakpoints, MQ } from './cssMediaQueries';
import { Marker } from '../../types/Asset';
import { hexToRgba } from '../../utils/fnColors';
import { ButtonPlayOutline } from '../../components/Buttons/ButtonPlayOutline/ButtonPlayOutline';

export const CardImage: React.FC<{ url: string; alwaysLoad?: boolean; fallbackImage?: string; alt?: string }> = ({
    url,
    alwaysLoad,
    fallbackImage,
    ...rest
}) => {
    return <PictureWithFallback src={url} fit="cover" {...rest} fallbackImage={fallbackImage} alwaysLoad={alwaysLoad} />;
};

export const Clear = styled.div`
    clear: both;
    content: '';
    display: inline-block;
`;

export const LoadingIndicatorWrapper = styled.div`
    height: auto;
    padding-top: 50px;
`;

export const LoaderWrapper = styled.div`
    animation: flickerAnimation 1.2s infinite ease-in-out;
    animation-delay: 0.6s;
    position: relative;
    width: 100%;
    overflow: hidden;

    @keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const LoadingRectangle = styled.div`
    width: 100%;
    height: 100%;
    background: ${props => hexToRgba(props.theme.brandedConfig.low_contrast, 0.8)};
    border-radius: 10px;
`;

export const TitlePlaceholder = styled.div`
    width: 80%;
    height: 12px;
    margin-top: 8px;
`;

export const LoadingCircle = styled.div`
    width: 100%;
    height: 100%;
    background: ${props => hexToRgba(props.theme.brandedConfig.low_contrast, 0.8)};
    border-radius: 50%;
`;

// page title with back icon
export const PageTitleBlock = styled.div`
    margin-bottom: 8px;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    visibility: hidden;

    ${MQ(Breakpoints.m)} {
        visibility: visible;
    }
`;

export const PageTitle = styled.div`
    text-align: left;
    font-size: 14px;
    line-height: 20px;
`;

export const PageTitleText = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

export const PageTitleSVG = styled(SVGInline)`
    height: 20px;

    svg {
        width: auto;
        height: 100%;
    }
`;

export const MetaDataSeparator = styled.span`
    color: ${props => props.theme.brandedConfig.high_contrast};
    opacity: 0.7;
    display: inline-block;
    padding-left: 9px;
    padding-right: 9px;
    vertical-align: middle;
`;

export const ContentMarker = styled.div<{ marker: Marker; withProgressBar?: boolean }>`
    position: absolute;
    padding: 6px 8px;
    font-size: 12px;
    font-family: ${fontBold};
    background-color: ${props => props.marker?.backgroundColor || 'var(--color-accent-dark)'};
    color: ${props => props.marker?.textColor || 'var(--color-primary)'};
    left: 8px;
    bottom: ${props => (props.withProgressBar ? '20px' : '8px')};
    font-weight: bold;
    border-radius: 2px;
`;

export const DetailMarkerAndMetaData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${MQ(Breakpoints.m)} {
        align-items: flex-start;
    }
`;

export const DetailMarkerContainer = styled.div``;

export const NoContentMessage = styled.p`
    color: ${props => props.theme.brandedConfig.high_contrast};
    opacity: 0.7;
    font-family: ${fontBold};
    font-size: 24px;
    line-height: 32px;
    width: 100%;
    padding: 0 12px;
    text-align: center;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
`;

export const StyledSVG = styled(SVGInline)`
    height: 18px;
    width: auto;
    display: inline-block;
    margin-right: 12px;
    position: relative;
    vertical-align: middle;
`;

export const StyledButtonPlayOutline = styled(ButtonPlayOutline)`
    ${MQ(Breakpoints.s)} {
        display: none;
    }
`;

export const ellipsis = (lineLimit: number) => {
    return css`
        display: -webkit-box;
        -webkit-line-clamp: ${lineLimit};
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
    `;
};

export const AudioSubtitleBlock = styled.div`
    font-size: 14px;
    line-height: 20px;
    font-family: ${fontBold};
    color: ${props => props.theme.brandedConfig.high_contrast};
`;

export const LandscapeImageContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #232438;
`;

export const MoviePortraitImageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${props => hexToRgba(props.theme.brandedConfig.black_color, 0.7)};
`;

export const MoviePortraitImage = styled.div`
    position: absolute;
    width: 120px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;

    img {
        width: 100%;
    }
`;

export const ChannelLogoWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 117px;
    height: 64px;
    border-top-right-radius: 5px;
    background: linear-gradient(214deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 25%, transparent 50%);
`;

export const ChannelImageInnerWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    height: 20px;
    max-width: 40px;
    text-align: right;
    font-size: 0;
`;

// modal

export const ModalWrapper = styled.div<{ opacity?: number }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => hexToRgba(props.theme.brandedConfig.black_color, props.opacity ?? 0.95)};
    overflow: auto;
    z-index: ${modalZIndex};
`;

export const ModalNavigation = styled.div`
    position: fixed;
    height: 48px;
    width: 100%;
    top: 0;
    z-index: 1;
    transition: all 0.2s ease-in;
    background: linear-gradient(
        180deg,
        ${props => hexToRgba(props.theme.brandedConfig.black_color, 1)},
        ${props => hexToRgba(props.theme.brandedConfig.black_color, 0)}
    );
`;

export const ModalCloseButton = styled.div`
    right: 18px;
    top: 14px;
    cursor: pointer;
    position: absolute;

    &:focus-visible {
        outline: 2px solid #fff;
        border-radius: 50%;
    }

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: ${props => props.theme.brandedConfig.high_contrast};
        }
    }

    .SVGInline {
        width: 20px;
        height: 20px;
    }

    ${MQ(Breakpoints.s)} {
        right: 26px;
    }

    ${MQ(Breakpoints.m)} {
        right: 46px;
    }

    &:hover {
        opacity: 0.5;
    }
`;
